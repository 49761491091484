/*
 * Manipulation utility module
 *
 * Small utility module exporting methods to manipulate lists of Objects
 */

/*
 * Get list of objects in ns array by Filter
 *
 * @param {Object} obj
 * @param {String} ns
 * @param {*} filter
 * @return {[*]}
 */
function getByfilter(obj, ns, filter = {}) {
    var results = [],
        current, key, match;

    for (current of obj[ns]) {
        match = true;

        for (key in filter) {
            if (filter[key] !== current[key]) {
                match = false;
                break;
            }
        }

        if (match) { results.push(current); }
    }

    return results;
}

/*
 * Remove given instance from ns array by given filter
 *
 * @param {Object} obj
 * @param {String} ns
 * @param {Object} filter
 */
function removeByFilter(obj, ns, filter) {
    for (let i = 0; i < obj[ns].length; i++) {
        let match = true,
            behaviour = obj[ns][i];

        for (let key in filter) {
            if (filter[key] !== behaviour[key]) {
                match = false;
                break;
            }
        }

        if (match) {
            if (obj[ns].$remove) {
                obj[ns].$remove(i);

            } else {
                obj[ns].splice(i, 1);
            }

            i--;
        }
    }

    return null;
}

/*
 * Remove given instance from ns Array
 *
 * @param {Object} obj
 * @param {String} ns
 * @param {Object} filter
 */
function removeInstance(obj, ns, instance) {
    let index = obj[ns].indexOf(instance);

    if (index === -1) { return; }

    if (obj[ns].$remove) {
        obj[ns].$remove(index);
    } else {
        obj[ns].splice(index, 1);
    }
}

export default { getByfilter, removeByFilter, removeInstance };