import async from 'async';
import config from '../config';
import tilesets from '../editor/tilesets';
import error from './error';

const TYPES = [
    'textures',
    'adaptive'
    ],
    EMPTY_CANVAS = document.createElement('canvas'),
    EMPTY_CTX = EMPTY_CANVAS.getContext('2d'),
    EMPTY_ASSET = {
        canvas : EMPTY_CANVAS,
        ctx    : EMPTY_CTX,
        color  : 'rgba(0, 0, 0, 0)'
    };

/*
 * Assets module
 *
 * Handles loading and caching of assets
 */

var cache = {};

/*
 * Load tile asset
 *
 * @param {String} setId
 * @param {Number} x
 * @param {Number} y
 * @param {Function} callback
 */
exports.loadTile = function (tile, callback) {
    if (!tile || tile === '__clear__') {
        if (callback) {
            callback(EMPTY_ASSET);
        }

        return;
    }

    var index = tile[0],
        type = tile[1],
        setId = tile[2],
        cacheKey = 'tile-' + tile[0] + tile[1] + tile[2],
        cached = cache[cacheKey];

    if (cached) {
        if (callback) { callback(cached); }
        return;
    }

    return loadTileset(setId, (tileset) => {
        cropTile(tileset.assets[TYPES[type]], type, index, function (canvas, url, ctx) {
            cache[cacheKey] = { canvas, url, color: getAssetColor(canvas, ctx) };
            if (callback) { callback(cache[cacheKey]); }
        });
    });
};

function getAssetColor(canvas, ctx) {
    var data = ctx.getImageData(
        config.TILE_SIZE_ORIGINAL[0] / 2 - 5,
        config.TILE_SIZE_ORIGINAL[1] / 2 - 5,
        10,
        10
        ).data,
        pixels = [],
        color = [],
        i, n, tot;

    for (i = 0; i < data.length; i += 4) {
        pixels.push([ data[i], data[i + 1], data[i + 2], data[i + 3] ]);
    }

    for (i = 0; i < 4; i++) {
        tot = 0;

        for (n = 0; n < pixels.length; n++) {
            tot += pixels[n][i];
        }

        color.push(Math.floor(tot / pixels.length));
    }

    color[3] = Math.floor(255 / color[3]);

    if (color[3] > 1) {
        color[3] = 0;
    }

    return 'rgba(' + color.join(',') + ')';
}

/*
 * Crop tile from tileset asset
 *
 * @param {Image} asset
 * @param {Number} x
 * @param {Number} y
 * @param {Function} callback
 */
function cropTile(asset, type, index, callback) {
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),
        width = type === 1 ? 2.333 : 1,
        height = type === 1 ? 2 : 1,
        x = index % config.TILESET_SIZE[0],
        y = Math.floor(index / config.TILESET_SIZE[0]),
        url;

    if (type === 1) {
        x = 0;
        y = index * config.ADAPTIVE_TILE_HEIGHT;
    }

    if (type === 1) {
        canvas.width = ctx.width = config.TILESET_ADAPTIVE_REAL_WIDTH;
    } else {
        canvas.width = ctx.width = config.TILE_SIZE_ORIGINAL[0] * width;
    }

    canvas.height = ctx.height = config.TILE_SIZE_ORIGINAL[1] * height;

    ctx.drawImage(
        asset,
        config.TILE_SIZE_ORIGINAL[0] * x,
        config.TILE_SIZE_ORIGINAL[1] * y,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
        );

    url = canvas.toDataURL();

    callback(canvas, url, ctx);
}

/*
 * Load tileset asset
 *
 * @param {Image} id
 * @param {Function} callback
 */
function loadTileset(id, callback) {
    var options = tilesets.get(id);

    if (!options) {
        error.handle(new Error('Tileset "' + id + '" not found'));
        return;
    }

    if (cache['tileset-' + id]) {
        return callback(cache['tileset-' + id]);
    }

    loadAssets({
        textures : '/assets/tilesets/' + id + '/textures.png',
        adaptive : options.adaptive ? '/assets/tilesets/' + id + '/adaptive.png' : null,
    }, function (err, assets) {
        if (err) { return error.handle(err); }

        var tileset = { options, assets, tiles: [] };

        cache['tileset-' + id] = tileset;

        callback(tileset);
    });
}

/*
 * Load all assets in a key-value Object expressing their URLs
 *
 * @param {Object} obj
 * @param {Function} callback
 */
function loadAssets(obj, callback) {
    var pairs = [],
        out = {},
        key;

    for (key in obj) {
        if (obj.hasOwnProperty(key)) {
            pairs.push([ key, obj[key] ]);
        }
    }

    async.map(pairs, function (pair, callback) {
        var asset = new Image();

        if (!pair[1]) { return callback(); }

        asset.src = pair[1];

        asset.onload = function () {
            out[pair[0]] = asset;
            callback();
        };
    }, function (err) {
        return callback(err, out);
    });
}