import Vue from 'vue';
import config from '../config';

/*
 * Character Sprite component
 *
 * Display character sprite
 */

Vue.component('character-sprite', {
    data            : () => {
        return {
            asset     : 'hero',
            x         : 0,
            y         : 0,
            url       : null,
            style     : {},
            speech    : { text: null },
            pose      : 'down',
            animation : null,
            visible   : true
        };
    },
    template        : require('../../views/component/character-sprite.jade'),
    isFn            : true,
    replace         : true,
    ready           : ready,
    props           : [
        'asset',
        'x',
        'y',
        'speech',
        'pose',
        'animation',
        'visible'
    ],
    methods         : {
        update
    }
});

/*
 * Initialise component
 */
function ready() {
    this.$watch('x', this.update);
    this.$watch('y', this.update);
    this.update();
}

/*
 * Update sprite CSS style
 */
function update() {
    this.style = {
        left : (this.x * 100) / config.MAP_SIZE[0] + '%',
        top  : (this.y * 100) / config.MAP_SIZE[1] + '%'
    };
}