import Vue from 'vue';

/*
 * Full Height directive
 *
 * Used to define dynamically resizing full height elements
 */

Vue.directive('full-height', {
    bind,
    unbind,
    resize
});

/*
 * Bind resize event and initialise
 */
function bind() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
}

/*
 * Unbind resize event
 */
function unbind() {
    window.removeEventListener('resize', this.resize.bind(this));
}

/*
 * Triggered on page resize
 */
function resize() {
    if (!this.el) { return; }

    var header = document.querySelector('header');

    this.el.style.height = window.innerHeight - header.offsetHeight + 'px';
}