import error from '../core/error';
import router from '../core/router';
import config from '../config';
import modal from '../ui/modal';
import api from '../core/api';

/*
 * Levels controller
 *
 * Levels list view controller
 */

export var scope = {
    KW_CONFIG : config.KW_CONFIG,
    share     : null,
    levelId   : null,
    loading   : true
};

export var methods = { play };

/*
 * Initialise controller
 */
export function run(req) {
    var id = req.namedParams.shareId;

    api.shares.get.byId({ id })
    .then((res) => {

        this.share = res.body.share;
        this.level = res.body.level;
        this.loading = false;

    }, (res) => {
        this.loading = false;

        if (res.status === 404) {
            return router.goTo('/404-not-found');
        }

        error.handle(new Error(res.body));
    })
    .catch(error.handle);
}

/*
 * Pick save slot and play level
 */
function play() {
    modal.open('save-slots', { levelId: this.level.id }, (slot) => {
        if (!slot) { return; }
        router.goTo(`/play/${this.level.id}/${slot.id}`);
    });
}