import infiniteJSON from 'js-infinite-json';
import lzjs from 'lzjs';

/*
 * Compression utility module
 *
 * A small module containing utilities to compress / decompress complex
 * and/or heavy JSON structures
 */

/*
 * Compress JSON Object to String using lzjs compression algorithm over
 * infiniteJSON stringification used to support circular structures
 *
 * @param {Object} obj
 * @return {String}
 */
function compress(obj) {
    return lzjs.compress(infiniteJSON.stringify(obj));
}

/*
 * Decompress String to JSON Object using lzjs decompression over
 * infiniteJSON parsed content
 *
 * @param {String} str
 * @return {Object}
 */
function decompress(str) {
    return infiniteJSON.parse(lzjs.decompress(str));
}

export default { compress, decompress };