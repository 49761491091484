import Q from 'q';
import kanoWorld from '../core/kano-world';
import EventEmitter from 'tiny-emitter';
import app from '../app';

/*
 * Unlocked challenges module
 *
 * Provides a centralised singleton interface to load, cache, and store and
 * save unlocked challenges value (A number representing the user's current
 * progress through challenges) - through Kano World API when logged in or
 * through LocalStorage if not
 */

var unlockedChallenges = new EventEmitter(),
    unlocked;

/*
 * Load initial data from Kano World SDK
 */
unlockedChallenges.init = function () {
    unlockedChallenges.load();

    app.on('login', () => {
        // On user login, load user's value through SDK appStorage
        unlockedChallenges.load()
        .then(() => {
            // If locally stored value is higher than user's value, update
            // user's appStorage to higher value (Then clear localStorage
            // value to avoid all users from this browser to sync up)
            if (localStorage.unlocked_challenges > unlocked) {
                unlockedChallenges.set(localStorage.unlocked_challenges);
                localStorage.removeItem('unlocked_challenges');
            }
        });
    });
};

/*
 * Load new data from SDK
 *
 * @return {Promise}
 */
unlockedChallenges.load = function () {
    var user = kanoWorld.sdk.auth.getUser(),
        deferred = Q.defer();

    // Logged out
    if (!user) {

        setIfHigher(localStorage.unlocked_challenges);
        deferred.resolve(unlocked);

    // Logged in
    } else {

        kanoWorld.sdk.appStorage.get('make-adventures', (err, data) => {
            if (err) { return deferred.reject(err); }

            unlocked = 0;
            setIfHigher(data.unlocked_challenges);
            deferred.resolve(unlocked);
        });

    }

    return deferred.promise;
};

/*
 * Save unlocked challenges value through SDK if logged in or on localStorage
 * if not logged in
 *
 * @param {Number} value
 * @return {Promise}
 */
unlockedChallenges.set = function (value) {
    var deferred = Q.defer(),
        user = kanoWorld.sdk.auth.getUser();

    // If logged out, set to localStorage
    if (!user) {

        localStorage.unlocked_challenges = value;
        unlocked = value;
        unlockedChallenges.emit('change', value);

    // If logged in, set through Kano World SDK appStorage API interface
    } else {

        kanoWorld.sdk.appStorage.set('make-adventures', {
            unlocked_challenges : value
        }, function (err) {
            if (err) { return deferred.reject(err); }

            unlocked = value;
            unlockedChallenges.emit('change', value);
            deferred.resolve(value);
        });

    }

    return deferred.promise;
};

/*
 * Get currently stored unlocked challenges value if available, load otherwise
 *
 * @return {Promise}
 */
unlockedChallenges.get = function () {
    var deferred = Q.defer();

    // Has a cached value
    if (typeof unlocked !== 'undefined' && unlocked !== null) {

        deferred.resolve(unlocked);
        return deferred.promise;

    }

    // Needs to load the value
    return unlockedChallenges.load();
};

/*
 * If given value is valid an higher of currently stored, update it and emit
 * a change event
 *
 * @param {Number|undefined} value
 */
function setIfHigher(value) {
    if (!unlocked && unlocked !== 0) {
        unlocked = 0;
        unlockedChallenges.emit('change', unlocked);
    }

    if (typeof value !== undefined && value !== null && value > unlocked) {
        if (unlocked != value) {
            unlocked = value;
            unlockedChallenges.emit('change', unlocked);
        }
    }
}

export default unlockedChallenges;