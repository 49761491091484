import api from './api';
import error from './error';
import analytics from './analytics';

/*
 * Tracking module
 *
 * Exports event tracking methods
 */

/*
 * Track event by key along with given meta data
 *
 * @param {String} key
 * @param {Object} meta
 */
function track(key, meta = {}) {
    analytics.track(key, meta);

    api.events.track({ key, meta })
    .then(() => {
        // Do nothing..
    }, (res) => {
        error.handle(new Error('Failed storing event:', res.body));
    })
    .catch(error.handle);
}

export default { track };