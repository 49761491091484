/*
 * Items module
 *
 * Items functions
 */

/*
 * Use item of given id if found in inventory
 *
 * @param {String} itemId
 */
exports.useItem = function (itemId) {
    var item = this.inventory.getItem(itemId);

    if (!item) {
        return this.terminal.error('Item {item}' + itemId + '{/item} not found in inventory');
    }

    this.game.useItem(item);
};

/*
 * Create new item instance with given options
 *
 * @param {Object} options
 * @param {Boolean} addToMap
 * @return {ItemInstance}
 */
exports.spawnItem = function (options, addToMap = false) {
    return this.map.spawnItem(this.game, options, addToMap);
};

/*
 * Set item by ID to given x and y coordinates
 *
 * @param {Number} x
 * @param {Number} y
 * @param {String} id
 * @return {ItemInstance}
 */
exports.setItem = function (x, y, id) {
    return this.map.spawnItem(this.game, { x, y, id }, true);
};