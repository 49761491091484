import Vue from 'vue';
import domUtil from '../util/dom';

/*
 * Collapsable Section directive
 *
 * Used to create collapsable sidebar panels
 */

Vue.directive('sidebar-panel', {
    bind,
    toggle
});

/*
 * Bind click event
 */
function bind() {
    this.el.addEventListener('click', (e) => {
        if (e.target.tagName === 'H4') {
            this.toggle(this);
        }
    });
}

/*
 * Toggle panel
 */
function toggle() {
    domUtil.toggleClass(this.el, 'collapsed');
}