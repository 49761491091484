import Vue from 'vue';
import config from '../config';
import tilesets from '../editor/tilesets';

/*
 * Tile Picker component
 *
 * Display single tile picker
 */

Vue.component('tile-picker', {
    data            : () => {
        return {
            tileset  : config.DEFAULT_TILESET,
            tilesets : tilesets.getOptions(),
            tile     : null,
            editing  : false,
            tiles    : []
        };
    },
    template        : require('../../views/component/tile-picker.jade'),
    isFn            : true,
    replace         : true,
    props           : [
        'tile'
    ],
    ready           : init,
    methods         : {
        setTile,
        updateTileset,
        renderTiles
    }
});

/*
 * Initialise component
 */
function init() {
    this.updateTileset();
    this.$watch('tile', this.updateTileset, true);
    this.$watch('tileset', this.renderTiles, true);
    this.renderTiles();
}

/*
 * Update tileset on tile data change
 */
function updateTileset() {
    this.tileset = this.tile ? this.tile[2] : Object.keys(tilesets)[0];
}

/*
 * Set tile from given TileDisplay reference
 *
 * @param {Array} tile
 */
function setTile(tile) {
    this.tile.$set(0, tile[0]);
    this.tile.$set(1, tile[1]);
    this.tile.$set(2, tile[2]);
    this.editing = false;
}

/*
 * Form array of all tiles to display
 */
function renderTiles() {
    var tiles = [],
        i;

    for (i = 0; i < config.TILESET_SIZE[0] * config.TILESET_SIZE[1]; i++) {
        tiles.push([ i, 0, this.tileset ]);
    }

    this.tiles = tiles;
}