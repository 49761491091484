import api from '../core/api';
import error from '../core/error';
import imageUtil from '../util/image';
import config from '../config';

/*
 * Save Slots modal
 *
 * Modal to select a save slot
 */

var data = {
        KW_URL  : config.KW_CONFIG.WORLD_URL,
        levelId : null,
        level   : null,
        success : false,
        loading : true,
        error   : null,
        meta    : null,
        item    : null
    },
    methods = { loadImage, shareOnKanoWorld };

/*
 * Initialise modal
 */
function run() {
    api.levels.load({ id: this.levelId })
    .then((res) => {
        this.level = res.body.level;

        this.loadImage(() => {
            this.meta = {
                title       : this.level.name,
                description : ''
            };

            this.loading = false;
        });
    }, (res) => {
        this.error = res.body;
    })
    .catch(error.handle);
}

/*
 * Load level preview image and convert it to blob
 *
 * @param {Function} callback
 */
function loadImage(callback) {
    var url = this.level.preview_url,
        image = new Image();
        image.crossOrigin = 'anonymous';

    if (!url) {
        this.error = 'Missing preview image';
        return;
    }

    image.onload = () => {
        imageUtil.toBlob(image, (blob) => {
            this.coverBlob = blob;
            blob.name = 'cover.png';
            callback(blob);
        });
    };

    image.src = url;
}

/*
 * Share on Kano World
 */
function shareOnKanoWorld() {
    this.loading = true;
    api.levels.share({
        id          : this.levelId,
        title       : this.meta.title,
        description : this.meta.description,
        files       : { cover: this.coverBlob }
    })
    .then((res) => {
        this.loading = false;
        this.success = true;
        this.item = res.body.item;
    }, (res) => {
        this.error = res.body;
    })
    .catch(error.handle);
}

export default { data, methods, run };