import modal from '../../ui/modal';
import extend from 'deep-extend';
import async from 'async';

/*
 * Modals Sequence utility
 *
 * Exports method to generate a modals sequence function with given options
 */

/*
 * Display modals sequence
 *
 * @param {Object} baseOptions
 * @param {[Object]} steps
 * @return {Function}
 */
export default function (baseOptions, steps) {

    /*
     * Generated async sequence function
     *
     * @param {Function} done
     */
    return function (done) {
        async.mapSeries(steps, (options, callback) => {
            var index = steps.indexOf(options),
                modalOptions = extend({}, baseOptions, options, {
                    final: index === steps.length - 1
                });

            modal.open('explain', modalOptions, callback);
        }, done);
    };
}