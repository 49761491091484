import sdk from 'kano-world-sdk';
import EventEmitter from 'tiny-emitter';
import error from '../core/error';

/*
 * Kano World module
 *
 * Initialises Kano World SDK and exports client
 */

var events = new EventEmitter();

/*
 * Initialise SDK
 *
 * @param {Function=} callback
 */
function init(callback = null) {
    sdk.init((err, user) => {
        if (err) { return error.handle(err); }

        events.emit('initialise', user);

        if (callback) { callback(user); }
    });
}

/*
 * Return true if user is currently logged in
 *
 * @return {Boolean}
 */
function loggedIn() {
    return !!sdk.auth.getToken();
}

export default {
    init,
    sdk,
    on : events.on.bind(events),
    loggedIn
};