import async from 'async';
import config from '../config';
import manipulation from '../util/manipulation';
import clone from 'deepcopy';
import extend from 'deep-extend';
import BehaviourInstance from './BehaviourInstance';
import ItemInstance from './ItemInstance';
import MapRenderer from './MapRenderer';
import EventEmitter from 'tiny-emitter';
import tileUtil from '../util/tile';

/*
 * Map class
 *
 * Wraps map data passed in options with common map-related methods used
 * by both editor and game
 */

const CONFIG_DEFAULTS = {
        font : 'Bell'
    },
    ADJACENCIES = [ [ 1, 0 ], [ 0, 1 ], [ -1, 0 ], [ 0, -1 ] ];

export default class Map extends EventEmitter {

    /*
     * Map constructor
     *
     * @param {Level} level
     * @param {Object=} options
     */
    constructor(level, options = {}) {
        super();
        this.level = level;
        this.layers = options.layers || [];
        this.entities = options.entities || [];
        this.behaviours = options.behaviours || [];
        this.name = options.name || 'Untitled';
        this.scripts = options.scripts || {};
        this.items = options.items || [];
        this.config = extend({}, CONFIG_DEFAULTS, options.config || {});
        this.preview = {};
        this.connections = options.connections || { top: null, right: null, bottom: null, left: null };
        this.previewRenderer = new MapRenderer(this, { simplify: true });
        this._instances = options.instances;
        this.instances = [];
    }

    /*
     * Get a list of tile position which are equal and adjecent to given one
     * (Used for paint bucket tool)
     *
     * @param {Number} x
     * @param {Number} y
     * @param {Number=} l
     * @return {[Object]}
     */
    getBucketTargets(x, y, l = 0) {
        var tile = this.getTile(x, y, l),
            key = tile ? tileUtil.getUniqueKey(tile) : 'empty',
            out = [];

        this.getBucketTargetsRecursive(key, x, y, l, [], out);

        return out;
    }

    /*
     * Returns true if given x, y position is out of boundaries
     *
     * @param {Number} x
     * @param {Number} y
     * @return {Boolean}
     */
    isOutOfBoundaries(x, y) {
        return (
            x > config.MAP_SIZE[0] - 1 || x < 0 ||
            y > config.MAP_SIZE[1] - 1 || y < 0
        );
    }

    /*
     * Recursive function that figures out paint bucket selection
     *
     * @param {String} matchKey
     * @param {Number} x
     * @param {Number} y
     * @param {Number=} l
     * @param {[String]} processed
     * @param {[Object]} processed
     * @return {[Object]}
     */
    getBucketTargetsRecursive(matchKey, x, y, l = 0, processed = [], results = []) {
        var tile = this.getTile(x, y, l),
            key = tile ? tileUtil.getUniqueKey(tile) : 'empty',
            posKey, off, cur, wasProcessed, isOut;

        posKey = `${x},${y}`;
        processed.push(posKey);

        if (matchKey === key) {
            results.push({ x, y });
        } else { return; }

        for (off of ADJACENCIES) {
            cur = { x: x + off[0], y: y + off[1] };

            posKey = `${cur.x},${cur.y}`;

            wasProcessed = processed.indexOf(posKey) !== -1;
            isOut = this.isOutOfBoundaries(cur.x, cur.y);

            if (wasProcessed || isOut) { continue; }

            this.getBucketTargetsRecursive(matchKey, cur.x, cur.y, l, processed, results);
        }

        return results;
    }

    /*
     * Add entity to current map at given position
     *
     * @param {Number} x
     * @param {Number} y
     * @param {Object} template
     * @param {Level} level
     */
    setEntity(x, y, template, level = null) {
        let maps = level ? level.maps : [ this ],
            current = this.getEntity({ x: x, y: y }),
            entity, map;

        // No change required
        if ((!current && !template) || (template && current && current.type === template.type)) {
            return;
        }

        this.removeEntity(current);

        if (!template) { return; }

        if (template.unique) {
            for (map of maps) {
                map.removeEntity(map.getEntity({ type: template.type }));
            }
        }

        entity = clone(template);
        entity.x = x;
        entity.y = y;
        this.entities.push(entity);

        this.emit('change', 'logic', x, y);
    }

    /*
     * Copy map by deep cloning layers
     *
     * @return {Map}
     */
    copy() {
        var copy = new Map(this.level, this),
            layers = [],
            layer, l, row, r, tile;

        for (layer of this.layers) {
            l = [];
            layers.push(l);

            for (row of layer) {
                r = [];
                l.push(r);

                for (tile of row) {
                    r.push(tile ? [ tile[0], tile[1], tile[2] ] : null);
                }
            }
        }

        copy.layers = layers;
        copy.entities = clone(this.entities);
        copy.behaviours = clone(this.behaviours);
        copy.items = clone(this.items);

        return copy;
    }

    /*
     * Get single entity by given filter
     *
     * @param {Object} filter
     * @return {Object}
     */
    getEntity(filter) {
        return manipulation.getByfilter(this, 'entities', filter)[0] || null;
    }

    /*
     * Get behaviour instance by given filter
     *
     * @param {Object} filter
     * @return {Object}
     */
    getInstance(filter) {
        return manipulation.getByfilter(this, 'instances', filter)[0] || null;
    }

    /*
     * Remove given entity from map entities list
     *
     * @param {Object} filter
     */
    removeEntity(entity) {
        return manipulation.removeInstance(this, 'entities', entity);
    }

    /*
     * Remove given instance from map entities list
     *
     * @param {Object} filter
     */
    removeInstance(instance) {
        return manipulation.removeInstance(this, 'instances', instance);
    }

    /*
     * Set a tile in the map to given one on current layer
     *
     * @param {Number} layer
     * @param {Number} x
     * @param {Number} y
     */
    setTile(layer, x, y, tile) {
        if (x < 0 || y < 0 || x > config.MAP_SIZE[0] - 1 || y > config.MAP_SIZE[1] - 1) { return; }

        let target = this.layers[layer][y][x];

        if (tileUtil.equal(tile, target)) {
            return;
        }

        if (this.layers[layer][y].$set) {
            this.layers[layer][y].$set(x, tile);
        } else
        // if (target && target.$set) {
        //     target.$set(0, tile[0]);
        //     target.$set(1, tile[1]);
        //     target.$set(2, tile[2]);
        // } else
        {
            this.layers[layer][y][x] = tile;
        }

        this.emit('change', layer, x, y);
    }

    /*
     * Set item by id at given coordinates
     *
     * @param {Number} x
     * @param {Number} y
     * @param {String} id
     */
    setItem(x, y, id) {
        var item = { x, y, id };

        manipulation.removeByFilter(this, 'items', { x, y });
        manipulation.removeByFilter(this, 'behaviours', { x, y });

        if (!id) { return null; }

        this.items.push(item);

        return item;
    }

    /*
     * Generate instances from each behaviour
     */
    generateInstances(game) {
        this.instances = [];

        this.behaviours.forEach((behaviour) => {
            this.instances.push(new BehaviourInstance({
                game  : game,
                map   : this,
                x     : behaviour.x,
                y     : behaviour.y,
                type  : 'behaviour',
                id    : behaviour.id,
                hooks : this.level.getBehaviour('tiles', behaviour.id) || {}
            }));
        });

        this.items.forEach((item) => {
            this.spawnItem(game, item, true);
        });
    }

    /*
     * Generate instance for item
     *
     * @param {GamePlay} game
     * @param {Object} item
     */
    spawnItem(game, item, addToMap = false) {
        var template = this.level.items[item.id];

        if (!template) {
            throw new Error('Item type not found: ' + item.id);
        }

        var hooks = this.level.getBehaviour('items', template.behaviour) || {},
            instance = new ItemInstance({
                game       : game,
                map        : this,
                x          : item.x || null,
                y          : item.y || null,
                type       : 'item',
                id         : item.id,
                hooks      : hooks,
                attributes : clone(template.attributes),
                states     : template.states,
                pickable   : template.pickable,
                singleUse  : template.singleUse
            });

        if (addToMap) {
            this.instances.push(instance);
        }

        return instance;
    }

    /*
     * Restore previous instances from stored options
     *
     * @param {GamePlay} game
     */
    restoreInstances(game) {
        this.instances = [];

        this._instances.forEach((options) => {
            var InstanceConstructor;

            options.game = game;

            if (options.type === 'behaviour') {
                InstanceConstructor = BehaviourInstance;
            } else if (options.type === 'item') {
                InstanceConstructor = ItemInstance;
            }

            if (!InstanceConstructor) { return; }

            this.instances.push(new InstanceConstructor(options));
        });
    }

    /*
     * Generate and cache preview image for map
     *
     * @param {Number=} size
     * @param {Function=} callback
     */
    generatePreview(callback = null) {
        if (this.generating) { return; }

        this.generating = true;

        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d'),
            size = config.MAP_SIZE[0];

        canvas.width = ctx.width = canvas.height = ctx.height = size;

        async.map(Object.keys(this.layers), (i, callback) => {
            this.previewRenderer.setSize(size);
            this.previewRenderer.renderLayer(i, callback);
        }, (err, layers) => {
            layers.forEach((rendered) => {
                ctx.drawImage(rendered.canvas, 0, 0, size, size);
            });

            var url = canvas.toDataURL(),
                preview = { canvas, ctx, url };

            this.preview = preview;

            this.generating = false;
            if (callback) { callback(null, preview); }
        });
    }

    /*
     * Get tile by given x / y coordinates and layer index
     *
     * @param {Number} x
     * @param {Number} y
     * @param {Number=} index
     */
    getTile(x, y, layer = 0) {
        if (!this.layers[layer] || !this.layers[layer][y]) { return null; }
        return this.layers[layer][y][x] || null;
    }

    /*
     * Return true if has connection on given side
     *
     * @return {Boolean}
     */
    hasConnection(direction) {
        return typeof this.connections[direction] === 'number';
    }

    /*
     * Export map data
     *
     * @param {Boolean=} exportInstances
     * @return {Object}
     */
    export(exportInstances) {
        var out = {
            layers      : this.layers,
            entities    : this.entities,
            items       : this.items,
            behaviours  : this.behaviours,
            name        : this.name,
            scripts     : this.scripts,
            config      : this.config,
            connections : this.connections
        };

        if (exportInstances) {
            out.instances = this.instances.map((instance) => {
                return instance.export();
            });
        }

        return out;
    }
}