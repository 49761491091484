/*
 * Default Items
 *
 * Exports level's default items
 */

export default {

    'potion-healing-minor': {
        name       : 'Minor Healing Potion',
        behaviour  : 'stat-potion',
        pickable   : true,
        singleUse  : true,
        states     : {
            default : { tile: [ 12, 0, 'items' ] }
        },
        attributes : {
            stat  : 'hp',
            value : 1
        }
    },

    'potion-healing': {
        name       : 'Healing Potion',
        behaviour  : 'stat-potion',
        pickable   : true,
        singleUse  : true,
        states     : {
            default : { tile: [ 18, 0, 'items' ] }
        },
        attributes : {
            stat  : 'hp',
            value : 3
        }
    },

    'potion-mana-minor': {
        name       : 'Minor Mana Potion',
        behaviour  : 'stat-potion',
        pickable   : true,
        singleUse  : true,
        states     : {
            default : { tile: [ 13, 0, 'items' ] }
        },
        attributes : {
            stat  : 'mana',
            value : 1
        }
    },

    'potion-mana': {
        name       : 'Mana Potion',
        behaviour  : 'stat-potion',
        pickable   : true,
        singleUse  : true,
        states     : {
            default : { tile: [ 19, 0, 'items' ] }
        },
        attributes : {
            stat  : 'mana',
            value : 3
        },
    }

};