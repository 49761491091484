export default {
    'prompt'             : { controller: require('./prompt') },
    'alert'              : { controller: require('./alert') },
    'confirm'            : { controller: require('./confirm') },
    'select-key'         : { controller: require('./select-key') },
    'save-slots'         : { controller: require('./save-slots') },
    'feedback'           : { controller: require('./feedback') },
    'level-save'         : { controller: require('./level-save') },
    'explain'            : { controller: require('./explain') },
    'challenge-finished' : { controller: require('./challenge-finished') },
    'share-level'        : { controller: require('./share-level') },
    'join'               : { controller: require('./join') },
    'pictures-gallery'   : { controller: require('./pictures-gallery') },
    'learn-alert'        : { controller: require('./learn-alert') }
};