/*
 * Explain modal
 *
 * Modal used for sequences inside editor challenges
 */

var data = {
        title     : null,
        text      : null,
        image     : null,
        assistant : null,
        icon      : null,
        final     : false
    };

/*
 * Initialise modal
 */
function run() {
    // Do nothing..
}

export default { data, run };