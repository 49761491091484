import keycode from 'keycode';

/*
 * Select Key modal
 *
 * Modal to select a key from the keyboard
 */

/*
 * Initialise modal
 */
function run() {
    var modal = this,
        input = this.$el.querySelector('input');

    input.focus();

    // Force focus on the input to escape submitting of commands
    input.addEventListener('blur', () => {
        if (!input.parentNode) { return; }
        input.blur();
    }); 

    // Listen to key press
    window.addEventListener('keydown', function keydown (e) {
        e.preventDefault();
        e.stopPropagation();

        // Remove listener after first use
        window.removeEventListener('keydown', keydown);

        var key = keycode(e.keyCode);

        modal.done(key);
    });
}

export default { run };