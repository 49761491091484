import Vue from 'vue';
import error from '../core/error';
import config from '../config';
import entities from '../editor/entities';
import MapRenderer from '../class/MapRenderer';

const GRID_COLOR = 'black',
    GRID_COLOR_BACK = 'white';

/*
 * Map Viewer component
 *
 * Display pannable map viewer
 */

Vue.component('map-viewer', {
    data            : () => {
        return {
            map         : null,
            grid        : false,
            logic       : false,
            hide        : [],
            items       : {},
            anchors     : false,
            tileAnchors : [],
            toggles     : true,
            assisted    : null
        };
    },
    template        : require('../../views/component/map-viewer.jade'),
    isFn            : true,
    replace         : true,
    created         : created,
    ready           : ready,
    beforeDestroy   : unbind,
    props           : [
        'map',
        'grid',
        'logic',
        'hide',
        'items',
        'anchors',
        'toggles',
        'assisted'
    ],
    methods         : {
        getEntityStyle,
        getInstanceStyle,
        render,
        renderLayer,
        renderGrid,
        layerIsVisible,
        getTileSize,
        getEntityTile,
        change,
        changeMap,
        generateAnchors,
        updateRectangle
    }
});

/*
 * Initialise component
 */
function created() {
    this.$parent.viewer = this;
    this.onChange = this.change.bind(this);

    if (this.anchors) {
        this.generateAnchors();
    }
}

/*
 * Generate anchors Array with all tiles anchors to diplay anchors
 */
function generateAnchors() {
    var tileAnchors = [],
        x, y, style;

    for (x = 0; x < config.MAP_SIZE[0]; x++) {
        for (y = 0; y < config.MAP_SIZE[1]; y++) {
            style = {
                left   : (x * 100) / config.MAP_SIZE[0] + '%',
                top    : (y * 100) / config.MAP_SIZE[1] + '%',
                width  : 100 / config.MAP_SIZE[0] + '%',
                height : 100 / config.MAP_SIZE[1] + '%'
            };

            tileAnchors.push({ x, y, style });
        }
    }

    this.tileAnchors = tileAnchors;
}

/*
 * Watch map changes and fire first render
 */
function ready() {
    this.resize = resize.bind(this);
    window.addEventListener('resize', this.resize);
    this.changeMap();
    this.$watch('map', this.changeMap);
}

/*
 * Set map renderer to current map and run first render
 */
function changeMap() {
    this.renderer = new MapRenderer(this.map);
    this.map.on('change', this.onChange);
    this.render();
}

/*
 * Handle window resize
 */
function resize() {
    this.render();
}

/*
 * Unbind generic DOM events
 */
function unbind() {
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('keydown', this.keydown);
    this.map.off('change', this.onChange);
}

/*
 * Deal with tile change and re-render adjacent tiles
 *
 * @param {Number} layer
 * @param {Number} x
 * @param {Number} y
 */
function change(layer, x, y) {
    if (typeof layer !== 'number') { return; }

    if (this.preventRenderChanges) { return; }

    this.updateRectangle({
        x      : x - 1,
        y      : y - 1,
        width  : 3,
        height : 3,
    }, layer);
}

/*
 * Update rendering in area expressed as a rectangle of tile locations
 *
 * @param {Object} rect
 * @param {Number=} layer
 */
function updateRectangle(rect, layer = 0) {
    var canvas = this.$el.querySelectorAll('[ref="layer"]')[layer],
        ctx = canvas.getContext('2d'),
        size = canvas.offsetWidth * config.PIXEL_RATIO;

    this.renderer.renderUpdatedArea(layer, rect.x, rect.y, rect.width, rect.height, (err, rendered) => {
        if (err) { return error.handle(err); }

        ctx.clearRect(0, 0, size, size);
        ctx.drawImage(rendered.canvas, 0, 0, size, size);
    });
}

/*
 * Render entire map
 */
function render() {
    // Next tick..
    setTimeout(() => {
        if (!this.$el) { return; }

        var canvases = this.$el.querySelectorAll('[ref="layer"]'),
            i;

        for (i = 0; i < this.map.layers.length; i++) {
            this.renderLayer(i, canvases[i]);
        }

        this.renderGrid();
    });
}

/*
 * Render a single layer of the map
 *
 * @param {Number} index
 * @param {HTMLCanvas} canvas
 */
function renderLayer(index, canvas) {
    var ctx = canvas.getContext('2d'),
        size = canvas.offsetWidth * config.PIXEL_RATIO;

    this.renderer.setSize(size);
    canvas.width = ctx.width = canvas.height = ctx.height = size;

    this.renderer.renderLayer(index, function (err, rendered) {
        if (err) { return error.handle(err); }

        ctx.drawImage(rendered.canvas, 0, 0, size, size);
    });
}

/*
 * Render map grid
 */
function renderGrid() {
    var canvas = this.$el.querySelector('[ref="grid"]'),
        ctx = canvas.getContext('2d'),
        size = canvas.offsetWidth * config.PIXEL_RATIO,
        offset = 1 * config.PIXEL_RATIO, x, y;

    canvas.width = ctx.width = canvas.height = ctx.height = size;

    ctx.strokeStyle = GRID_COLOR_BACK;

    for (x = 1; x < config.MAP_SIZE[0]; x++) {
        ctx.beginPath();
        ctx.moveTo(size / config.MAP_SIZE[0] * x + offset, offset);
        ctx.lineTo(size / config.MAP_SIZE[0] * x + offset, size + offset);
        ctx.stroke();
    }

    for (y = 0; y < config.MAP_SIZE[0]; y++) {
        ctx.beginPath();
        ctx.moveTo(offset, size / config.MAP_SIZE[0] * y + offset);
        ctx.lineTo(size + offset, size / config.MAP_SIZE[0] * y + offset);
        ctx.stroke();
    }

    ctx.strokeStyle = GRID_COLOR;
    ctx.lineWidth = 1 * config.PIXEL_RATIO;

    for (x = 1; x < config.MAP_SIZE[0]; x++) {
        ctx.beginPath();
        ctx.moveTo(size / config.MAP_SIZE[0] * x, 0);
        ctx.lineTo(size / config.MAP_SIZE[0] * x, size);
        ctx.stroke();
    }

    for (y = 0; y < config.MAP_SIZE[0]; y++) {
        ctx.beginPath();
        ctx.moveTo(0, size / config.MAP_SIZE[0] * y);
        ctx.lineTo(size, size / config.MAP_SIZE[0] * y);
        ctx.stroke();
    }
}

/*
 * Get current tiles size
 *
 * @return {Number}
 */
function getTileSize() {
    return config.TILE_SIZE;
}

/*
 * Get instance tile styles
 *
 * @param {Object} instance
 * @return {Object}
 */
function getInstanceStyle(instance) {
    return {
        left : (instance.x * 100) / config.MAP_SIZE[0] + '%',
        top  : (instance.y * 100) / config.MAP_SIZE[1] + '%'
    };
}

/*
 * Get entity tile styles
 *
 * @param {Object} entity
 * @return {Object}
 */
function getEntityStyle(entity) {
    var tileSize = this.getTileSize();

    return {
        left : entity.x * tileSize + 'px',
        top  : entity.y * tileSize + 'px'
    };
}

/*
 * Returns true if layer of given index is visible
 *
 * @param {Number} index
 * @return {Boolean}
 */
function layerIsVisible(index) {
    return this.hide.indexOf(index) === -1;
}

/*
 * Get tile of given entity tile
 *
 * @param {Object} entity
 * @return {Object}
 */
function getEntityTile(entity) {
    for (var e of entities) {
        if (entity.type === e.type) {
            return e.tile;
        }
    }

    return null;
}