// Import Babel polyfills
import 'babel-polyfill';

// Require dependencies
import Vue from 'vue';
import canvasToBlob from 'canvas-to-blob';
import router from './core/router';
import app from './app';
import config from './config';
import kanoWorld from './core/kano-world';
import analytics from './core/analytics';
import unlockedChallenges from './core/unlocked-challenges';
import uiRules from './ui/rules';
import assetsPreload from './core/assets-preload';

// Set debug mode
Vue.config.debug = config.DEBUG;

// Load routes
import './routes';

// Load filters
import './filter/string';
import './filter/markdown';
import './filter/date';

// Load components
require('./component/map-viewer');
require('./component/tiles-palette');
require('./component/tile-display');
require('./component/tile-picker');
require('./component/level-editor');
require('./component/level-editor-selection');
require('./component/level-editor-behaviours');
require('./component/level-editor-items');
require('./component/level-editor-map');
require('./component/level-editor-world');
require('./component/game-play');
require('./component/character-sprite');
require('./component/code-editor');
require('./component/struct-editor');
require('./component/attributes-editor');
require('./component/editor-challenge');
require('./component/assisted-drawing-highlighter');
require('./component/pictures-showcase');
require('./component/mobile-compatibility-alert');

// Load directives
import './directive/full-height';
import './directive/sidebar-panel';

// Initialise router
canvasToBlob.init();
app.init();
router.init();
kanoWorld.init();
analytics.init();
unlockedChallenges.init();
uiRules.init();
assetsPreload.init();