import BaseChallenge from '../class/BaseChallenge';
import modalsSequence from './util/modals-sequence';
import requireClick from '../ui/require-click';
import focus from '../ui/focus';
import bubble from '../ui/bubble';
import session from '../editor/session';

/*
 * Draw Floor challenge
 *
 * First challenge in learn mode, teaches to select and draw tiles
 */

var challenge = {
        name  : 'Logic',
        steps : [],
        hide  : {
            logic      : true,
            items      : true,
            behaviours : true,
            map        : true,
            world      : true
        }
    };

// Editor introduction sequence
challenge.steps.push(modalsSequence({ title : 'Logic' }, [
    { text: 'Great! Now you should be able to draw basic maps!', assistant: 'standing' },
    { text: 'But we still need them to work properly.. Let me explain', assistant: 'standing' },
]));

// Select layer 1
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = false;
    bubble.open({ text: 'Let\'s draw **below the player** - Select layer 1' , target: '.layers-selection', type: 'assistant', direction: 'left' });
    focus.on('.layers-selection', '.layers-selection .layer-choice-1');
    requireClick.on('.layers-selection .layer-choice-1')
    .then(() => {
        editor.layersLocked = true;
        done();
    });
});

// Draw a wall around the character
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    bubble.open({ text: 'Now we are on layer 2 - Let\'s draw a wall maze!', type: 'assistant', target: '.map', direction: 'right' });
    focus.on('.tiles-palette, .map');

    editor.assistedDrawing([
        '--x--x---------',
        '--x--x---------',
        '--x--xxxxxxxx--',
        '--x---------x--',
        '--x--xxxxxxxx--',
        '--x--x---------',
        '--x--x---xxxx--',
        '--x--x-----x---',
        '--x--x-----x---',
        '--x--xxxxxxx---',
        '------x--x-----',
        '--xxxxx--x--x--',
        '--x------x--x--',
        '------x-----x--',
        '------x-----x--'
    ], { wood: [ '4:1', '6:1', '7:1', '9:1', '10:1' ] })
    .then(() => {
        editor.selection.clear();
        done();
    });
});

// Toggle to preview
challenge.steps.push(function (done) {
    bubble.open({ text: 'Let\'s play the game and see how it works now.', type: 'assistant', target: '.map', direction: 'right', button: true });
    focus.on();
    requireClick.on('.bubble button')
    .then(() => {
        bubble.open({ text: 'Click on the play button', type: 'assistant', target: '.play-preview', direction: 'right' });
        focus.on('.play-preview');
        requireClick.on('.play-preview')
        .then(done);
    });
    
});

// Write the moveRight command
challenge.steps.push(function (done) {
    var game = session.getGame(),
        completed = false;

    // Explain command to input
    bubble.open({ text: 'Now type `moveRight(5)` and press Enter to move the player 5 tiles to the right..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });
    focus.on('input.prompt');

    // Listen for commands input
    game.events.on('command', function (command) {
        if (completed) { return; }

        if (/moveRight\([0-9]*\)/.test(command)) {
            completed = true;
            correct();
        } else {
            wrong();
        }
    });

    /*
     * Command was incorrectly submitted
     */
    function wrong() {
        bubble.open({ text: 'That wasn\'t quite right - try again..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });

        setTimeout(() => {
            bubble.open({ text: 'Type `moveRight(5)` and press Enter..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });
        }, 1000);
    }

    /*
     * Command was correctly submitted
     */
    function correct() {
        focus.on('.map');
        requireClick.on('[nothing]');

        bubble.close();

        setTimeout(() => {
            bubble.open({ text: 'Did you see that? The character can pass through the wall - **Let\'s fix that**!', type: 'assistant', target: '.map', direction: 'right', button: true });
            requireClick.on('.bubble button')
            .then(done);
        }, 3000);
    }
});

// Back to editor mode
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    requireClick.off();
    editor.togglePreview();
    focus.off();

    done();
});

// Explain solid blocks
challenge.steps.push(modalsSequence({ title : 'Layers' }, [
    { text: 'When you draw a map, the game doesn\'t know what is solid and what is not', image: 'logic/solids-missing' },
    { text: 'So we need to add **Solid blocks** that will prevent the player from passing through', image: 'logic/solids-added' },
]));

// Show and explain logic panel
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.hide.logic = false;

    // Next tick..
    setTimeout(() => {
        focus.on('.tool.tool-logic', null);

        bubble.open({ text: 'There\'s a new tool - The Logic panel', type: 'assistant', target: '.tool.tool-logic', direction: 'left', button: true });
        requireClick.on('.bubble button')
        .then(() => {
            focus.on('.tool.tool-logic');
            bubble.open({ text: 'Click on it to switch to Logic mode', type: 'assistant', target: '.tool.tool-logic', direction: 'left' });
            return requireClick.on('.tool.tool-logic');
        })
        .then(() => {
            focus.off();
            focus.on('.tiles-palette-chromeless', null);
            bubble.open({ text: 'In here there\'s a couple new things you can draw. Let\'s start from Solid blocks', type: 'assistant', target: '.tiles-palette-chromeless', direction: 'left', button: true });
            return requireClick.on('.bubble button');
        })
        .then(() => {
            focus.on('[tile="logic:solid"]', null);
            bubble.open({ text: 'The character cannot walk over Solid blocks!', type: 'assistant', target: '.tiles-palette-chromeless', direction: 'top', button: true });
            return requireClick.on('.bubble button');
        })
        .then(() => {
            focus.on('[tile="logic:solid"]');
            bubble.open({ text: 'Select the Solid block', type: 'assistant', target: '.tiles-palette-chromeless', direction: 'top' });
            return requireClick.on('[tile="logic:solid"]');
        })
        .then(() => {
            focus.on('[tile="logic:solid"], .map', '.map');
            bubble.open({ text: 'Now draw it all over the wall', type: 'assistant', target: '.map', direction: 'right' });

            editor.assistedDrawing([
                '--x--x---------',
                '--x--x---------',
                '--x--xxxxxxxx--',
                '--x---------x--',
                '--x--xxxxxxxx--',
                '--x--x---------',
                '--x--x---xxxx--',
                '--x--x-----x---',
                '--x--x-----x---',
                '--x--xxxxxxx---',
                '------x--x-----',
                '--xxxxx--x--x--',
                '--x------x--x--',
                '------x-----x--',
                '------x-----x--'
            ], { logic: [ 'solid' ] })
            .then(() => {
                editor.selection.clear();
                done();
            });
        });
    });
});

// Toggle to preview
challenge.steps.push(function (done) {
    bubble.open({ text: 'Cool - Now let\'s switch to play mode', type: 'assistant', target: '.play-preview', direction: 'right' });
    focus.on('.play-preview');
    requireClick.on('.play-preview')
    .then(done);
    
});

// Write the moveRight command
challenge.steps.push(function (done) {
    var game = session.getGame(),
        completed = false;

    // Explain command to input
    bubble.open({ text: 'Type `moveRight(5)` and press Enter', type: 'assistant', target: 'input.prompt', direction: 'bottom' });
    focus.on('input.prompt');

    // Listen for commands input
    game.events.on('command', function (command) {
        if (completed) { return; }

        if (/moveRight\([0-9]*\)/.test(command)) {
            completed = true;
            correct();
        } else {
            wrong();
        }
    });

    /*
     * Command was incorrectly submitted
     */
    function wrong() {
        bubble.open({ text: 'That wasn\'t quite right - try again..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });

        // Next tick..
        setTimeout(() => {
            bubble.open({ text: 'Type \'moveRight(5)\' and press Enter..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });
        }, 1000);
    }

    /*
     * Command was correctly submitted
     */
    function correct() {
        focus.on('.map');
        requireClick.on('[nothing]');

        bubble.close();

        // Next tick..
        setTimeout(() => {
            bubble.open({ text: 'See that? The walls now stop the player!', type: 'assistant', target: '.map', direction: 'right', button: true });
            requireClick.on('.bubble button')
            .then(done);
        }, 2500);
    }
});

// Switch back to editor
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.togglePreview();

    // Next tick..
    setTimeout(() => {
        done();
    });
});

// Explain and draw spawn point
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    focus.off();
    focus.on('.tiles-palette-chromeless', null);
    bubble.open({ text: 'Let\'s learn about the other Logic block!', type: 'assistant', target: '.tiles-palette-chromeless', direction: 'left', button: true });
    requireClick.on('.bubble button')
    .then(() => {
        focus.on('[tile="logic:spawn"]', null);
        bubble.open({ text: 'The Spawn block defines where the player will be when the game starts - You can only have one of this in your game', type: 'assistant', target: '.tiles-palette-chromeless', direction: 'top', button: true });
        return requireClick.on('.bubble button');
    })
    .then(() => {
        focus.on('[tile="logic:spawn"]');
        bubble.open({ text: 'Now select it', type: 'assistant', target: '.tiles-palette-chromeless', direction: 'top' });
        return requireClick.on('[tile="logic:spawn"]');
    })
    .then(() => {
        focus.on('.tiles-palette-chromeless, .map', '.map');
        bubble.open({ text: 'Place the spawn point at the center of the map', type: 'assistant', target: '.map', direction: 'right' });

        editor.assistedDrawing([
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '-------x-------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------'
        ], { logic: [ 'spawn' ] })
        .then(() => {
            editor.selection.clear();
            done();
        });

    });
});

// Toggle to preview
challenge.steps.push(function (done) {
    bubble.open({ text: 'And let\'s go back to play mode', type: 'assistant', target: '.play-preview', direction: 'right' });
    focus.on('.play-preview');
    requireClick.on('.play-preview')
    .then(() => {
        // Next tick..
        setTimeout(done);
    });
});

challenge.steps.push(function (done) {
    focus.on('.map');
    bubble.open({ text: 'Cool! As you can see, the player now starts from the center of the map!', type: 'assistant', target: '.map', direction: 'right', button: true });
    requireClick.on('.bubble button')
    .then(done);
});

// Switch back to editor
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    focus.off();
    editor.togglePreview();

    // Next tick..
    setTimeout(() => {
        done();
    });
});

// Editor introduction sequence
challenge.steps.push(modalsSequence({ title : 'Remember..' }, [
    { text: 'Always remember to place Solid blocks to your maps, so that it behaves well when playing!', image: 'logic/example' },
    { text: 'Also remember to play with your maps after drawing, to make sure that it works as you want.', assistant: 'standing' },
]));

export default new BaseChallenge(challenge);