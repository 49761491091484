import error from '../core/error';
import api from '../core/api';
import modal from '../ui/modal';
import compression from './compression';
import kanoWorld from '../core/kano-world';
import moment from 'moment';
import Q from 'q';

const SAVE_SLOTS_COUNT = 5;

/*
 * Save Slots utility module
 *
 * Small utility module exporting methods to read and parse save slots data
 */

/*
 * Get a list of all default game save slots meta data and state Objects
 *
 * @return {Promise}
 */
exports.list = function (levelId) {
    var slots = [],
        loadedSaves = [],
        deferred = Q.defer();

    if (!kanoWorld.loggedIn()) {
        finish();
        return deferred.promise;
    }

    api.gameSaves.list({ levelId })
    .then((res) => {
        loadedSaves = res.body.saves;
        finish();
    }, (res) => {
        deferred.reject(res.body);
    })
    .catch(deferred.reject);

    /*
     * Get save loaded by API by slot name
     *
     * @param {String} slot
     * @return {Object|null}
     */
    function getLoadedSave(slot) {
        var save;

        for (save of loadedSaves) {
            if (slot === save.slot) {
                return save;
            }
        }

        return null;
    }

    /*
     * Generate each slot, populate with API data if found and resolve promise
     *
     * @return {[Object]}
     */
    function finish() {
        var i, save;

        for (i = 1; i < SAVE_SLOTS_COUNT + 1; i++) {
            save = getLoadedSave(`${i}`);

            slots.push({
                id    : i,
                name  : 'Slot ' + i,
                label : save ? moment(save.meta.date).format('YYYY MMM HH:MM') : 'Empty',
                empty : !save,
                save  : save || null
            });
        }

        deferred.resolve(slots);
    }

    return deferred.promise;
};

/*
 * From a save state string, return an Object containing `state` and
 * `meta` Objects as properties
 *
 * @param {String} str
 * @return {Object}
 */
exports.parse = function (str) {
    try {
        return compression.decompress(str);
    } catch (e) {
        return error.handle(new Error('Corrupted save data'));
    }
};

/*
 * Load game save from API - Resolve with null if missing
 *
 * @retunr {Promise}
 */
exports.load = function (levelId, slot) {
    var deferred = Q.defer();

    if (!kanoWorld.loggedIn()) {
        deferred.resolve(null);
        return deferred.promise;
    }

    api.gameSaves.load({ levelId, slot })
    .then((res) => {
        deferred.resolve(res.body.save);
    }, (res) => {
        if (res.status === 404) {
            return deferred.resolve(null);
        }

        deferred.reject(res.body);
    })
    .catch(deferred.reject);

    return deferred.promise;
};

/*
 * Clear given save slot
 *
 * @param {Object} slot
 */
exports.clear = function (levelId, slot) {
    var deferred = Q.defer();

    api.gameSaves.clear({
        levelId : levelId,
        slot    : slot
    })
    .then(() => {
        deferred.resolve();
    }, (res) => {
        deferred.reject(res.body);
    })
    .catch(error.handle);

    return deferred.promise;
};

/*
 * Save game state through API
 *
 * @param {String} slot
 * @param {GameState} gameState
 * @return {Promise}
 */
exports.save = function (slot, gameState) {
    var data = gameState.export(),
        deferred = Q.defer();

    api.gameSaves.save({
        levelId : data.meta.levelId,
        slot    : slot,
        meta    : data.meta,
        files   : {
            file : data.file
        }
    })
    .then(() => {
        deferred.resolve();
    }, (res) => {
        deferred.reject();
        return modal.open('alert', { text: `Error saving: ${res.body}` });
    });

    return deferred.promise;
};