import session from '../../editor/session';
import focus from '../../ui/focus';
import bubble from '../../ui/bubble';
import requireClick from '../../ui/require-click';
import Q from 'q';

/*
 * Require command
 *
 * Require command to be called in game preview mode
 */

/*
 * Require command that matches given regex - Explain it using given String
 *
 * @param {RegExp} commandRegex
 * @param {String} cmdString
 * @param {Function=} callback
 * @return {Promise}
 */
export default function (cmdRegex, cmdString, callback) {
    var deferred = Q.defer(),
        game = session.getGame(),
        completed = false;

    // Explain command to input
    bubble.open({ text: 'Now type `' + cmdString + '` and press Enter to move the player 5 tiles to the right..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });
    focus.on('input.prompt');

    // Only allow commands matching given regex
    game.commandRestriction = cmdRegex;

    // Listen for correct and wrong commands being inputted
    game.events.on('command', correct);
    game.events.on('wrong', wrong);

    /*
     * Command was incorrectly submitted
     */
    function wrong() {
        if (completed) { return; }

        bubble.open({ text: 'That wasn\'t quite right - try again..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });

        setTimeout(() => {
            bubble.open({ text: 'Type `' + cmdString + '` and press Enter..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });
        }, 1000);
    }

    /*
     * Command was correctly submitted
     */
    function correct() {
        if (completed) { return; }

        completed = true;

        // Next tick..
        setTimeout(() => {
            if (typeof callback === 'function') { callback(); }
            deferred.resolve();
        });
    }

    return deferred.promise;
}