import BaseChallenge from '../class/BaseChallenge';
import modalsSequence from './util/modals-sequence';
import requireClick from '../ui/require-click';
import focus from '../ui/focus';
import bubble from '../ui/bubble';
import session from '../editor/session';

/*
 * Draw Floor challenge
 *
 * First challenge in learn mode, teaches to select and draw tiles
 */

var challenge = {
        name  : 'Layers',
        steps : [],
        hide  : {
            logic      : true,
            items      : true,
            behaviours : true,
            map        : true,
            world      : true
        }
    };

// Editor introduction sequence
challenge.steps.push(modalsSequence({ title : 'Layers' }, [
    { text: 'So, now you know how to draw with tiles, let\'s learn about layers!', assistant: 'standing' },
    { text: 'The map is made of different layers, just like a sandwich', image: 'layers/sandwich' },
    { text: 'You can draw tiles of the map on each of these layers, but keep in mind: The player\'s character will be in the middle!', image: 'layers/explode' }
]));

// Draw the river
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    focus.on('.tiles-palette');
    bubble.open({ text: 'Select a water tile', target: '.tiles-palette', type: 'assistant', direction: 'bottom' });
    requireClick.on('[tile="3:0:wood"], [tile="4:0:wood"], [tile="5:0:wood"], [tile="11:0:wood"]')
    .then(() => {
        focus.on('.map');
        bubble.open({ text: 'Fill the highlighted area to make a river', target: '.map', type: 'assistant', direction: 'right' });
    });

    editor.assistedDrawing([
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------',
        '-------xx------'
    ], { wood: [ '3:0', '4:0', '5:0', '11:0' ] })
    .then(() => {
        editor.selection.clear();
        done();
    });
});

// Explain layer's panel - Select layer 1
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    focus.on('.layers-selection');
    bubble.open({ text: 'Well done! That\'s gonna be our river.\n\nNow, see this panel?', target: '.layers-selection', type: 'assistant', direction: 'left', button: true });
    requireClick.on('.bubble button')
    .then(() => {
        bubble.open({ text: 'These are the map layers - Notice that Layer 0 is now selected', target: '.layers-selection', type: 'assistant', direction: 'left', button: true });
        return requireClick.on('.bubble button');
    })
    .then(() => {
        bubble.open({ text: 'This means that you are now drawing on that layer - Let\'s try to draw on the next layer!' , target: '.layers-selection', type: 'assistant', direction: 'left', button: true });
        return requireClick.on('.bubble button');
    })
    .then(() => {
        editor.layersLocked = false;
        bubble.open({ text: 'Click on layer 1' , target: '.layers-selection', type: 'assistant', direction: 'left' });
        focus.on('.layers-selection', '.layers-selection .layer-choice-1');
        return requireClick.on('.layers-selection .layer-choice-1');
    })
    .then(() => {
        editor.layersLocked = true;
        bubble.open({ text: 'Now we are on layer 1 - Let\'s draw a bridge!', type: 'assistant', button: true });
        focus.on();
        return requireClick.on('.bubble button');
    })
    .then(done);
});

// Draw the bridge
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    focus.on('.tiles-palette');
    bubble.open({ text: 'Select a wooden bridge tile', target: '.tiles-palette', type: 'assistant', direction: 'bottom' });
    requireClick.on('[tile="21:0:wood"], [tile="27:0:wood"], [tile="5:0:wood"], [tile="11:0:wood"]')
    .then(() => {
        focus.on('.map');
        bubble.open({ text: 'Fill the highlighted area to make the brigde', target: '.map', type: 'assistant', direction: 'right' });
    });

    editor.assistedDrawing([
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '------xxxx-----',
        '------xxxx-----',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { wood: [ '21:0', '27:0' ] })
    .then(() => {
        editor.selection.clear();
        done();
    });
});

// Select layer 2
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    bubble.open({ text: 'Cool! Now select Layer 2' , target: '.layers-selection', type: 'assistant', direction: 'left' });
    focus.on('.layers-selection', '.layers-selection .layer-choice-2');
    return requireClick.on('.layers-selection .layer-choice-2')
    .then(() => {
        editor.layersLocked = true;
        bubble.open({ text: 'Now we are on layer 2, which is **above the character** - Let\'s draw some trees!', type: 'assistant', button: true });
        focus.on();
        return requireClick.on('.bubble button');
    })
    .then(() => {
        focus.off();
        done();
    });
});

// Editor introduction sequence
challenge.steps.push(modalsSequence({ title : 'Selecting more tiles at once' }, [
    { text: 'You can select more tiles at once by clicking dragging on the palette!', image: 'layers/select-tree' }
]));

// Draw some trees
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    focus.on('.tiles-palette, .map');
    bubble.open({ text: 'Draw trees in the highlighted areas', target: '.map', type: 'assistant', direction: 'right' });
    requireClick.on('[tile="28:0:wood"], [tile="29:0:wood"], [tile="34:0:wood"], [tile="35:0:wood"], [tile="41:0:wood"]', true);

    editor.assistedDrawing([
        '--x------------',
        '--x-------x----',
        '----------x----',
        '----------x----',
        '---------------',
        '---------------',
        '-----x------x--',
        '-----x------x--',
        '-----x---------',
        '---------------',
        '---------------',
        '---------------',
        '----x----------',
        '----x----------',
        '---------------'
    ], { wood: [ '28:0', '29:0', '34:0', '35:0', '41:0' ] })
    .then(() => {
        editor.selection.clear();
        done();
    });
});

// Draw some trees
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    focus.on('.layers-selection, .map', '.layer-choice-2 .action-solo');
    bubble.open({ text: 'Great! You can use this button to only see your current layer', target: '.layers-selection', direction: 'left', type: 'assistant' });
    requireClick.on('.layer-choice-2 .action-solo')
    .then(() => {

        bubble.open({ text: 'See? Now check the water layer', target: '.layers-selection', direction: 'left', type: 'assistant' });
        focus.on('.layers-selection', '.layer-choice-1 .action-solo');
        return requireClick.on('.layer-choice-1 .action-solo');

    })
    .then(() => {

        focus.on('.layers-selection, .map', null);
        return bubble.open({ text: 'This helps when you don\'t know what\'s on a layer!', target: '.layers-selection', direction: 'left', type: 'assistant', button: true });

    })
    .then(() => {

        bubble.open({ text: 'Otherwise, you can use the other button to show and hide individual layers', target: '.layers-selection', direction: 'left', type: 'assistant' });
        editor.solo = null;
        focus.on('.layers-selection, .map', '.layer-choice-1 .action-toggle');
        return requireClick.on('.layer-choice-1 .action-toggle');

    })
    .then(() => {

        bubble.open({ text: 'See?', target: '.layers-selection', direction: 'left', type: 'assistant' });
        editor.solo = null;
        focus.on('.layers-selection, .map', null);
        requireClick.on('[nothing]');

        setTimeout(() => {
            editor.hidden = [];
            requireClick.off();
            done();
        }, 2000);

    });
});

// Switch to preview mode
challenge.steps.push(function (done) {
    bubble.open({ text: 'Great! Now we have a river, a bridge and some trees! Let\'s see how it plays!', type: 'assistant', button: true });
    focus.on();
    requireClick.on('.bubble button')
    .then(() => {
        bubble.open({ text: 'Click on the play button', type: 'assistant', target: '.play-preview', direction: 'right' });
        focus.on('.play-preview');
        requireClick.on('.play-preview')
        .then(done);
    });
});

// Observe the character moving though the level
challenge.steps.push(function (done) {
    var editor = session.getEditor(),
        game = session.getGame();

    // Next tick..
    setTimeout(() => {
        focus.off();
        bubble.close();

        game.controls.emit('command', 'move(4, 7).then(function () { moveRight(6); })');

        focus.on('.map');
        requireClick.on();

        bubble.open({ text: 'See? The player walks behind the trees but on above the bridge?', target: '.map', type: 'assistant', direction: 'right' });

        // Next tick..
        setTimeout(() => {
            editor.togglePreview();
            done();
        }, 8200);
    });
});

export default new BaseChallenge(challenge);