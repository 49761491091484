import Vue from 'vue';

/*
 * Mobile Compatibility Alert component
 *
 * Show a hideable message warning the user about mobile experience not being
 * supported in current view
 */

Vue.component('mobile-compatibility-alert', {
    data            : () => {
        return {
            hidden : false
        };
    },
    template        : require('../../views/component/mobile-compatibility-alert.jade'),
    isFn            : true,
    replace         : true,
    props           : [
        'item'
    ],
    methods         : {
        hide
    }
});

function hide() {
    this.hidden = true;
}