/*
 * Geometry utility module
 *
 * A small module containing utilities to work with geometry
 */

/*
 * Get DOM element absolute bounding box with rectangle properties
 *
 * @param {HTMLElement} el
 * @return {Object}
 */
exports.getBoundingBox = function (el) {
    var bb = el.getBoundingClientRect();

    return {
        x      : bb.left,
        y      : bb.top,
        width  : bb.width,
        height : bb.height
    };
};

/*
 * Returns true if two recrangle Objects intersect
 *
 * @param {Object} a
 * @param {Object} b
 * @return {Boolean}
 */
exports.rectanglesIntersect = function (a, b) {
    var x1 = a.x,
        y1 = a.y,
        x2 = x1 + a.width,
        y2 = y1 + a.height;

    if (b.x > x1) { x1 = b.x; }
    if (b.y > y1) { y1 = b.y; }
    if (b.x + b.width < x2) { x2 = b.x + b.width; }
    if (b.y + b.height < y2) { y2 = b.y + b.height; }

    return (x2 <= x1 || y2 <= y1) ? false : true;
};