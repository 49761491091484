import Vue from 'vue';
import extend from 'deep-extend';
import config from '../config';
import assets from '../core/assets';

/*
 * Tile Display component
 *
 * Display tile image from tileset
 */

Vue.component('tile-display', {
    data            : () => {
        return {
            tile          : {},
            class         : null,
            url           : null,
            style         : {},
            computedStyle : {},
            mousedown     : function () {}
        };
    },
    template        : require('../../views/component/tile-display.jade'),
    isFn            : true,
    replace         : true,
    compiled        : init,
    beforeDestroy   : unbind,
    props           : [
        'tile',
        'mousedown',
        'class',
        'style'
    ],
    methods         : {
        update,
        render
    }
});

/*
 * Initialise component
 */
function init() {
    this.ctx = this.$el.getContext('2d');

    this.update();

    this.$watch('tile', this.update);

    this.resize = resize.bind(this);

    window.addEventListener('resize', this.resize);
}

/*
 * Handle window resize
 */
function resize() {
    this.render();
}

/*
 * Unbind generic DOM events
 */
function unbind() {
    window.removeEventListener('resize', this.resize);
}

/*
 * Update tile on position change
 */
function update() {
    assets.loadTile(this.tile, (tile) => {
        this.image = tile;

        setTimeout(this.render.bind(this));
    });
}

/*
 * Render tile on canvas
 */
function render() {
    if (!this.$el) { return; }

    var size = this.$el.offsetWidth;

    this.ctx.width = this.ctx.height = this.$el.width = this.$el.height = size * config.PIXEL_RATIO;
    this.computedStyle = extend({}, this.style || {}, { height: size + 'px' });

    // Disable smoothing
    this.ctx.imageSmoothingEnabled       = false;
    this.ctx.mozImageSmoothingEnabled    = false;
    this.ctx.msImageSmoothingEnabled     = false;
    this.ctx.oImageSmoothingEnabled      = false;

    if (!this.tile || this.tile[1] !== 1) {
        this.ctx.drawImage(this.image.canvas, 0, 0, size * config.PIXEL_RATIO, size * config.PIXEL_RATIO);
    } else {
        this.ctx.drawImage(
            this.image.canvas,
            0,
            0,
            config.TILE_SIZE_ORIGINAL[0],
            config.TILE_SIZE_ORIGINAL[1],
            0,
            0,
            size * config.PIXEL_RATIO,
            size * config.PIXEL_RATIO
            );
    }
}