import async from 'async';
import Vue from 'vue';
import modal from '../ui/modal';
import session from '../editor/session';
import clustersUtil from '../util/map-clusters';

/*
 * World Editor component
 *
 * Interface to edit maps disposition and connections
 */

const DIRECTIONS = {
        top    : [ 0, -1 ],
        right  : [ 1, 0 ],
        bottom : [ 0, 1 ],
        left   : [ -1, 0 ]
    };

Vue.component('level-editor-world', {
    data            : () => {
        return {
            level      : null,
            grids      : [],
            debug      : true,
            selected   : null,
            connecting : {
                direction : null,
                map       : null
            }
        };
    },
    template        : require('../../views/component/level-editor-world.jade'),
    isFn            : true,
    replace         : true,
    props           : [ 'level', 'debug', 'selected' ],
    created         : ready,
    methods         : {
        update,
        removeConnections,
        openConnection,
        closeConnection,
        hasConnections,
        endConnection,
        getMapLocation,
        selectMap,
        addMap,
        editMap,
        drawMap,
        deleteMap
    }
});

/*
 * Initialise component
 */
function ready() {
    this.editor = session.getEditor();
    this.editor.world = this;
    this.update();

    async.mapSeries(this.level.maps, function (map, callback) {
        map.generatePreview(callback);
    });
}

/*
 * Update clusters and grids - called after structural changes to update
 * rendering
 */
function update() {
    this.grids = clustersUtil.formClusters(this.level.maps)
    .sort(function (a, b) {
        return a.length > b.length ? -1 : 1;
    })
    .map((cluster) => {
        return clustersUtil.formGrid(cluster);
    });
}

/*
 * Remove connections on given map
 *
 * @param {Map} map
 */
function removeConnections(map) {
    var dir, index, facing;

    for (dir in map.connections) {
        if (map.connections.hasOwnProperty(dir)) {
            index = map.connections[dir];

            if (index === null) { continue; }

            facing = getFacingDirection(dir);
            map.connections[dir] = null;
            this.level.maps[index].connections[facing] = null;
        }
    }

    this.update();
}

/*
 * Set connecting state to initiate from given map and direction
 *
 * @param {Map} map
 * @param {String} direction
 */
function openConnection(map, direction) {
    if (this.connecting.map) {
        this.endConnection();
        return;
    }

    this.connecting.direction = direction;
    this.connecting.map = map;
}

/*
 * Close initiated connection onto given map and end connecting state
 *
 * @param {Map} map
 */
function closeConnection(map) {
    var index = this.level.maps.indexOf(map),
        dir = this.connecting.direction,
        facing = getFacingDirection(dir),
        origin = this.getMapLocation(this.connecting.map),
        offset = DIRECTIONS[dir],
        position = [ origin.loc[0] + offset[0], origin.loc[1] + offset[1] ],
        adjacentMap, x, y;

    Object.keys(DIRECTIONS).forEach((dir) => {
        offset = DIRECTIONS[dir];
        facing = getFacingDirection(dir);
        x = position[0] + offset[0];
        y = position[1] + offset[1];
        adjacentMap = getMapByLocation(origin.grid, [ x, y ]);

        if (!adjacentMap) { return; }

        map.connections[dir] = this.level.maps.indexOf(adjacentMap);
        adjacentMap.connections[facing] = index;
    });

    this.endConnection();
    this.update();
}

/*
 * End connecting state
 */
function endConnection() {
    this.connecting.map = null;
    this.connecting.direction = null;
}

/*
 * Get location in grid of given map
 *
 * @param {Map} map
 * @return {Object|null}
 */
function getMapLocation(map) {
    var grid, x, y;

    for (grid of this.grids) {
        for (y = 0; y < grid.length; y++) {
            for (x = 0; x < grid[y].length; x++) {
                if (grid[y][x] === map) {
                    return {
                        grid : grid,
                        loc  : [ x, y ],
                    };
                }
            }
        }
    }

    return null;
}

/*
 * Get map by grid and location in grid
 *
 * @param {[Array]} grid
 * @param {Object} loc
 * @return {Map|null}
 */
function getMapByLocation(grid, loc) {
    return grid[loc[1]] ? grid[loc[1]][loc[0]] || null : null;
}

/*
 * Get direction string opposed to given one (E.g. 'top' will return 'bottom')
 *
 * @param {String} dir
 * @return {String}
 */
function getFacingDirection(dir) {
    let dirs = Object.keys(DIRECTIONS);

    return dirs[(dirs.indexOf(dir) + 2) % dirs.length];
}

/*
 * Return true if map has any connections
 *
 * @param {Map} map
 * @return {Boolean}
 */
function hasConnections(map) {
    for (let dir in map.connections) {
        if (map.connections.hasOwnProperty(dir) && map.connections[dir] !== null) {
            return true;
        }
    }

    return false;
}

/*
 * Add a new map
 *
 * @return {Map}
 */
function addMap() {
    let map = this.editor.addMap(true, (map) => {
        if (this.connecting.map) {
            this.closeConnection(map);
        }

        this.update();
    });
}

/*
 * Select given map
 *
 * @param {Map} map
 */
function selectMap(map) {
    this.editor.map = map;
}

/*
 * Select given map and switch to map mode
 *
 * @param {Map} map
 */
function editMap(map) {
    this.selectMap(map);
    this.editor.mode = 'map';
}

/*
 * Select given map and switch to draw mode
 *
 * @param {Map} map
 */
function drawMap(map) {
    this.selectMap(map);
    this.editor.mode = 'draw';
}

/*
 * Delete given map - updatte connection indexes for all maps, select another
 *
 * @param {Map} map
 */
function deleteMap(map) {
    let index = this.level.maps.indexOf(map),
        selectedIndex = this.level.maps.indexOf(this.editor.map),
        cur, dir;

    if (this.level.maps.length <= 1) {
        return modal.open('alert', { text: 'You can\'t delete the only map' });
    }

    modal.open('confirm', {}, (confirmed) => {
        if (!confirmed) { return; }

        for (cur of this.level.maps) {
            for (dir in cur.connections) {
                if (cur.connections.hasOwnProperty(dir)) {
                    if (cur.connections[dir] === index) {
                        cur.connections[dir] = null;
                    } else if (cur.connections[dir] > index) {
                        cur.connections[dir] -= 1;
                    }
                }
            }
        }

        this.level.maps.splice(index, 1);

        if (selectedIndex === index) {
            this.selectMap(
                this.level.maps[index] ||
                this.level.maps[index - 1] ||
                this.level.maps[0]
                );
        }

        this.update();
    });
}