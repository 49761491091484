import challenges from '../challenges/index';
import router from '../core/router';

/*
 * Challenges controller
 *
 * Challenges list view controller
 */

export var scope = {
    challenge : null
};

/*
 * Initialise controller
 *
 * @param {Object} req
 */
export function run(req) {
    var slug = req.namedParams.slug,
        challenge = challenges.getBySlug(slug);

    if (!challenge) {
        return router.goTo('/404-not-found');
    }

    this.challenge = challenge;
}