import slug from 'slug';

/*
 * BaseChallenge class
 *
 * Wrapper class for editor challenges
 */

export default class BaseChallenge {

    /*
     * Challenge constructor
     *
     * @param {Object} options
     */
    constructor(options) {
        this.steps = options.steps || [];
        this.name = options.name || 'unnamed';
        this.level = options.level || null;
        this.hide = options.hide || {};
        this.slug = slug(this.name).toLowerCase();
    }

}