import Vue from 'vue';
import geometryUtil from '../util/geometry';
import config from '../config';
import session from '../editor/session';
import tilesets from '../editor/tilesets';
import tileUtil from '../util/tile';

/*
 * Tile Palette component
 *
 * Tile selection palette
 */

Vue.component('tiles-palette', {
    data            : () => {
        return {
            tileset        : config.DEFAULT_TILESET,
            tiles          : [],
            highlightStyle : null,
            clickTile      : null,
            width          : null,
            height         : null,
            adaptive       : true,
            enabled        : null
        };
    },
    template        : require('../../views/component/tiles-palette.jade'),
    isFn            : true,
    replace         : true,
    ready           : ready,
    beforeDestroy   : unbind,
    props           : [ 'tileset', 'clickTile', 'adaptive', 'enabled' ],
    methods         : {
        renderTiles,
        tileMouseDown,
        dragMove,
        getDragArea,
        updateHighlight,
        dragFinish,
        getTileRectByPos,
        getTilePosByCoords,
    }
});

/*
 * Initialise component
 */
function ready() {
    // Name parent, add reference to parent
    this.editor = session.getEditor();
    this.editor.palette = this;

    // Render tiles from tileset
    this.renderTiles();

    // Bind tileset change
    this.$watch('tileset', this.renderTiles);
    this.$watch('adaptive', this.renderTiles);
    this.$watch('enabled', this.renderTiles);

    // Prepare self bound callbacks
    this.mouseup = mouseup.bind(this);
    this.mousemove = mousemove.bind(this);

    // Bind editor events
    this.editor.events.on('selectionchange', this.updateHighlight.bind(this));
    this.editor.events.on('mousemove', this.mousemove);

    // Bind DOM events
    window.addEventListener('mouseup', this.mouseup);
}

/*
 * Unbind generic DOM events
 */
function unbind() {
    // Unbind editor events
    this.editor.events.off('mousemove', this.mousemove);

    // Unbind DOM events
    window.removeEventListener('mouseup', this.mouseup);
}

/*
 * Generate tiles array to display tileset
 */
function renderTiles() {
    var tilesetOptions = tilesets.get(this.tileset),
        special = 1 + tilesetOptions.adaptive,
        empties = config.TILESET_SIZE[0] - special % config.TILESET_SIZE[0] || config.TILESET_SIZE[0] - 1,
        x = 0,
        y = 0,
        width = 0,
        height = 0,
        tileSize = this.editor.getTileSize(),
        clear = {
            style    : getStyle(),
            tile     : '__clear__',
            selected : false,
            enabled  : !this.enabled || this.enabled.indexOf('__clear__') !== -1
        },
        tile, i, key;

    if (!this.adaptive) {
        empties = config.TILESET_SIZE[0] - 1;
    } else if (empties === config.TILESET_SIZE[0]) {
        empties = 0;
    }

    this.tiles = [ clear ];
    step();

    if (this.adaptive) {
        for (i = 0; i < tilesetOptions.adaptive; i++) {
            tile = [ i, 1, this.tileset ];
            key = tileUtil.getUniqueKey(tile);

            this.tiles.push({
                style    : getStyle(),
                selected : false,
                enabled  : !this.enabled || this.enabled.indexOf(key) !== -1,
                tile, key, x, y
            });

            step();
        }
    }

    for (i = 0; i < empties; i++) {
        this.tiles.push({
            style    : getStyle(),
            tile     : null,
            selected : false,
            enabled  : !this.enabled,
            x, y
        });

        step();
    }

    for (i = 0; i < config.TILESET_SIZE[0] * config.TILESET_SIZE[1]; i++) {
        tile = [ i, 0, this.tileset ];
        key = tileUtil.getUniqueKey(tile);

        this.tiles.push({
            style    : getStyle(),
            selected : false,
            enabled  : !this.enabled || this.enabled.indexOf(key) !== -1,
            tile, key, x, y
        });
        step();
    }

    /*
     * Step to next x and y
     */
    function step() {
        x++;

        x = x % config.TILESET_SIZE[0];

        if (x === 0) {
            y++;
        }

        width = Math.max(width, x + 1);
        height = Math.max(height, y + 1);
    }

    this.width = width * tileSize;
    this.height = height * tileSize;

    /*
     * Get current tile CSS style
     *
     * @return {Object}
     */
    function getStyle() {
        return {
            position : 'absolute',
            left     : x * tileSize + 'px',
            top      : y * tileSize + 'px'
        };
    }
}

/*
 * Called on mouse move
 *
 * @param {Object} mouse
 */
function mousemove(mouse) {
    let bb = geometryUtil.getBoundingBox(this.$el);

    this.mouse = {
        x : mouse.x - bb.x,
        y : mouse.y - bb.y
    };

    if (this.dragging) {
        this.dragEnd = {
            x : this.mouse.x,
            y : this.mouse.y
        };

        this.dragMove();
        this.updateHighlight();
    }
}

/*
 * Called on mouse down over tile
 */
function tileMouseDown() {
    this.editor.selection.hide();

    this.dragStart = {
        x : this.mouse.x,
        y : this.mouse.y
    };

    this.dragEnd = this.dragStart;
    this.dragMove();

    if (this.editor.mode === 'draw') {
        this.dragging = true;
    } else {
        this.dragFinish();
    }
}

/*
 * Called on mouse up
 */
function mouseup() {
    if (this.dragging) {
        this.dragFinish();
    }
}

/*
 * Called while dragging - update selection highlight and detect hit tiles
 */
function dragMove() {
    var tiles = [],
        dragArea = this.getDragArea(),
        y = -1,
        x, startPos, endPos, tile, tileRect, i, pos;

    for (i = 0; i < this.tiles.length; i++) {
        x = i % config.TILESET_SIZE[0];

        if (x === 0) {
            y++;
        }

        pos = { x, y };

        tile = this.tiles[i];
        tileRect = this.getTileRectByPos(pos);

        if (geometryUtil.rectanglesIntersect(dragArea, tileRect)) {
            tile.selected = true;

            if (!startPos) {
                startPos = pos;
            }

            endPos = pos;

            tiles.push(tile.tile);
        } else {
            tile.selected = false;
        }
    }

    if (!tiles.length) { return; }

    this.selectionArea = {
        x      : startPos.x,
        y      : startPos.y,
        width  : endPos.x - startPos.x + 1,
        height : endPos.y - startPos.y + 1
    };

    tiles = tiles.map((tile) => {
        if (!tile) { return null; }

        if (!this.enabled) { return tile; }

        if (
            tile === '__clear__' && this.enabled.indexOf('__clear__') === -1 ||
            this.enabled.indexOf(tileUtil.getUniqueKey(tile)) === -1
            ) {
            return null;
        }

        return tile;
    });

    this.selectedTiles = tiles;
    this.updateHighlight();
}

/*
 * Get a tile's rectangular frame (In pixels) by index
 *
 * @param {Number} index
 * @return {Object}
 */
function getTileRectByPos(pos) {
    var tileSize = this.editor.getTileSize();

    return {
        x      : pos.x * tileSize,
        y      : pos.y * tileSize,
        width  : tileSize,
        height : tileSize
    };
}

/*
 * Get X and Y tileset coordinates of a tile by its index
 *
 * @param {Number} index
 * @return {Object}
 */
function getTilePosByCoords(pos) {
    return {
        x : pos.x * config.TILESET_SIZE[0],
        y : pos.y * config.TILESET_SIZE[1]
    };
}

/*
 * Called once drag is over - Update selection and current state
 */
function dragFinish() {
    this.dragMove();
    this.dragging = false;

    if (!this.selectionArea) { return; }

    if (this.clickTile) {
        return this.clickTile(this.selectedTiles[0]);
    }

    this.editor.selection.show();

    this.editor.selection.set({
        width  : this.selectionArea.width,
        height : this.selectionArea.height,
        tiles  : this.selectedTiles
    });
}

/*
 * Get rectangle of current drag area
 *
 * @return {Object}
 */
function getDragArea() {
    if (!this.dragStart) { return; }

    var start = this.dragStart,
        end = this.dragEnd,
        rect = {
            x      : start.x,
            y      : start.y,
            width  : end.x - start.x + 1,
            height : end.y - start.y + 1
        };

    if (rect.width < 0) {
        rect.x += rect.width;
        rect.width = Math.abs(rect.width);
    }

    if (rect.height < 0) {
        rect.y += rect.height;
        rect.height = Math.abs(rect.height);
    }

    if (rect.width < 1) { rect.width = 1; }
    if (rect.height < 1) { rect.height = 1; }

    return rect;
}

/*
 * Update current drag / selection highlight styles
 */
function updateHighlight() {
    var tileSize = this.editor.getTileSize(),
        tile;

    if (!this.editor.selection.tiles.length && !this.dragging || !this.selectionArea) {
        this.highlightStyle = null;

        for (tile of this.tiles) {
            tile.selected = false;
        }

        return;
    }

    this.highlightStyle = {
        left    : this.selectionArea.x * tileSize + 'px',
        top     : this.selectionArea.y * tileSize + 'px',
        width   : this.selectionArea.width * tileSize + 'px',
        height  : this.selectionArea.height * tileSize + 'px',
    };
}