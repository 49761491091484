import Vue from 'vue';
import fonts from '../data/fonts';
import error from '../core/error';
import session from '../editor/session';

/*
 * Map Editor component
 *
 * Interface to edit map scripts, meta data and settings inside level editor
 */

Vue.component('level-editor-map', {
    data            : () => {
        return {
            map         : null,
            fonts       : fonts,
            fontPreview : null
        };
    },
    template        : require('../../views/component/level-editor-map.jade'),
    isFn            : true,
    replace         : true,
    props           : [ 'map' ],
    created         : init,
    methods         : {
        select,
        getId,
        updateFontPreview
    }
});

/*
 * Initialise component
 */
function init() {
    this.editor = session.getEditor();
    this.editor.mapEditor = this;
    this.$watch('map.config.font', this.updateFontPreview);
    this.$watch('map.name', this.updateFontPreview);
    this.updateFontPreview();
}

/*
 * Update ASCII font preview
 */
function updateFontPreview() {
    figlet(this.map.name || 'Lorem Ipsum', this.map.config.font, (err, text) => {
        if (err) { return error.handle(err); }

        this.fontPreview = text;
    });
}

/*
 * Get map ID
 *
 * @param {Map} map
 */
function getId(map) {
    if (!this.editor) { return null; }

    for (let id in this.editor.level.maps) {
        if (this.editor.level.maps.hasOwnProperty(id)) {
            if (this.editor.level.maps[id] === map) {
                return id;
            }
        }
    }

    return null;
}

/*
 * Select given map
 *
 * @param {Map} map
 */
function select(map) {
    this.editor.map = map;
}