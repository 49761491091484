import error from '../core/error';
import kanoWorld from '../core/kano-world';

/*
 * Join modal
 *
 * Modal to sign up / in Kano World
 */

var data = {
        signupForm  : {
            username : '',
            email    : '',
            password : '',
            terms    : false
        },
        signinForm  : {
            username : '',
            password : ''
        },
        error   : '',
        loading : '',
        tab     : 'signup'
    },
    methods = { getNextError, submit };

/*
 * Initialise modal
 */
function run() {
    this.$watch('tab', () => {
        this.error = null;
    });
}

/*
 * Get next validation error
 *
 * @return {String|null}
 */
function getNextError() {
    if (this.tab === 'signup') {

        if (!this.signupForm.username) {
            return 'Username required';
        } else if (!this.signupForm.email) {
            return 'Email required';
        } else if (!this.signupForm.password) {
            return 'Password required';
        } else if (this.signupForm.password.length < 3) {
            return 'Password too short';
        } else if (!this.signupForm.terms) {
            return 'Please accept Terms and Conditions';
        }

    } else if (this.tab === 'signin') {

        if (!this.signinForm.username) {
            return 'Username required';
        } else if (!this.signinForm.password) {
            return 'Password required';
        }

    }

    return null;
}

/*
 * Submit signin / signup form
 *
 * @param {SubmitEvent} e
 */
function submit(e) {
    var endpoint = this.tab === 'signup' ? kanoWorld.sdk.api.users.post : kanoWorld.sdk.api.auth.post,
        formData = this.tab === 'signup' ? this.signupForm : this.signinForm;

    e.preventDefault();

    this.error = this.getNextError();

    if (this.error) { return; }

    endpoint(formData)
    .then((res) => {
        this.done(res.body.session);
    }, (res) => {
        this.error = res.body;
    })
    .catch(error.handle);
}

export default { data, methods, run };