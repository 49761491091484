import Vue from 'vue';
import keycode from 'keycode';
import geometryUtil from '../util/geometry';
import tileUtil from '../util/tile';
import entities from '../editor/entities';
import config from '../config';
import session from '../editor/session';

/*
 * Level Editor Selection component
 *
 * Selection preview and tile placing UI
 */

Vue.component('level-editor-selection', {
    data            : () => {
        return {
            tiles        : [],
            behaviours   : [],
            items        : [],
            width        : 0,
            height       : 0,
            visible      : false,
            previewStyle : null,
            assisted     : null
        };
    },
    template        : require('../../views/component/level-editor-selection.jade'),
    isFn            : true,
    replace         : true,
    ready           : ready,
    beforeRemove    : unbind,
    props           : [ 'assisted' ],
    methods         : {
        set: set,
        get,
        clear,
        show,
        hide,
        apply,
        updatePreview,
        snapToMap,
        mousemove,
        drawTiles,
        drawItem,
        getItemId,
        addEntity
    }
});

/*
 * Initialise component
 */
function ready() {
    this.editor = session.getEditor();
    this.editor.selection = this;

    this.mousedown = mousedown.bind(this);
    this.mouseup = mouseup.bind(this);
    this.keydown = keydown.bind(this);

    window.addEventListener('mousedown', this.mousedown);
    window.addEventListener('mouseup', this.mouseup);
    window.addEventListener('keydown', this.keydown);

    this.editor.events.on('mousemove', this.mousemove.bind(this));
}

/*
 * Unbind generic DOM events
 */
function unbind() {
    window.removeEventListener('mousedown', this.mousedown);
    window.removeEventListener('mouseup', this.mouseup);
    window.removeEventListener('keydown', this.keydown);
}

/*
 * Called on key down - Clear selection when pressing ESC
 *
 * @param {KeyDownEvent} e
 */
function keydown(e) {
    var key = keycode(e);

    if (key === 'esc') {
        this.clear();
        this.editor.events.emit('selectionclear');
    }
}

/*
 * Called on mouse move - update preview, apply current selection if drawing
 */
function mousemove() {
    this.updatePreview();

    if (this.drawing && this.snapped) {
        this.apply();
    }
}

/*
 * Called on mouse down - Set drawing status and draw tiles if snapped
 */
function mousedown() {
    this.drawing = true;

    if (this.snapped) {
        this.apply();
    }

}

/*
 * Stop drawing on mouse up
 */
function mouseup() {
    this.drawing = false;
}

/*
 * Get current selection properties
 *
 * @return {Object}
 */
function get() {
    return {
        behaviours : this.behaviours,
        tiles      : this.tiles,
        items      : this.items,
        width      : this.width,
        height     : this.height
    };
}

/*
 * Set selection properties and update
 *
 * @param {Object} options
 */
function set(options) {
    var key;

    for (key in options) {
        this[key] = options[key];
    }

    this.updatePreview();
    this.editor.events.emit('selectionchange', this);
}

/*
 * Clear selection
 */
function clear() {
    this.hide();

    this.set({
        behaviours : [],
        tiles      : [],
        items      : [],
        width      : 0,
        height     : 0
    });
}

/*
 * Update selection preview style
 */
function updatePreview() {
    if (!this.tiles.length && !this.behaviours.length && !this.items.length) {
        this.previewStyle = {
            display : 'none'
        };

        return;
    }

    let tileSize = this.editor.getTileSize();

    this.previewRect = {
        x      : this.editor.mouse.x - (this.width * tileSize) / 2,
        y      : this.editor.mouse.y - (this.height * tileSize) / 2,
        width  : this.width * tileSize,
        height : this.height * tileSize
    };

    this.snapToMap();

    // Update preview styles
    this.previewStyle = {
        left   : this.previewRect.x + 'px',
        top    : this.previewRect.y + 'px',
        width  : this.previewRect.width + 'px',
        height : this.previewRect.height + 'px'
    };
}

/*
 * Show selection preview
 */
function show() {
    this.visible = false;
}

/*
 * Hide selection preview
 */
function hide() {
    this.visible = false;
}

/*
 * Snap selection to map if intersecting
 */
function snapToMap() {
    var mapEl = this.editor.getMapElement(),
        tileSize, mapRect;

    if (!mapEl) { return; }

    tileSize = this.editor.getTileSize();
    mapRect = geometryUtil.getBoundingBox(mapEl);

    if (!geometryUtil.rectanglesIntersect(mapRect, this.previewRect)) {
        this.snapped = false;
        return;
    }

    var relative = {
            x : this.previewRect.x - mapRect.x,
            y : this.previewRect.y - mapRect.y
        };

    this.snapped = {
        x : Math.floor(relative.x / tileSize + 0.5),
        y : Math.floor(relative.y / tileSize + 0.5)
    };

    this.previewRect.x = mapRect.x + this.snapped.x * tileSize;
    this.previewRect.y = mapRect.y + this.snapped.y * tileSize;
}

/*
 * Apply selection to map, through the editor
 */
function apply() {
    var mode = this.editor.mode,
        x = this.snapped.x,
        y = this.snapped.y;

    if (mode === 'draw') {
        if (this.editor.layerType === 'tiles') {
            this.drawTiles();
        } else if (this.editor.layerType === 'items') {
            this.drawItem();
        }
    }

    if (x < 0 || y < 0 || x >= config.MAP_SIZE[0] || y >= config.MAP_SIZE[0]) {
        return;
    }

    if (mode === 'entities') {
        this.addEntity();
    } else if (mode === 'behaviours') {
        this.editor.behaviours.draw(this.snapped.x, this.snapped.y);
    }
}

/*
 * Draw selection onto snapped position in active editor map
 */
function drawTiles() {
    let map = this.editor.map,
        x, y;

    if (this.editor.tool === 'pencil') {

        for (y = 0; y < this.height; y++) {
            for (x = 0; x < this.width; x++) {
                if (this.assisted && !this.assisted.isInArea(this.snapped.x + x, this.snapped.y + y)) { return; }

                let tile = this.tiles[y * this.width + x],
                    layer = this.editor.layer;

                if (!tile) { continue; }

                if (tile === '__clear__') {
                    tile = null;
                }

                map.setTile(layer, this.snapped.x + x, this.snapped.y + y, tileUtil.copy(tile));
            }
        }

    } else if (this.editor.tool === 'bucket') {

        if (!this.tiles.length) { return; }

        let targets = this.editor.map.getBucketTargets(this.snapped.x, this.snapped.y, this.editor.layer),
            rect = getPositionsBoundingBox(targets),
            pos;

        this.editor.viewer.preventRenderChanges = true;

        for (pos of targets) {
            if (this.assisted && !this.assisted.isInArea(pos.x, pos.y)) { continue; }

            map.setTile(this.editor.layer, pos.x, pos.y, tileUtil.copy(this.tiles[0]));
        }

        this.editor.viewer.updateRectangle(rect, this.editor.layer);
        this.editor.viewer.preventRenderChanges = false;

    }
}

/*
 * Get bounding rectangle from Array of x, y position Objects
 *
 * @param {[Object]} positions
 * @return {Object}
 */
function getPositionsBoundingBox(positions) {
    var minX = Infinity,
        minY = Infinity,
        maxX = 0,
        maxY = 0,
        pos;

    for (pos of positions) {
        if (pos.x < minX) { minX = pos.x; }
        if (pos.y < minY) { minY = pos.y; }
        if (pos.x > maxX) { maxX = pos.x; }
        if (pos.y > maxY) { maxY = pos.y; }
    }

    return {
        x      : minX,
        y      : minY,
        width  : maxX - minX + 1,
        height : maxY - minY + 1
    };
}

/*
 * Draw item onto snapped position in active editor map
 */
function drawItem() {
    let x = this.snapped.x,
        y = this.snapped.y,
        id = this.getItemId(this.items[0]);

    if (this.assisted && !this.assisted.isInArea(x, y)) { return; }

    this.editor.map.setItem(x, y, id);
}

/*
 * Get item id by options
 *
 * @param {Object} item
 * @return {String}
 */
function getItemId(item) {
    let items = this.editor.level.items,
        key;

    for (key in items) {
        if (items[key] === item) {
            return key;
        }
    }

    return null;
}

/*
 * Add selected entity to active editor map
 */
function addEntity() {
    var tile = this.tiles[0],
        template = null,
        cur;

    if (!tile) { return; }
    if (this.assisted && !this.assisted.isInArea(this.snapped.x, this.snapped.y)) { return; }

    for (cur of entities) {
        if (cur.tile[0] === tile[0] && cur.tile[1] === tile[1]) {
            template = cur;
        }
    }

    this.editor.map.setEntity(this.snapped.x, this.snapped.y, template, this.editor.level);
}