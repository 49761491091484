import BaseChallenge from '../class/BaseChallenge';
import modalsSequence from './util/modals-sequence';
import requireClick from '../ui/require-click';
import focus from '../ui/focus';
import bubble from '../ui/bubble';
import session from '../editor/session';

/*
 * Draw Floor challenge
 *
 * First challenge in learn mode, teaches to select and draw tiles
 */

var challenge = {
        name  : 'World',
        steps : [],
        hide  : {
            items      : true,
            behaviours : true,
            map        : true,
            world      : true
        }
    };

// Editor introduction sequence
challenge.steps.push(modalsSequence({ title : 'World' }, [
    { text: 'So, now you can draw a map, but what if we want to draw a larger game?', assistant: 'standing' },
    { text: 'There is, of course, a panel for that! The World panel', image: 'world/panel' },
]));

// Select layer 1
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Let\'s draw a pathway' , target: '.map', type: 'assistant', direction: 'right' });
    focus.on('.tiles-palette, .map');

    editor.layer = 1;

    editor.assistedDrawing([
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '------xxx------',
        '------xxxxxxxxx',
        '------xxx------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { wood: [ '8:0', '9:0', '12:0', '14:0', '15:0', '16:0', '33:0', '36:0', '38:0', '39:0', '1:1', '5:1' ] })
    .then(() => {
        editor.hide.world = false;
        editor.selection.clear();
        focus.off();
        bubble.close();

        // Next tick..
        setTimeout(done);
    });
});

// Go to World panel
challenge.steps.push(function (done) {
    bubble.open({ text: 'Now click on the World panel', type: 'assistant', target: '.tool.tool-world', direction: 'left' });
    focus.on('.tool.tool-world');
    requireClick.on('.tool.tool-world')
    .then(() => {
        // Next tick..
        setTimeout(done);
    });
    
});

// REMOVE ME
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.hide.world = false;
    editor.mode = 'world';

    setTimeout(done);
});

// Explain World panel
challenge.steps.push(function (done) {
    bubble.open({ text: 'This is the map you\'ve just drawn - Let\'s create another one', type: 'assistant', target: '.cell', direction: 'bottom', button: true });
    requireClick.on('.bubble button')
    .then(() => {

        focus.on('.cell.add');
        bubble.open({ text: 'Click here to add a new map', type: 'assistant', target: '.cell.add', direction: 'bottom' });
        return requireClick.on('.cell.add');

    })
    .then(() => {
        bubble.close();
        focus.off();
        setTimeout(done, 500);
    });
});

// Create a new map
challenge.steps.push(function (done) {
    var input = document.querySelector('.modal input'),
        editor = session.getEditor(),
        cancelButton = document.querySelector('.modal a.button.danger');

    cancelButton.style.display = 'none';

    focus.on('.modal', '.modal input, .modal button.save, .modal button.success');
    input.focus();
    bubble.open({ text: 'Choose a name for the new map and click on Done', type: 'assistant', target: '.modal', direction: 'right' });

    editor.events.once('addmap', () => {
        focus.off();
        bubble.close();

        setTimeout(done, 500);
    });
});

// Click on connector
challenge.steps.push(function (done) {
    var connector = document.querySelector('.cluster:first-child .connector-left');

    connector.style.display = 'block';

    bubble.open({ text: 'Cool! Now, let\'s connect the two maps - Click on the connector', type: 'assistant', target: '.cluster', direction: 'bottom' });
    focus.on('.cluster:first-child', '.cluster:first-child .connector-left');
    requireClick.on('.cluster:first-child .connector-left')
    .then(() => {
        setTimeout(done);
    }, done);
});

// Click on connectable
challenge.steps.push(function (done) {
    var connectable = document.querySelector('.connector-connectable');

    connectable.style.display = 'block';

    bubble.open({ text: 'And connect it with the first map', type: 'assistant', target: '.connector-connectable', direction: 'bottom' });
    focus.on('.cluster:nth-child(2)', '.connector-connectable');
    requireClick.on('.connector-connectable')
    .then(() => {
        // Next tick..
        setTimeout(done);
    }, done);
});

// Show connected maps - Edit new map
challenge.steps.push(function (done) {
    bubble.open({ text: 'See? The two maps are now connected', type: 'assistant', target: '.cluster:first-child', direction: 'bottom', button: true });
    focus.on('.cluster:first-child');
    requireClick.on('.bubble button')
    .then(() => {
        var actionsWrap = document.querySelector('.cluster:first-child .cell:last-child .actions');

        actionsWrap.style.display = 'block';

        bubble.open({ text: 'Now let\'s edit the new map - Click on the edit button', type: 'assistant', target: '.cluster:first-child', direction: 'bottom' });
        focus.on('.cluster:first-child', '.cluster:first-child .cell:last-child .action-draw');
        return requireClick.on('.cluster:first-child .cell:last-child .action-draw');

    })
    .then(() => {
        // Next tick..
        setTimeout(done);
    });
});

// Draw rest of the pathway
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Let\'s continue the pathway on this map' , target: '.map', type: 'assistant', direction: 'right' });
    focus.on('.tiles-palette, .map');

    editor.layer = 1;

    editor.assistedDrawing([
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '------xxx------',
        'xxxxxxxxx------',
        '------xxx------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { wood: [ '8:0', '9:0', '12:0', '14:0', '15:0', '16:0', '33:0', '36:0', '38:0', '39:0', '1:1', '5:1' ] })
    .then(() => {
        editor.hide.world = false;
        editor.selection.clear();
        focus.off();
        bubble.close();

        // Next tick..
        setTimeout(done);
    });
});

// Go to logic mode
challenge.steps.push(function (done) {
    focus.on('.tool.tool-logic');
    bubble.open({ text: 'Now, let\'s go to Logic mode', type: 'assistant', target: '.tool.tool-logic', direction: 'left' });
    return requireClick.on('.tool.tool-logic')
    .then(() => {
        // Next tick..
        setTimeout(done);
    });
});

// Go to logic mode
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Set the spawn point to the middle of the pathway' , target: '.map', type: 'assistant', direction: 'right' });
    focus.on('[tile="logic:spawn"], .map');

    editor.assistedDrawing([
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '-------x-------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { logic: [ 'spawn' ] })
    .then(() => {
        editor.hide.world = false;
        editor.selection.clear();
        focus.off();
        bubble.close();

        // Next tick..
        setTimeout(done);
    });
});

// Switch to preview
challenge.steps.push(function (done) {
    bubble.open({ text: 'Cool - Now let\'s try the map!', type: 'assistant', target: '.play-preview', direction: 'right' });
    focus.on('.play-preview');
    requireClick.on('.play-preview')
    .then(() => {
        bubble.close();
        // Next tick..
        setTimeout(done);
    });
});

// Test the map
challenge.steps.push(function (done) {
    var game = session.getGame(),
        editor = session.getEditor();

    focus.on('.map');
    game.controls.emit('command', 'moveLeft(15)');
    requireClick.on('[nothing]');

    setTimeout(() => {
        bubble.open({ text: 'See? The maps work together now! Pretty cool, huh?', type: 'assistant', target: '.play-preview', direction: 'right', button: true });
        requireClick.on('.bubble button')
        .then(() => {
            requireClick.off();
            editor.togglePreview();
            focus.off();
            // Next tick..
            setTimeout(done);
        });
    }, 6000);
});

// Outro sequence
challenge.steps.push(modalsSequence({ title : 'World' }, [
    { text: 'Well done! You created multiple maps and connected them!', assistant: 'standing' },
    { text: 'You can create worlds as big as you like - Feel free to go crazy!', assistant: 'standing' }
]));

export default new BaseChallenge(challenge);