import Vue from 'vue';
import objectUtil from '../util/object';

/*
 * Attributes Editor component
 *
 * Display component to edit an attributes Object
 */

Vue.component('attributes-editor', {
    data            : () => {
        return {
            attributes : {},
            struct     : {}
        };
    },
    template        : require('../../views/component/attributes-editor.jade'),
    isFn            : true,
    replace         : true,
    props           : [ 'attributes', 'struct' ],
    methods         : {
        isEmpty: objectUtil.isEmpty
    }
});