/*
 * Map Clusters utility module
 *
 * Exports methods to form clusters from Arrays of Maps
 */

const DIRECTIONS = {
        top    : [ 0, -1 ],
        right  : [ 1, 0 ],
        bottom : [ 0, 1 ],
        left   : [ -1, 0 ]
    };

/*
 * Form clusters from given maps
 *
 * @param {[Map]} maps
 * @return {[Map]}
 */
exports.formClusters = function (maps) {
    var clusters = [],
        i, n, a, b, clusterA, clusterB;

    // Create clusters
    for (i = 0; i < maps.length; i++) {
        maps[i]._id = i;
        clusters.push([ maps[i] ]);
    }

    // Merge clusters of communicating maps
    for (i = 0; i < maps.length; i++) {
        a = maps[i];


        for (n = 0; n < maps.length; n++) {
            b = maps[n];

            clusterA = getCluster(a);
            clusterB = getCluster(b);

            for (let dir of Object.keys(DIRECTIONS)) {

                // If maps are communicating
                if (b.connections[dir] === i) {

                    if (clusterA !== clusterB) {
                        mergeClusters(clusterA, clusterB);
                    }

                }

            }
        }
    }

    // Remove empty clusters
    for (i = 0; i < clusters.length; i++) {
        if (clusters[i].length) { continue; }
        clusters.splice(i, 1);
        i--;
    }

    return clusters;

    /*
     * Merge two clusters by their indexes (Leave the second empty)
     *
     * @param {Number} a
     * @param {Number} b
     */
    function mergeClusters(a, b) {
        clusters[a] = clusters[a].concat(clusters[b].splice(0, clusters[b].length));
    }

    /*
     * Get index of cluster containing given map
     *
     * @param {Map} map
     * @return {Number}
     */
    function getCluster(map) {
        for (let i in clusters) {
            if (clusters[i].indexOf(map) !== -1) {
                return i;
            }
        }

        return null;
    }
};

/*
 * Form graid from given maps cluster
 *
 * @param {[Map]} cluster
 * @return {[Map]}
 */
exports.formGrid = function (cluster) {
    var locations = {},
        processed = [],
        grid = [],
        size = [ 0, 0 ],
        offset = [ 0, 0 ],
        key, coords, x, y, index;

    addToGridRecursive(0, 0, 0);

    for (key in locations) {
        if (locations.hasOwnProperty(key)) {
            coords = parseCoordinates(key);

            if (coords[0] < offset[0]) {
                offset[0] = coords[0];
            }

            if (coords[1] < offset[1]) {
                offset[1] = coords[1];
            }

            if (coords[0] + 1 > size[0]) {
                size[0] = coords[0] + 1;
            }

            if (coords[1] + 1 > size[1]) {
                size[1] = coords[1] + 1;
            }
        }
    }

    offset[0] = Math.abs(offset[0]);
    offset[1] = Math.abs(offset[1]);

    for (y = 0; y < size[1] + offset[1]; y++) {
        grid[y] = [];

        for (x = 0; x < size[0] + offset[0]; x++) {
            key = (x - offset[0]) + ',' + (y - offset[1]);

            index = locations[key];

            grid[y][x] = index === null ? null : cluster[index];
        }
    }

    return grid;

    /*
     * Recursively add map of given index and connected maps to grid
     *
     * @param {Number} i
     * @param {Number} x
     * @param {Number} y
     */
    function addToGridRecursive(i, x, y) {
        locations[x + ',' + y] = i;
        processed.push(i);
        addConnections(x, y);
    }

    /*
     * Recursively add connections of map at given coordinates to grid
     *
     * @param {Number} x
     * @param {Number} y
     */
    function addConnections(x, y) {
        var i = locations[x + ',' + y],
            map = cluster[i],
            offset, n;

        for (let dir of Object.keys(DIRECTIONS)) {
            if (map.connections[dir] === null) { continue; }

            n = getIndexById(map.connections[dir]);

            if (processed.indexOf(n) !== -1) { continue; }

            offset = DIRECTIONS[dir];
            addToGridRecursive(n, x + offset[0], y + offset[1]);
        }
    }

    /*
     * Get map index from given temporary map id
     *
     * @param {Number} id
     * @return {Number}
     */
    function getIndexById(id) {
        for (let i = 0; i < cluster.length; i++) {
            if (cluster[i]._id === id) { return i; }
        }

        return null;
    }

    /*
     * Parse coordinates from x, y key string
     *
     * @param {Number} key
     * @return {[Number]}
     */
    function parseCoordinates(key) {
        return key.split(',').map(function (str) {
            return parseInt(str, 10);
        });
    }
};