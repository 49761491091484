/*
 * Tile utility module
 *
 * A small module containing utilities to work with tiles
 */

/*
 * Return true if tile A equals tile B
 *
 * @param {Array} a
 * @param {Array} b
 * @return {Boolean}
 */
function equal(a, b) {
    return a === b || (!a && !b) || a && b && a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
}

/*
 * Get unique key to identify given tile type (E.g. '0:0:mySet')
 *
 * @param {Array} tile
 * @return {String}
 */
function getUniqueKey(tile) {
    return tile[0] + ':' + tile[1] + ':' + tile[2];
}

/*
 * Parse tile in usual Array format from unique String key
 *
 * @param {String} key
 * @return {Array}
 */
function parse(key) {
    var parts = key.split(':');

    return [ parseInt(parts[0], 10), parseInt(parts[1], 10), parts[2] ];
}

/*
 * Clone tile
 *
 * @param {Array|null=} tile
 * @return {Array|null}
 */
function copy(tile = null) {
    return tile instanceof Array ? [ tile[0], tile[1], tile[2] ] : tile;
}

export default { equal, getUniqueKey, parse, copy };