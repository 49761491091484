import Vue from 'vue';
import EventEmitter from 'tiny-emitter';
import kanoWorld from './core/kano-world';
import router from './core/router';
import domUtil from './util/dom';
import modal from './ui/modal';
import Q from 'q';

/*
 * App VM
 *
 * A centralised app VM that holds a centralised, stateful scope for some of
 * the app's data
 */

var LOADING_CLASSNAME = 'loading',
    app = new EventEmitter();

/*
 * Initialise app's VM
 */
app.init = () => {
    app.vm = new Vue({
        el      : document.body,
        data    : {
            title      : null,
            viewId     : null,
            user       : null,
            ready      : false,
            route      : null,
            headerOpen : false
        },
        methods : { login, logout, setSession },
        created
    });
};

/*
 * Initialise view
 */
function created() {
    domUtil.removeClass(document.body, LOADING_CLASSNAME);

     // Change document title on scope title change
    this.$watch('title', (val) => {
        document.title = val;
    });

    // Attach user to view on SDK initialisation
    kanoWorld.on('initialise', (user) => {
        this.user = user;
        this.ready = true;

        if (user) {
            app.emit('login', user);
        }
    });

    // Attach route to view on route change
    router.on('change', (route) => {
        this.route = route;
    });
}

/*
 * Set current session
 *
 * @param {Object} session
 * @param {Function=} callback
 */
function setSession(session, callback = null) {
    kanoWorld.sdk.auth.setToken(session.token);
    this.user = session.user;
    kanoWorld.sdk.auth.initSession(callback);

    if (session.user) {
        app.emit('login', session.user);
    }
}

/*
 * Login Kano World account
 */
function login() {
    var deferred = Q.defer();

    modal.open('join', { tab: 'signin' })
    .then((session) => {
        if (!session) { return deferred.reject(); }

        this.setSession(session, deferred.resolve);
    })
    .catch(deferred.reject);

    return deferred.promise;
}

/*
 * Login Kano World account
 */
function logout() {
    kanoWorld.sdk.auth.logout();
}

module.exports = app;