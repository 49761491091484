import modal from '../ui/modal';
import router from '../core/router';
import tracking from '../core/tracking';

/*
 * Splash controller
 *
 * Splash page view controller
 */

export var methods = { play, feedbackModal };

/*
 * Initialise controller
 */
export function run() {
    // Do nothing..
}

/*
 * Start game on given slot
 */
function play() {
    modal.open('save-slots', { levelId: 'kano-adventure' }, (slot) => {
        if (!slot) { return; }
        router.goTo(`/play/kano-adventure/${slot.id}`);
    });

    tracking.track('splash-opened');
}

/*
 * Open feedback modal
 */
function feedbackModal() {
    modal.open('feedback', { title: 'Say Hi', copy: null });
}