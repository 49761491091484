var Movable = require('./Movable');

/*
 * BaseInstance class
 *
 * Base class for all types of instances
 */

export default class BaseInstance extends Movable {

    /*
     * Instance Constructor
     *
     * @param {Object=} options
     */
    constructor(options = {}) {
        super(options);
        this.game = options.game;
        this.x = options.x || 0;
        this.y = options.y || 0;
        this.type = options.type;
        this.id = options.id;
        this.hooks = options.hooks;
        this.initialized = false;
    }

    /*
     * Perform hook in provided GameApi
     *
     * @param {GameApi} api
     */
    perform(hook, api) {
        this.level = this.game.level || null;
        this.map = this.game.level.map || null;

        if (this.hooks[hook]) {
            api.execute(this.hooks[hook], true, this);
        }
    }

    /*
     * Export current instance's properties
     *
     * @return {Object}
     */
    export() {
        var out = {},
            key;

        for (key in this) {
            if (
                this.hasOwnProperty(key) &&
                key !== 'game' &&
                key !== 'level' &&
                key !== 'map'
                ) {
                out[key] = this[key];
            }
        }

        return out;
    }

}