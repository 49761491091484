import Vue from 'vue';
import hljs from 'highlight.js';
import keycode from 'keycode';

/*
 * Code Editor component
 *
 * Code editor UI component with syntax highlighting
 */

var configured = false,
    INDENT_REGEX = /^\s*/,
    INDENT_SPACES = 4,
    HISTORY_STORAGE = 20,
    INDENT_STR = '    ',
    CONFIG = {
        classPrefix : 'token-',
        languages   : [ 'javascript' ]
    };

Vue.component('code-editor', {
    data            : () => {
        return {
            code        : '',
            highlighted : null,
            lines       : [],
            title       : null,
            selection   : null
        };
    },
    template        : require('../../views/component/code-editor.jade'),
    isFn            : true,
    replace         : true,
    ready           : ready,
    props           : [
        'code',
        'title'
    ],
    methods         : {
        update,
        bind,
        onScroll,
        onKeydown,
        onSelectionChange,
        updateGutter,
        indent,
        getSelection,
        getCharLocation,
        getLineIndentation,
        getLineContent,
        onChange,
        undo,
        redo
    }
});

/*
 * Initialise component
 */
function ready() {
    this.textareaEl = this.$el.querySelector('[ref="textarea"]');
    this.highlightEl = this.$el.querySelector('[rel="highlight"]');
    this.displayEl = this.$el.querySelector('[rel="display"]');
    this.history = [ this.code ];
    this.historyCursor = 0;

    if (!configured) {
        hljs.configure(CONFIG);
        configured = true;
    }

    this.$watch('code', this.onChange);
    this.$watch('code', this.update);
    this.update();
    this.bind();
}

/*
 * Bind DOM events
 */
function bind() {
    this.textareaEl.addEventListener('scroll', this.onScroll.bind(this));
    this.textareaEl.addEventListener('keyup', this.onScroll.bind(this));
    this.textareaEl.addEventListener('keydown', this.onKeydown.bind(this));
    this.textareaEl.addEventListener('selectionchange', this.onSelectionChange.bind(this));
    this.textareaEl.addEventListener('mouseup', this.onSelectionChange.bind(this));
    this.textareaEl.addEventListener('keyup', this.onSelectionChange.bind(this));

    this.textareaEl.addEventListener('keydown', () => {
        // Next tick..
        setTimeout(() => {
            this.onSelectionChange();
        });
    });
}

/*
 * Handle code change
 *
 * @param {String} newVal
 * @param {String} oldVal
 */
function onChange(newVal, oldVal) {
    if (this.artifactChange) {
        this.artifactChange = false;
        return;
    }

    console.log('alter');

    this.history.push(oldVal);
    this.historyCursor = this.history.length - 1;
}

function undo() {
    console.log('undo');
    this.code = this.history[this.historyCursor--];
    this.artifactChange = true;
}

function redo() {
    console.log('redo');
    this.code = this.history[this.historyCursor++];
    this.artifactChange = true;
}

/*
 * Handle key press
 *
 * @param {KeyDownEvent} e
 */
function onKeydown(e) {
    var key = keycode(e.keyCode);

    if (key === 'tab') {
        e.preventDefault();
        this.indent();
    } else if (key === 'z' && (e.ctrlKey || e.metaKey) && e.shiftKey) {
        e.preventDefault();
        this.redo();
    } else if (key === 'z' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.undo();
    }
}

/*
 * Handle selection change
 */
function onSelectionChange() {
    this.selection = this.getSelection();
}

/*
 * Update display scroll position on textarea scroll
 */
function onScroll() {
    this.displayEl.scrollLeft = this.textareaEl.scrollLeft;
    this.displayEl.scrollTop = this.textareaEl.scrollTop;
}

/*
 * Update syntax highlighting
 */
function update() {
    this.updateGutter();
    hljs.highlightBlock(this.highlightEl);
    this.onSelectionChange();
}

/*
 * Indent selected line
 */
function indent() {
    this.code = this.code.split('\n').map((line, i) => {
        if (
            (line.length || this.selection.lines.length === 1) &&
            this.selection.lines.indexOf(i + 1) !== -1
            ) {
            return INDENT_STR + line;
        }

        return line;
    }).join('\n');
}

/*
 * Get rich Object containing data about current selection
 *
 * @return {Object}
 */
function getSelection() {
    var startChar = this.textareaEl.selectionStart,
        endChar = this.textareaEl.selectionEnd,
        content = this.textareaEl.value.substr(startChar, end),
        startLoc = this.getCharLocation(startChar),
        endLoc = this.getCharLocation(endChar),
        start = {
            line : startLoc.line,
            col  : startLoc.col,
            char : startChar
        },
        end = {
            line : endLoc.line,
            col  : endLoc.col,
            char : endChar
        },
        lines = [],
        i;

    for (i = start.line; i < end.line + 1; i++) {
        lines.push(i);
    }

    return { start, end, content, lines };
}

/*
 * Get line and column location of character by its index
 *
 * @param {Number} char
 * @return {Object}
 */
function getCharLocation(char) {
    var lines = this.code.substr(0, char).split('\n'),
        previousLineTotal = 0;

    lines.pop();

    lines.forEach((line) => {
        previousLineTotal += line.length + 1;
    });

    return {
        line : lines.length + 1,
        col  : char - previousLineTotal,
        char
    };
}

/*
 * Get indentation from a line number
 *
 * @param {Number} l
 * @return {Number}
 */
function getLineIndentation(l) {
    var line = this.getLineContent(l),
        spaces = line.match(INDENT_REGEX)[0].length;

    return Math.floor(spaces / INDENT_SPACES);
}

/*
 * Get content of given line
 *
 * @param {Number} l
 * @return {String}
 */
function getLineContent(l) {
    return this.code.split('\n')[l - 1];
}

/*
 * Form gutter context data
 */
function updateGutter() {
    var linesCount = (this.code || '').split('\n').length,
        lines = [],
        i;

    for (i = 0; i < linesCount; i++) {
        lines.push(i + 1);
    }

    this.lines = lines;
}