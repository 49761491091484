import Vue from 'vue';
import manipulation from '../util/manipulation';
import modal from '../ui/modal';
import session from '../editor/session';

/*
 * Behaviours Editor component
 *
 * Interface to edit behaviours inside level editor
 */

Vue.component('level-editor-behaviours', {
    data            : () => {
        return {
            mode       : 'draw',
            selected   : null,
            behaviours : {},
            editing    : null,
            editKey    : null,
            assisted   : null
        };
    },
    template        : require('../../views/component/level-editor-behaviours.jade'),
    isFn            : true,
    replace         : true,
    ready           : init,
    props           : [ 'behaviours', 'assisted' ],
    methods         : {
        reset,
        draw,
        clear,
        remove,
        select,
        add
    }
});

/*
 * Initialise component
 */
function init() {
    this.editor = session.getEditor();
    this.editor.behaviours = this;

    // Handle backspace deletion if a mode is selected
    this.editor.events.on('keydown-backspace', (e) => {
        if (
            !this.editor.previewing &&
            this.editor.mode === 'behaviours' &&
            this.mode === 'draw' && this.selected
            ) {
            e.preventDefault();

            this.remove(this.selected);
        }
    });

    // Maintain selection on mode change
    this.$watch('mode', () => {
        let selected = this.selected;
        this.reset();
        this.select(selected);
    });
}

/*
 * Reset state
 */
function reset() {
    this.selected = null;
    this.editor.selection.clear();
    this.editing = null;
    this.editKey = null;
}

/*
 * Place selected behaviour to current map at given position
 *
 * @param {Number} x
 * @param {Number} y
 * @param {Object} template
 */
function draw(x, y) {
    var map = this.editor.map;

    if (this.assisted && !this.assisted.isInArea(x, y)) { return; }

    this.clear({ x: x, y: y });

    if (!this.selected || this.selected === '__clear__') { return; }

    map.behaviours.push({ x, y, id: this.selected });
    map.emit('change', 'behaviours', x, y);
}

/*
 * Remove given entity from current map
 *
 * @param {Object} filter
 */
function clear(filter) {
    manipulation.removeByFilter(this.editor.map, 'items', filter);
    manipulation.removeByFilter(this.editor.map, 'behaviours', filter);
}

/*
 * Select given behaviour
 *
 * @param {String} behaviourId
 */
function select(behaviourId) {
    if (this.assisted && !this.assisted.tileIsAllowed(`${behaviourId}:behaviours`)) { return; }

    this.reset();
    this.selected = behaviourId;

    if (!this.selected) {
        return this.editor.selection.clear();
    }

    if (this.mode === 'draw') {

        this.editor.selection.set({
            behaviours : [ behaviourId ],
            width      : 1,
            height     : 1
        });

    } else if (this.mode === 'edit') {

        this.editing = this.behaviours[this.selected];
        this.editKey = 'initialize';

    }
}

/*
 * Prompt creation of a new behaviour
 */
function add() {
    modal.open('prompt', {
        question : 'Please choose a name'
    }, (name) => {
        if (!name) {
            return modal.open('alert', { text: 'Needs a name!' });
        }

        if (this.behaviours[name]) {
            return modal.open('alert', { text: 'Name already in use' });
        }

        this.behaviours.$set(name, {});
        this.mode = 'edit';
        this.select(name);
    });
}

/*
 * Delete behaviour type
 *
 * @param {String} name
 */
function remove(name) {
    modal.open('confirm', { text: 'Delete ' + this.selected +'?' }, (confirmed) => {
        if (!confirmed) { return; }

        this.behaviours.$delete(name);

        this.clear({ id: name });

        if (this.selected === name) {
            this.select();
        }
    });
}