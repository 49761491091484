import async from 'async';
import config from '../config';
import assets from '../core/assets';
import tileUtil from '../util/tile';

var renderIdIncr = 0,
    rendererWorker = new Worker('/js/worker/map-render.js');

/*
 * MapRenderer class
 *
 * Instance that take care of rendering Map's layers while keeping previous
 * renders cached
 *
 * Also contains mapping of adaptive tilesets and rendering logic for each
 * type of tile
 */

export default class MapRenderer {

    /*
     * MapRenderer constructor
     *
     * @param {Map} map
     */
    constructor(map, options = {}) {
        this.simplify = options.simplify || false;
        this.map = map;
        this.cache = [];
        this.worker = rendererWorker;
        this.rendering = false;
        this.cue = [];

        if (options.size) {
            this.setSize(options.size);
        }
    }

    /*
     * Set renderer size
     *
     * Will remove cached renders since on a different resolution
     *
     * @param {Number} size
     */
    setSize(size) {
        if (size !== this.size) {
            this.cache = [];
        }

        this.size = size;
    }

    render(callback) {
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');

        canvas.width = ctx.width = canvas.height = ctx.height = this.size;

        async.map([ 0, 1, 2, 3 ], (index, callback) => {
            this.renderLayer(index, callback);
        }, (err, layers) => {
            var layer;

            if (err) { return callback(err); }

            for (layer of layers) {
                ctx.drawImage(layer.canvas, 0, 0, this.size, this.size);
            }

            callback(null, { ctx, canvas });
        });
    }

    /*
     * Render layer of given index
     *
     * @param {Number} index
     * @param {Number} size
     * @param {Function} callback
     */
    renderLayer(layerIndex, callback) {
        this.renderUpdatedArea(layerIndex, 0, 0, config.MAP_SIZE[0], config.MAP_SIZE[1], callback);
    }

    /*
     * Render a rectangulat area of a given layer specified in starting
     * X and Y grid position and width and height expressed in tiles
     *
     * @param {Number} layerIndex
     * @param {Number} startX
     * @param {Number} startY
     * @param {Number} width
     * @param {Number} height
     * @param {Function} callback
     */
    renderUpdatedArea(layerIndex, startX, startY, width, height, callback) {
        // Cue render task if already rendering
        if (this.rendering) {
            return this.cue.push(() => {
                this.renderUpdatedArea(layerIndex, startX, startY, width, height, callback);
            });
        }

        this.rendering = true;

        var renderer = this,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d'),       // Context to render within
            size = this.size,
            tileSize = [
                (size * config.TILE_SIZE_ORIGINAL[0]) / (config.MAP_SIZE[0] * config.TILE_SIZE_ORIGINAL[0]),
                (size * config.TILE_SIZE_ORIGINAL[1]) / (config.MAP_SIZE[1] * config.TILE_SIZE_ORIGINAL[1])
            ],
            session = {
                startX, startY, width, height, size,                // Canvas and rendering variables
                renderId           : renderIdIncr++,                // Render id for response matching
                mapSize            : config.MAP_SIZE,               // Configured, fixed map size
                tileSize           : tileSize,                      // Current editor display tile size in px
                simplify           : this.simplify,                 // Simplified render
                chunkSize          : [
                    tileSize[0] / 3,                                // Individual chunk width
                    tileSize[1] / 3                                 // Individual chunk height
                ],
                originalChunkSize  : [
                    config.TILE_SIZE_ORIGINAL[0] / 3,               // Width of chunk in the original asset
                    config.TILE_SIZE_ORIGINAL[1] / 3                // Height of chunk in the original asset
                ],
                adaptiveTilesTypes : [],                            // Storage for adaptive tile types encountered
                layer              : this.map.layers[layerIndex],   // Layer data from map
                layerIndex         : layerIndex,                    // Layer index (For cache references)
            };

        // Setup canvas and context size
        canvas.width = ctx.width = canvas.height = ctx.height = size;

        // Disable smoothing
        ctx.imageSmoothingEnabled    = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.msImageSmoothingEnabled  = false;
        ctx.oImageSmoothingEnabled   = false;

        // If cache for the layer is available, render it and clear the square to re-render in current loop
        if (this.cache[session.layerIndex]) {
            ctx.drawImage(this.cache[session.layerIndex].canvas, 0, 0, session.mapSize[0] * session.tileSize[0], session.mapSize[1] * session.tileSize[1]);
            ctx.clearRect(session.startX * session.tileSize[0], session.startY * session.tileSize[1], session.width * session.tileSize[0], session.height * session.tileSize[1]);
        }

        // Prepare to receive rendering steps from worker
        renderer.worker.addEventListener('message', (e) => {
            var response = e.data;

            if (response.renderId === session.renderId) {
                this.renderSteps(response.steps, canvas, ctx, session, (err) => {
                    callback(err, { ctx, canvas });
                    this.rendering = false;
                    this.nextInCue();
                });
            }
        });

        setTimeout(function () {
            // Request computing of rendering steps from worker
            renderer.worker.postMessage(session);
        });
    }

    /*
     * Render cued change
     */
    nextInCue() {
        if (!this.cue.length) { return; }
        return this.cue.splice(0, 1)[0]();
    }

    /*
     * Load assets and perform rendering steps calculated by web worker
     *
     * @param {[Object]} steps
     * @param {CanvasElement} canvas
     * @param {2dRenderingContext} ctx
     * @param {Object} session
     * @param {Function} callback
     */
    renderSteps(steps, canvas, ctx, session, callback) {
        async.map(steps, (step, callback) => {
            var tile = tileUtil.parse(step.asset);

            assets.loadTile(tile, (asset) => {
                if (this.simplify) {
                    ctx.fillStyle = asset.color;
                    ctx.fillRect(step.x, step.y, Math.round(step.width), Math.round(step.height));
                } else if (step.crop) {
                    ctx.drawImage(asset.canvas, step.x, step.y, step.width, step.height, step.dx, step.dy, step.dwidth, step.dheight);
                } else {
                    ctx.drawImage(asset.canvas, step.x, step.y, step.width, step.height);
                }

                callback();
            });
        }, () => {
            this.cache[session.layerIndex] = { canvas, ctx: ctx };
            callback(null, this.cache[session.layerIndex]);
        });
    }

}