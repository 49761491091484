/*
 * Basics module
 *
 * Basic functions - observe, look, use, etc..
 */

/*
 * Describe current map if describe script is available
 */
exports.observe = function (silent = false) {
    if (this.map.scripts.describe) {
        return this.execute(this.map.scripts.describe);
    }

    if (!silent) {
        this.terminal.log('No description avaiable for this area');
    }
};

/*
 * Look at tile ahead
 */
exports.look = function () {
    let pos = this.player.getFacingPosition(),
        instance = this.map.getInstance(pos);

    if (!instance || !instance.hooks.look) {
        return this.terminal.log('{person}You{/person} {action}see{/action} nothing special');
    }

    instance.perform('look', this);
};

/*
 * Describe tile ahead
 */
exports.describe = function () {
    let pos = this.player.getFacingPosition(),
        instance = this.map.getInstance(pos);

    if (!instance || !instance.hooks.describe) {
        return this.terminal.log('{person}You{/person} {action}notice{/action} nothing special');
    }

    instance.perform('describe', this);
};

/*
 * Trigger talk hook on item ahead
 */
exports.talk = function () {
    let pos = this.player.getFacingPosition(),
        instance = this.map.getInstance(pos);

    if (!instance || !instance.hooks.talk) {
        return this.terminal.log('No one to {action}talk{/action} to here');
    }

    instance.perform('talk', this);
};

/*
 * Use tile ahead
 */
exports.use = function () {
    let pos = this.player.getFacingPosition(),
        instance = this.map.getInstance(pos);

    if (!instance || !instance.hooks.use) {
        return this.terminal.log('Nothing to do here..');
    }

    instance.perform('use', this);
};