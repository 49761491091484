/*
 * Learn Alert modal
 *
 * Modal to advise going to challenges
 */

var data = {
        preventAlert : false
    };

/*
 * Initialise modal
 */
function run() {
    this.preventAlert = localStorage.prevent_learn_alert === 'true';

    this.$watch('preventAlert', () => {
        localStorage.prevent_learn_alert = this.preventAlert;
    });
}

export default { data, run };