/*
 * Confirm modal
 *
 * Modal to confirm a procedure
 */

var data = {
        title       : null,
        text        : null,
        cancelText  : null,
        confirmText : null,
        colors      : true
    };

/*
 * Initialise modal
 */
function run() {
    let button = this.$el.querySelector('button.success');

    if (button && button.focus) { button.focus(); }
}

export default { data, run };