/*
 * Object utility module
 *
 * Small utility module exporting methods to work with objects and namespaces
 */

/*
 * Returns true if given Object is empty
 *
 * @param {Object} obj
 * @return PBoolean
 */
exports.isEmpty = function (obj) {
    return !obj || !Object.keys(obj).length;
};

/*
 * Set key at given namespace in an Object
 *
 * @param {Object} root
 * @param {String} ns
 * @param {*} val
 */
exports.setKey = function (root, ns, val) {
    var segments = ns.split('.'),
        cur = root,
        key = segments.pop();

    segments.forEach(function (segment) {
        if (!cur[segment]) {
            cur[segment] = {};
        }
        cur = cur[segment];
    });

    cur[key] = val;
};

/*
 * Get key at given namespace in an Object - return fallback value if set and
 * a value wasn't found
 *
 * @param {Object} root
 * @param {String} ns
 * @param {*=} fallback
 * @return {*}
 */
exports.getKey = function (obj, ns, fallback = undefined) {
    obj = obj || {};

    var cur = obj,
        parts = ns.split('.'),
        i;

    for (i = 0; i < parts.length; i += 1) {
        if (!cur[parts[i]]) {
            return fallback === undefined ? null : fallback;
        }

        cur = cur[parts[i]];
    }

    return cur;
};

/*
 * Non-deep extend of multiple Objects
 *
 * @param {..Object..}
 */
exports.extend = function () {
    var out = {},
        i, obj, key;

    for (i = 0; i < arguments.length; i++) {
        obj = arguments[i];

        for (key in obj) {
            if (obj.hasOwnProperty(key)) {
                out[key] = obj[key];
            }
        }
    }

    return out;
};