import Vue from 'vue';
import EventEmitter from 'tiny-emitter';
import config from '../config';
import keycode from 'keycode';
import Map from '../class/Map';
import generate from '../util/generate';
import modal from '../ui/modal';
import tilesets from '../editor/tilesets';
import session from '../editor/session';
import entities from '../editor/entities';
import AssistedDrawing from '../class/AssistedDrawing';
import authUtil from '../util/auth';

const LAYERS = [
        { label: 'Layer 0 - Below character', type: 'tiles' },
        { label: 'Layer 1 - Below character', type: 'tiles' },
        { label: 'Layer 2 - Above character', type: 'tiles' },
        { label: 'Layer 3 - Above character', type: 'tiles' },
        { label: 'Items', type: 'items' }
    ];

/*
 * Level Editor component
 *
 * Display tile image from tileset
 */

Vue.component('level-editor', {
    data            : () => {
        return {
            level          : null,
            map            : null,
            mode           : 'draw',
            mouse          : { x: 0, y: 0 },
            events         : new EventEmitter(),
            layer          : 0,
            layerType      : 'tiles',
            preview        : null,
            previewing     : false,
            exploded       : false,
            solo           : null,
            layers         : LAYERS,
            hidden         : [],
            tileset        : config.DEFAULT_TILESET,
            tilesetOptions : tilesets.getOptions(),
            activeItem     : null,
            activeEntity   : null,
            entities       : entities,
            hide           : {},
            anchors        : false,
            assisted       : null,
            layersLocked   : false,
            tool           : 'pencil'
        };
    },
    template        : require('../../views/component/level-editor.jade'),
    isFn            : true,
    replace         : true,
    created         : init,
    props           : [ 'level', 'hide', 'anchors' ],
    ready           : ready,
    beforeDestroy   : unbind,
    methods         : {
        getTileSize,
        getMapElement,
        togglePreview,
        changeMode,
        changeLayer,
        layerIsVisible,
        toggleLayer,
        toggleSolo,
        getHiddenLayers,
        selectItem,
        selectEntity,
        clearSelection,
        addMap,
        changeMap,
        assistedDrawing,
        save: authUtil.required(save)
    }
});

/*
 * Initialise component
 */
function init() {
    session.setEditor(this);
}

/*
 * Run component
 */
function ready() {
    this.map = this.level.maps[this.level.lastMapOpened];

    this.mousemove = mousemove.bind(this);
    this.keydown = keydown.bind(this);

    window.addEventListener('mousemove', this.mousemove);
    window.addEventListener('keydown', this.keydown);

    this.$watch('mode', this.changeMode);
    this.$watch('layer', this.changeLayer);
    this.$watch('map', this.changeMap);

    this.events.on('selectionclear', this.clearSelection.bind(this));
}

/*
 * Clear selection
 */
function clearSelection() {
    this.selection.clear();
    this.activeEntity = null;
    this.activeItem = null;
}

/*
 * Unbind generic DOM events
 */
function unbind() {
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('keydown', this.keydown);
}

/*
 * Store last map opened index in level when changing map
 */
function changeMap() {
    this.level.lastMapOpened = this.level.maps.indexOf(this.map);
}

/*
 * Change editor mode
 *
 * @param {String} mode
 * @param {String} previousMode
 */
function changeMode(mode, previousMode) {
    this.clearSelection();

    if (previousMode === 'draw' && this.map) {
        this.map.generatePreview();
    }
}

/*
 * Triggered on layer change - update `layerType` accordingly
 *
 * @param {Number} l
 */
function changeLayer(l) {
    let layerType = LAYERS[l].type;

    if (this.layerType !== layerType) {
        this.clearSelection();
    }

    this.layerType = layerType;
}

/*
 * Select item by id
 *
 * @param {String} id
 */
function selectItem(id) {
    this.activeItem = id;
    this.selection.set({
        items  : [ this.level.items[id] || id ],
        width  : 1,
        height : 1
    });
}

/*
 * Select given entity
 *
 * @param {Object} entity
 */
function selectEntity(entity) {
    let index = entities.indexOf(entity),
        type = typeof entity === 'string' ? entity : entity.type,
        key = `${type}:logic`,
        tile;

    if (this.assisted && !this.assisted.tileIsAllowed(key)) { return; }

    if (entity === '__clear__') {
        tile = '__clear__';
    } else {
        tile = [ index + 1, 0, 'logic' ];
    }

    this.activeEntity = entity;

    this.selection.set({
        tiles  : [ tile ],
        width  : 1,
        height : 1
    });
}

/*
 * Initialise assisted drawing with given options
 *
 * @param {[String]} area
 * @param {Object} tiles
 * @param {Number} layer
 * @return {AssistedDrawing}
 */
function assistedDrawing(area, tiles, layer) {
    this.assisted = new AssistedDrawing(this, area, tiles, layer);

    this.assisted.then(() => {
        this.assisted = null;
    });

    return this.assisted;
}

/*
 * Toggle preview state, initialise preview level
 *
 * @param {Function=} callback
 */
function togglePreview(callback = null) {
    this.previewing = !this.previewing;

    if (this.previewing) {
        let preview = this.level.copy();

        this.preview = preview;
    } else {
        this.preview = null;
    }

    // Wait 2 ticks..
    setTimeout(() => {
        setTimeout(() => {
            if (typeof callback === 'function') { callback(); }
        });
    });
}

/*
 * Triggered on mouse move
 *
 * @param {MouseEvent} e
 */
function mousemove(e) {
    this.mouse.x = e.pageX;
    this.mouse.y = e.pageY;
    this.events.emit('mousemove', this.mouse);
}

/*
 * Triggered on key down
 *
 * @param {MouseEvent} e
 */
function keydown(e) {
    let key = keycode(e.keyCode);

    this.events.emit('keydown', e);
    this.events.emit('keydown-' + key, e);
}

/*
 * Get map DOM element from map viewer
 *
 * @return {DOMElement}
 */
function getMapElement() {
    return this.viewer ? this.viewer.$el || null : null;
}

/*
 * Get current tiles size
 *
 * @return {Number}
 */
function getTileSize() {
    return config.TILE_SIZE;
}

/*
 * Save current level
 */
function save() {
    return modal.open('level-save', { level : this.level })
    .then((success) => {
        this.events.emit('save', success);
    });
}

/*
 * Add map
 *
 * @param {Boolean=} select
 * @return {Map}
 */
function addMap(select = true, callback = null) {
    modal.open('prompt', { question: 'Map name' }, (name) => {

        if (!name) {
            return modal.open('alert', { text: 'Needs a name!' });
        }

        let map = new Map(this.level, generate.map());

        map.generatePreview();
        map.name = name;

        this.level.maps.push(map);

        if (select) { this.map = map; }

        if (callback) { callback(map); }

        this.events.emit('addmap');

        return map;
    });
}

/*
 * Returns true if layer of given index is visible
 *
 * @param {Number} index
 * @return {Boolean}
 */
function layerIsVisible(index) {
    return this.hidden.indexOf(index) === -1 && !(this.solo !== null && this.solo !== index);
}

/*
 * Toggle layer visibility by index
 *
 * @param {Number} index
 */
function toggleLayer(index) {
    let i = this.hidden.indexOf(index);

    if (this.layersLocked) { return; }

    if (i !== -1) {
        this.hidden.splice(i, 1);
    } else {
        this.hidden.push(index);
    }
}

/*
 * Toggle solo visibility on layer of given index
 *
 * @param {Number} index
 * @return {[Number]}
 */
function toggleSolo(index) {
    if (this.layersLocked) { return; }

    this.solo = this.solo === index ? null : index;
}

/*
 * Get an array of hidden layers indexes
 *
 * @return {[Number]}
 */
function getHiddenLayers() {
    let out = [],
        i;

    for (i = 0; i < this.layers.length; i++) {
        if (!this.layerIsVisible(i)) {
            out.push(i);
        }
    }

    return out;
}