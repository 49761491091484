/*
 * Challenges index
 *
 * Exports all challenges and getter methods
 */

var challenges = [
    require('./drawing'),
    require('./layers'),
    require('./logic'),
    require('./world'),
    require('./behaviours')
];

/*
 * Get challenge by slug
 *
 * @param {String} slug
 * @return {Object|void}
 */
challenges.getBySlug = function (slug) {
    var challenge;

    for (challenge of challenges) {
        if (challenge.slug === slug) {
            return challenge;
        }
    }

    return null;
};

/*
 * Get challenge coming after given one in the list
 *
 * @param {Object} current
 * @return {Object|void}
 */
challenges.getNext = function (current) {
    var i;

    for (i = 0; i < challenges.length; i++) {
        if (challenges[i] === current) {
            return challenges[i + 1] || null;
        }
    }

    return null;
};

export default challenges;