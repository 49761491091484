import challenges from '../challenges/index';
import error from '../core/error';
import app from '../app';
import unlockedChallenges from '../core/unlocked-challenges';

/*
 * Challenges controller
 *
 * Challenges list view controller
 */

export var scope = {
    loading  : true,
    unlocked : 0,
    challenges
};

export var methods = {
    updateUnlocked
};

/*
 * Initialise controller
 */
export function run() {
    this.updateUnlocked();
    unlockedChallenges.on('change', this.updateUnlocked);
}

/*
 * Unbind from login before view is destroyed
 */
export function destroy() {
    app.off('login', this.updateUnlocked);
}

/*
 * Load last unlocked challenge from server (Or localStorage if logged out)
 */
function updateUnlocked() {
    unlockedChallenges.get().then((unlocked) => {
        this.unlocked = unlocked;
        this.loading = false;
    }, error.handle)
    .catch(error.handle);
}