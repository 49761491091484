import Vue from 'vue';
import clone from 'deepcopy';
import manipulation from '../util/manipulation';
import modal from '../ui/modal';
import session from '../editor/session';

/*
 * Items Editor component
 *
 * Interface to edit items
 */

Vue.component('level-editor-items', {
    data            : () => {
        return {
            items        : {},
            behaviours   : [],
            mode         : 'edit-item',
            editing      : null,
            state        : null,
            editKey      : null,
            attributeKey : null,
            selected     : null
        };
    },
    template        : require('../../views/component/level-editor-items.jade'),
    isFn            : true,
    replace         : true,
    props           : [ 'items', 'behaviours' ],
    created         : init,
    methods         : {
        reset,
        editBehaviour,
        editItem,
        deleteBehaviour,
        getBehaviourOptions,
        getAttributesStructure,
        addItem,
        deleteItem,
        addBehaviour,
        addState,
        removeState
    }
});

/*
 * Initialise component
 */
function init() {
    this.editor = session.getEditor();
    this.editor.items = this;

    // Handle reset on ESC key down
    this.editor.events.on('keydown-esc', () => {
        this.reset();
    });
}

/*
 * Reset state
 */
function reset() {
    this.editing = null;
    this.selected = null;
}

/*
 * Prompt creation of a new item
 */
function addItem() {
    modal.open('prompt', {
        question : 'Please choose an ID'
    }, (name) => {
        if (!name) { return; }

        if (this.items[name]) {
            return modal.open('alert', { text: 'ID already in use' });
        }

        this.items.$set(name, {
            name       : name,
            behaviour  : null,
            singleUse  : true,
            pickable   : true,
            states     : {
                default : { tile: [ 1, 0, 'items' ] }
            },
            attributes : {}
        });

        this.editItem(name);
    });
}

/*
 * Prompt creation of a new item
 */
function addBehaviour() {
    modal.open('prompt', {
        question : 'Please choose an ID'
    }, (name) => {
        if (!name) { return; }

        if (this.behaviours[name]) {
            return modal.open('alert', { text: 'ID already in use' });
        }

        this.behaviours.$set(name, {
            describe   : null,
            use        : null,
            attributes : {
            }
        });

        this.editBehaviour(name);
    });
}

/*
 * Delete item type
 *
 * @param {String} id
 */
function deleteItem(id) {
    modal.open('confirm', { text: 'Delete ' + this.selected +'?' }, (confirmed) => {
        if (!confirmed) { return; }

        this.items.$delete(id);

        manipulation.removeByFilter(this.editor.map, 'items', { id });

        if (this.selected === id) {
            this.selected = null;
            this.editing = null;
        }
    });
}

/*
 * Set editing behaviour by id
 *
 * @param {String} id
 */
function editBehaviour(id) {
    this.selected = id;
    this.mode = 'edit-behaviour';
    this.editKey = 'attributes';
    this.attributeKey = '';
    this.editing = this.behaviours[id];
}

/*
 * Set editing item by id
 *
 * @param {String} id
 */
function editItem(id) {
    this.selected = id;
    this.mode = 'edit-item';
    this.editing = this.items[id];
}

/*
 * Delete item behaviour by id
 *
 * @param {String} id
 */
function deleteBehaviour(id) {
    modal.open('confirm', { text: 'Delete ' + id +'?' }, (confirmed) => {
        if (!confirmed) { return; }

        for (let key in this.items) {
            if (this.items.hasOwnProperty(key)) {
                if (this.items[key].behaviour === id) {
                    this.items[key].behaviour = null;
                }
            }
        }

        this.behaviours.$delete(id);
    });
}

/*
 * Generate behaviour options ready to display in a select box
 *
 * @return {[Object]}
 */
function getBehaviourOptions() {
    var out = [ { text: ' -- None --', value: null } ];

    for (let key in this.behaviours) {
        if (this.behaviours.hasOwnProperty(key)) {
            out.push({ text: key, value: key });
        }
    }

    return out;
}

/*
 * Get attributes structure from item's behaviour
 *
 * @param {Object} item
 * @return {Object}
 */
function getAttributesStructure(item) {
    let behaviour = item.behaviour ? this.behaviours[item.behaviour] : null;
    return behaviour ? behaviour.attributes || null : null;
}

/*
 * Add a state to current item
 */
function addState() {
    modal.open('prompt', { question: 'Please choose a name' }, (name) => {
        if (!name) { return; }

        if (this.editing.states[name]) {
            return modal.open('alert', { text: 'State already exists' });
        }

        this.editing.states.$set(name, clone(this.editing.states.default));
    });
}

/*
 * Remove current item's state by id
 *
 * @param {String} id
 */
function removeState(id) {
    modal.open('confirm', { text: 'Are you sure?' }, (confirmed) => {
        if (!confirmed) { return; }

        this.editing.states.$delete(id);
    });
}