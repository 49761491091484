/*
 * Game API modules index
 *
 * Export all API modules in one object
 */

export default {
    security : require('./modules/security'),
    text     : require('./modules/text'),
    movement : require('./modules/movement'),
    tutorial : require('./modules/tutorial'),
    basics   : require('./modules/basics'),
    items    : require('./modules/items')
};