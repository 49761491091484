import Level from '../class/Level';
import generate from '../util/generate';
import api from '../core/api';
import error from '../core/error';
import tracking from '../core/tracking';
import router from '../core/router';
import modal from '../ui/modal';

/*
 * Editor controller
 *
 * Game editor view controller
 */

export var scope = {
    level : null,
    foo   : 'test'
};

export var methods = { newLevel, loadLevel };

/*
 * Initialise controller
 *
 * @param {Object} req
 */
export function run(req) {
    var levelId = req.namedParams.levelId,
        preventLearnAlert = localStorage.prevent_learn_alert === 'true';

    if (!preventLearnAlert) {
        modal.open('learn-alert');
    }

    if (!levelId) {
        this.newLevel();
    } else {
        this.loadLevel(levelId);
    }

    tracking.track('editor-opened');
}

/*
 * Create empty new level Object
 *
 * @return {Object}
 */
function newLevel() {
    this.level = generate.empty();
}

/*
 * Load level from server by slug
 *
 * @param {String} levelId
 */
function loadLevel(levelId) {
    api.levels.load({ id: levelId })
    .then((res) => {
        this.level = new Level(res.body.level);
    }, (res) => {
        if (res.status === 404) {
            return router.goTo('/404-not-found');
        }

        error.handle(new Error(res.body));
    })
    .catch(error.handle);
}