import Vue from 'vue';
import types from '../editor/types';
import objectUtil from '../util/object';

/*
 * Structure Editor component
 *
 * Display component to edit a key-type structure
 */

Vue.component('struct-editor', {
    data            : () => {
        return {
            item  : null,
            types : types,
            key   : ''
        };
    },
    template        : require('../../views/component/struct-editor.jade'),
    isFn            : true,
    replace         : true,
    props           : [
        'item'
    ],
    methods         : {
        addKey,
        isEmpty: objectUtil.isEmpty
    }
});

/*
 * Add empty key on object
 *
 * @param {SubmitEvent} e
 */
function addKey(e) {
    e.preventDefault();
    this.item.$set(this.key, types[0]);
    this.key = null;
}