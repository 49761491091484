import Service from 'api-service';
import kanoWorld from '../core/kano-world';
 
/*
 * API service
 *
 * Wrapper to API endpoints
 */

module.exports = new Service('/api')

// List levels
.add('levels.list', {
    method : 'get',
    route  : '/levels'
})

// Load level
.add('levels.load', {
    method : 'get',
    route  : '/levels/:id'
})

// Delete level
.add('levels.delete', {
    method : 'delete',
    route  : '/levels/:id'
})

// Share level
.add('levels.share', {
    method : 'post',
    route  : '/levels/:id/share'
})
 
// Save level
.add('levels.save', {
    method : 'put',
    route  : '/levels'
})
 
// Get shared level
.add('shares.get.byId', {
    method : 'get',
    route  : '/shared/:id'
})

// Send feedback
.add('feedback.send', {
    method : 'post',
    route  : '/feedback'
})

// Track event
.add('events.track', {
    method : 'post',
    route  : '/events'
})

// Solve maps packing
.add('packer.solve', {
    method : 'post',
    route  : '/packer/solve'
})

// Save game state
.add('gameSaves.save', {
    method : 'post',
    route  : '/saves/:levelId/:slot'
})

// Delete game state
.add('gameSaves.clear', {
    method : 'delete',
    route  : '/saves/:levelId/:slot'
})

// Get game state
.add('gameSaves.load', {
    method : 'get',
    route  : '/saves/:levelId/:slot'
})

// Save game state
.add('gameSaves.list', {
    method : 'get',
    route  : '/saves/:levelId'
})

/*
 * Auth middleware - When available, attach Kano World token to every request
 *
 * @param {Object} req
 * @param {Function} next
 */
.use((req, next) => {
    var token = kanoWorld.sdk.auth.getToken();

    if (token) {
        req.setRequestHeader('kw-token', token);
    }

    next();
});