import EventEmitter from 'tiny-emitter';
import Q from 'q';

/*
 * Movable class
 *
 * Base class for in-game event-emitting moveable instances
 */

const STEP_MS = 400,
    DIRECTIONS = [ 'up', 'right', 'down', 'left' ];

export default class Movable extends EventEmitter {

    /*
     * Player constructor
     *
     * @param {Object=} options
     */
    constructor(options = {}) {
        super();
        this.x = options.x || 0;
        this.y = options.y || 0;
        this._moveCue = [];
        this.moving = false;
    }

    /*
     * Move instance by given x and y coordinates
     *
     * @param {Number} x
     * @param {Number} y
     * @param {Function=} callback
     */
    move(x = 0, y = 0, callback = null) {
        var deferred = Q.defer();

        if (typeof x !== 'number' || typeof y !== 'number') {
            throw new Error('`.move` only accepts Number values');
        }

        var stepX = Math.abs(x) / x,
            stepY = Math.abs(y) / y,
            i;

        for (i = 0; i < Math.abs(x); i ++) {
            this._moveCue.push({ axis: 'x', offset: stepX });
        }

        for (i = 0; i < Math.abs(y); i ++) {
            this._moveCue.push({ axis: 'y', offset: stepY });
        }

        this._moveCue.push(() => {
            deferred.resolve();

            if (typeof callback === 'function') { callback(); }
        });

        if (!this.moving) {
            this.moving = true;
            this.step();
        }

        return deferred.promise;
    }

    /*
     * Clear moving cue
     */
    stop() {
        this._moveCue = [];
    }

    /*
     * Recursive step loop function to computer player movement in time
     */
    step() {
        let step = this._moveCue[0],
            change = { x: 0, y: 0 },
            newValue;

        if (!step || !this.moving) {
            this.moving = false;
            return;
        }

        this._moveCue.splice(0, 1);

        if (typeof step === 'function') {
            step.call(this);
        } else {
            newValue = Math.floor(this[step.axis] + step.offset);
            change[step.axis] = newValue - this[step.axis];
            this[step.axis] = newValue;

            if (step.axis === 'x' && step.offset > 0) {
                this.turn('right');
            } else if (step.axis === 'x' && step.offset < 0) {
                this.turn('left');
            } else if (step.axis === 'y' && step.offset > 0) {
                this.turn('down');
            } else if (step.axis === 'y' && step.offset < 0) {
                this.turn('up');
            }

            // Emit move event
            this.emit('move', { x: this.x, y: this.y }, change);
        }

        // Call next step
        setTimeout(this.step.bind(this), STEP_MS);
    }

    /*
     * Base turn function
     *
     * @param {String} dir
     */
    turn(dir) {
        if (DIRECTIONS.indexOf(dir) === -1) {
            throw new Error('.turn called with invalid direction: "' + dir + '"');
        }

        this.emit('turn', dir);
        this.direction = dir;
    }

    /*
     * Get instance's facing tile position
     *
     * @return {Object}
     */
    getFacingPosition() {
        var off = { x: 0, y: 0 },
            x = this.x,
            y = this.y;

        if (this.direction === 'up') {
            off = { x : 0, y: -1 };
        } else if (this.direction === 'right') {
            off = { x : 1, y: 0 };
        } else if (this.direction === 'down') {
            off = { x : 0, y: 1 };
        } else if (this.direction === 'left') {
            off = { x : -1, y: 0 };
        }

        x += off.x;
        y += off.y;

        return { x, y };
    }

}