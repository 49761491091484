import app from '../app';
import kanoWorld from '../core/kano-world';

function required(fn) {
    return function () {
        var args = arguments,
            self = this;

        if (kanoWorld.loggedIn()) {
            return proceed();
        }

        app.vm.login().then(proceed);

        /*
         * Execute wrapped function
         */
        function proceed() {
            fn.apply(self, args);
        }
    };
}

export default { required };