import domUtil from '../util/dom';
import Q from 'q';

/*
 * Require Click UI module
 *
 * Export methods to set a click requirement over a given selector before
 * accepting any other click events - works by applying a mouse trap element
 * in front of the page and performing manual hit detection with selector's
 * matched elements
 */

var wrap = document.body,
    selector, trap, deferred, mouseDownOnly;

/*
 * Create trap layer to catch and prevent default clicking behaviour
 */
function createTrap() {
    trap = document.createElement('div');
    trap.className = 'mouse-trap';
    wrap.appendChild(trap);
}

/*
 * Trap and cancel all clicks not matching given selector until promise is
 * resolved
 *
 * @param {String} sel
 * @return {Promise}
 * @param {Boolean} mdo
 */
function on(sel, mdo = false) {
    if (selector) { off(); }

    mouseDownOnly = mdo;

    selector = sel;
    deferred = Q.defer();

    createTrap();
    trap.addEventListener('mousedown', mousedown);
    trap.addEventListener('mousemove', mousemove);

    return deferred.promise;
}

/*
 * Change cursor if colliding on mousemove
 */
function mousemove(e) {
    if (!trap) { return; }
    trap.style.cursor = getCollidingTarget(e.pageX, e.pageY) ? 'pointer' : 'default';
}

/*
 * Catch and haneld trap mousedown - check collision with elements below trap
 * and transfer click event if matching
 *
 * @param {MouseDownEvent} e
 */
function mousedown(e) {
    var target = getCollidingTarget(e.pageX, e.pageY);

    if (target) {
        return proceed(target, e.pageX, e.pageY);
    }
}

/*
 * Get target hit on current position if found
 *
 * @param {Number} x
 * @param {Number} y
 * @return {HTMLElement|null}
 */
function getCollidingTarget(x, y) {
    var targets = document.querySelectorAll(selector),
        i, target, rect;

    for (i = 0; i < targets.length; i++) {
        target = targets[i];
        rect = target.getBoundingClientRect();

        if (
            x > rect.left && x < rect.left + rect.width &&
            y > rect.top && y < rect.top + rect.height
            ) {
            return target;
        }
    }

    return null;
}

/*
 * Resolve promise, remove trap and transfer click to appropriate element in
 * case target selector has been clicked
 *
 * @param {HTMLElement} target
 * @param {Number} x
 * @param {Number} y
 */
function proceed(target, x, y) {
    var def = deferred;

    deferred = null;
    selector = null;

    off();

    setTimeout(() => {
        domUtil.click(target, x, y, mouseDownOnly);
        def.resolve(target);
    }, 100);
}

/*
 * Reject existing promisesm clear out trap and reset state
 */
function off() {
    if (trap) {
        wrap.removeChild(trap);
        trap = null;
    }

    if (deferred) {
        deferred.reject();
    }

    deferred = null;
}

export default { on, off };