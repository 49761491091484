import api from '../core/api';
import error from '../core/error';
import Level from '../class/Level';
import tracking from '../core/tracking';
import router from '../core/router';

/*
 * Play controller
 *
 * Gampleay view controller
 */

export var scope = {
    level : null,
    slot  : null
};

export var methods = { loadLevel };

/*
 * Initialise controller
 *
 * @param {Object} req
 */
export function run(req) {
    var levelId = req.namedParams.levelId;

    this.slot = req.namedParams.slot || 0;

    this.loadLevel(levelId);

    tracking.track('game-started');
}

/*
 * Load level from server by ID
 *
 * @param {String} levelId
 */
function loadLevel(levelId) {
    api.levels.load({ id: levelId })
    .then((res) => {
        var level = new Level(res.body.level);

        level.generateInstances();

        this.level = level;
    }, function (res) {
        if (res.status === 404) {
            return router.goTo('/404-not-found');
        }

        error.handle(new Error(res.body));
    })
    .catch(error.handle);
}