import Vue from 'vue';
import numberUtil from '../util/number';
import modal from '../ui/modal';

/*
 * Pictures Showcase component
 *
 * Display pictures gallery from given path and length
 */

Vue.component('pictures-showcase', {
    data            : () => {
        return {
            baseUrl   : null,
            length    : 0,
            pictures  : [],
            extension : 'png',
            current   : null
        };
    },
    template        : require('../../views/component/pictures-showcase.jade'),
    isFn            : true,
    replace         : true,
    props           : [ 'baseUrl', 'length', 'extension' ],
    methods         : {
        generatePictures,
        select,
        zoom
    },
    ready
});

/*
 * Initialise component
 */
function ready() {
    this.$watch('basePath', this.generatePictures);
    this.$watch('length', this.generatePictures);
    this.$watch('extension', this.generatePictures);
    this.generatePictures();
}

/*
 * Generate an Object for each picture in range
 */
function generatePictures() {
    var i = 0,
        pictures = [];

    for (i = 1; i <= this.length; i++) {
        pictures.push({
            index : i - 1,
            url   : `${this.baseUrl}/${numberUtil.zeroPad(i)}@2x.${this.extension}`
        });
    }

    this.pictures = pictures;
    this.select(0);
}

/*
 * Select picture of given index
 *
 * @param {Number} index
 */
function select(index) {
    this.current = this.pictures[index];
}

/*
 * Open pictures gallery lightbox on current image
 */
function zoom() {
    modal.open('pictures-gallery', { pictures: this.pictures, current: this.current })
    .then((index) => {
        // Set current picture to last active while in pictures gallery
        this.current = this.pictures[index];
    });
}