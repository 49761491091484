/*
 * Session module
 *
 * exports current editor to provide instance access to other components
 */

var editor, game;

export default {

    /*
     * Get the current editor
     *
     * @return {LevelEditor}
     */
    getEditor() {
        return editor;
    },

    /*
     * Set the current editor
     *
     * @param {LevelEditor} e
     */
    setEditor(e) {
        editor = e;
    },

    /*
     * Get the current game
     *
     * @return {GamePlay}
     */
    getGame() {
        return game;
    },

    /*
     * Set the current game
     *
     * @param {GamePlay} g
     */
    setGame(g) {
        game = g;
    }

};