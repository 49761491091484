import BaseChallenge from '../class/BaseChallenge';
import modalsSequence from './util/modals-sequence';
import requireClick from '../ui/require-click';
import requireCommand from './util/require-command';
import focus from '../ui/focus';
import bubble from '../ui/bubble';
import session from '../editor/session';

/*
 * Draw Floor challenge
 *
 * First challenge in learn mode, teaches to select and draw tiles
 */

var challenge = {
        name  : 'Behaviours (Drawing)',
        steps : [],
        hide  : {
            items      : true,
            behaviours : true,
            map        : true
        }
    };

// Editor introduction sequence
challenge.steps.push(modalsSequence({ title : 'World' }, [
    { text: 'So, now we\'re able to draw large worlds, but there\'s more to a game!', assistant: 'standing' },
    { text: 'For example, we want to add some difficulty to the game, and allow him to win or lose...', assistant: 'standing' },
    { text: 'To do that, we\'re going to use Behaviours! But first, let\'s make a map...', assistant: 'standing' },
]));

// Draw dark slots
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Let\'s draw a couple dark spots - They\'re gonna be our mystery doors' , target: '.map', type: 'assistant', direction: 'right' });
    focus.on('.tiles-palette, .map');

    editor.layer = 1;

    editor.assistedDrawing([
        '---------------',
        '----x-----x----',
        '----x-----x----',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { wood: [ '9:0', '10:0' ] })
    .then(() => {
        editor.hide.world = false;
        editor.selection.clear();
        focus.off();
        bubble.close();

        // Next tick..
        setTimeout(done);
    });
});

// Select layer 1
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = false;
    bubble.open({ text: 'Let\'s draw on the next layer' , target: '.layers-selection', type: 'assistant', direction: 'left' });
    focus.on('.layers-selection', '.layers-selection .layer-choice-1');
    requireClick.on('.layers-selection .layer-choice-1')
    .then(() => {
        editor.layersLocked = true;
        done();
    });
});

// Draw walls
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Let\'s draw a wall, leaving the doors visible' , target: '.map', type: 'assistant', direction: 'right' });
    focus.on('.tiles-palette, .map');

    editor.assistedDrawing([
        'xxxxxxxxxxxxxxx',
        'xxxx-xxxxx-xxxx',
        'xxxx-xxxxx-xxxx',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { wood: [ '14:0', '15:0', '16:0', '17:0', '4:1', '6:1', '7:1', '9:1', '10:1' ] })
    .then(() => {
        editor.hide.world = false;
        editor.selection.clear();
        focus.off();
        bubble.close();

        // Next tick..
        setTimeout(done);
    });
});

// Draw spikes
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Let\'s add a row of spikes' , target: '.map', type: 'assistant', direction: 'right' });
    focus.on('.tiles-palette, .map');

    editor.assistedDrawing([
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        'xxxxxxxxxx-xxxx',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { wood: [ '54:0', '55:0', '56:0' ] })
    .then(() => {
        editor.hide.world = false;
        editor.selection.clear();
        focus.off();
        bubble.close();

        // Next tick..
        setTimeout(done);
    });
});

// Switch to logic mode
challenge.steps.push(function (done) {
    focus.on('.tool.tool-logic');
    bubble.open({ text: 'Let\'s go to Logic mode', type: 'assistant', target: '.tool.tool-logic', direction: 'left' });
    return requireClick.on('.tool.tool-logic')
    .then(() => {
        // Next tick..
        setTimeout(done);
    });
});

// Draw walls
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Let\'s make the walls solid' , target: '.map', type: 'assistant', direction: 'right' });
    focus.on('[tile="logic:solid"], .map');

    editor.assistedDrawing([
        'xxxxxxxxxxxxxxx',
        'xxxx-xxxxx-xxxx',
        'xxxx-xxxxx-xxxx',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { logic: [ 'solid' ] })
    .then(() => {
        editor.hide.world = false;
        editor.selection.clear();
        focus.off();
        bubble.close();

        // Next tick..
        setTimeout(done);
    });
});

// Draw walls
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Set the Spawn flag in the middle' , target: '.map', type: 'assistant', direction: 'right' });
    focus.on('[tile="logic:spawn"], .map');

    editor.assistedDrawing([
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '-------x-------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------',
        '---------------'
    ], { logic: [ 'spawn' ] })
    .then(() => {
        editor.hide.world = false;
        editor.selection.clear();
        focus.off();
        bubble.close();

        // Next tick..
        setTimeout(done);
    });
});

// Switch to behaviours panel
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.hide.behaviours = false;

    // Next tick..
    setTimeout(() => {
        focus.on('.tool.tool-behaviours');
        bubble.open({ text: 'Now let\'s explore something new - The Behaviours panel!', type: 'assistant', target: '.tool.tool-behaviours', direction: 'left' });
        return requireClick.on('.tool.tool-behaviours')
        .then(() => {
            focus.off();
            bubble.close();
            // Next tick..
            setTimeout(done);
        });
    });
});

// Explain behaviours panel
challenge.steps.push(modalsSequence({ title : 'World' }, [
    { text: 'Behaviours are interactions with the Player - They contain code that explains how a tile behaves!', image: 'behaviours-drawing/map-example' },
    { text: 'They are invisible on the map, but when they will react to the player walking and using commands when playing.', image: 'behaviours-drawing/map-action' },
    { text: 'For now we are not gonna write new Behaviours, we are going to draw some pre-made ones', assistant: 'standing' },
]));

// Draw winning behaviour
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.layersLocked = true;
    bubble.open({ text: 'Select the `win` Behaviour - This will make the player win the game' , target: '.tiles-palette-chromeless', type: 'assistant', direction: 'left' });
    focus.on('.tiles-palette-chromeless', '[tile="behaviour:win"]');
    return requireClick.on('[tile="behaviour:win"]')
    .then(() => {
        bubble.open({ text: 'Now draw the `win` Behaviour over the door on the right side', type: 'assistant', target: '.map', direction: 'right' });
        focus.on('.tiles-palette-chromeless, .map', '.map');

        editor.assistedDrawing([
            '---------------',
            '----------x----',
            '----------x----',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------'
        ], { behaviours: [ 'win' ] })
        .then(() => {
            editor.hide.world = false;
            editor.selection.clear();
            focus.off();
            bubble.close();

            // Next tick..
            setTimeout(done);
        });

    });
});

// Draw losing behaviour
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.behaviours.reset();

    editor.layersLocked = true;
    bubble.open({ text: 'Now select the `lose` Behaviour - This will make the player lose the game' , target: '.tiles-palette-chromeless', type: 'assistant', direction: 'left' });
    focus.on('.tiles-palette-chromeless', '[tile="behaviour:lose"]');
    return requireClick.on('[tile="behaviour:lose"]')
    .then(() => {
        bubble.open({ text: 'And draw it over the other door', type: 'assistant', target: '.map', direction: 'right' });
        focus.on('.tiles-palette-chromeless, .map', '.map');

        editor.assistedDrawing([
            '---------------',
            '----x----------',
            '----x----------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------'
        ], { behaviours: [ 'lose' ] })
        .then(() => {
            editor.hide.world = false;
            editor.selection.clear();
            focus.off();
            bubble.close();

            // Next tick..
            setTimeout(done);
        });

    });
});

// Draw damage behaviour
challenge.steps.push(function (done) {
    var editor = session.getEditor();

    editor.behaviours.reset();

    editor.layersLocked = true;
    bubble.open({ text: 'Finally, let\'s select the `damage` Behaviour - This will hurt the player when walking over it' , target: '.tiles-palette-chromeless', type: 'assistant', direction: 'left' });
    focus.on('.tiles-palette-chromeless', '[tile="behaviour:damage"]');
    return requireClick.on('[tile="behaviour:damage"]')
    .then(() => {
        bubble.open({ text: 'And draw it over the spikes', type: 'assistant', target: '.map', direction: 'right' });
        focus.on('.tiles-palette-chromeless, .map', '.map');

        editor.assistedDrawing([
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            'xxxxxxxxxx-xxxx',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------',
            '---------------'
        ], { behaviours: [ 'damage' ] })
        .then(() => {
            editor.hide.world = false;
            editor.selection.clear();
            focus.off();
            bubble.close();

            // Next tick..
            setTimeout(done);
        });

    });
});

// Switch to gameplay
challenge.steps.push(function (done) {
    focus.off();

    // Congratulate, continue after confirming
    focus.on();
    bubble.open({ text: 'Great job! We\'re ready to play now!', type: 'assistant', button: true })
    .then(() => {

        // Congratulate, continue when OK is pressed
        bubble.open({ text: 'Click on the play button', type: 'assistant', target: '.play-preview', direction: 'right' });
        focus.on('.play-preview');
        return requireClick.on('.play-preview')
        .then(() => {
            // Next tick..
            setTimeout(done);
        });

    });
});

// Walk over `lose` behaviour
challenge.steps.push(function (done) {
    requireCommand(/move\(-3,\s*-4*\)/, 'move(-3, -4)')
    .then(() => {
        requireClick.on('[nothing]');
        bubble.close();
        focus.on('.map');
        bubble.open({ text: 'The player will lose as it walks through the left door', type: 'assistant', target: '.map', direction: 'right' });
        setTimeout(() => {
            focus.on('.game-overlay', '.game-overlay button');
            bubble.open({ text: 'That worked just as expected! Now let\'s try to walk over the winning door!', type: 'assistant', target: '.game-overlay .inner', direction: 'right' });
            requireClick.on('.game-overlay button')
            .then(done);
        }, 4000);
    });
});

// Walk over `win` behaviour
challenge.steps.push(function (done) {
    requireCommand(/move\(3,\s*-4*\)/, 'move(3, -4)')
    .then(() => {
        requireClick.on('[nothing]');
        bubble.close();
        focus.on('.map');
        bubble.open({ text: 'The player will win as it walks through the left door', type: 'assistant', target: '.map', direction: 'right' });
        setTimeout(() => {
            focus.on('.game-overlay', '.game-overlay button');
            bubble.open({ text: 'Great! You created a game that can be won and lost!', type: 'assistant', target: '.game-overlay .inner', direction: 'right', button: true });
            requireClick.on('.bubble button')
            .then(done);
        }, 4000);
    });
});

// Remove focus, close bubble, switch back to editor
challenge.steps.push(function (done) {
    session.getEditor().togglePreview();
    focus.off();
    bubble.close();
    // Next tick..
    setTimeout(done);
});

// Editor introduction sequence
challenge.steps.push(modalsSequence({ title : 'World' }, [
    { text: 'Behaviours are what makes the game fun - You can combine them however you want to make puzzles for the player!', image: 'behaviours-drawing/example-complex' },
    { text: 'You can also write your own, using code.. But we\'ll learn that another time!', assistant: 'standing' }
]));

export default new BaseChallenge(challenge);