/*
 * Alert modal
 *
 * Modal to display a message alert
 */

var data = {
        answer   : null,
        question : null
    };

/*
 * Initialise modal
 */
function run() {
    let button = this.$el.querySelector('button');

    if (button && button.focus) { button.focus(); }
}

export default { data, run };