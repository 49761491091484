module.exports = "<section id=\"hero\" full-height=\"full-height\" first-section=\"first-section\"><div class=\"cloud cloud-01\"></div><div class=\"cloud cloud-02\"></div><div class=\"cloud cloud-03\"></div><div class=\"page-width\"><div class=\"logo\"></div><div class=\"illustration-wrap\"><div class=\"illustration\"></div></div><div class=\"center intro-copy\"><h3>A platform that allows you to create and play amazing games.</h3><div class=\"p large\">Click <span class=\"text-make\">Make </span>to create your own game, click <span class=\"text-learn\">Learn </span>to understand how it works or <span class=\"text-play\">Play </span>to try a ready-made game.</div></div><div class=\"buttons\"><a href=\"/challenges\" class=\"button secondary large highlight\"><i class=\"icon-book\"> </i>Learn</a><a v-on=\"click: play\" class=\"button success large highlight\"><i class=\"icon-play\"> </i>Play</a><a href=\"/editor\" class=\"button primary large highlight\"><i class=\"icon-pencil\"> </i>Make</a></div></div></section><section id=\"play\" full-height=\"full-height\"><div class=\"page-width\"><div class=\"row\"><div class=\"col-6 copy\"><h3> <strong>Play </strong>Adventures</h3><p class=\"large\">Make Adventures is a platform that allows you to create amazing " +
"games that combine a classic RPG visual style and a command-line " +
"interface." +
"<br><br>" +
"But before you start to make your own games, try the adventure that " +
"we handcrafted for you to explore the potential of text-based " +
"storytelling." +
"<br><br>" +
"By writing simple JavaScript commands you control the character, " +
"eplore and interact with the world around you." +
"</p><a v-on=\"click: play\" class=\"button\">Play the Kano Adventure</a></div><div class=\"col-5 off-1\"><pictures-showcase base-url=\"/assets/screenshots/game\" length=\"6\" extension=\"jpg\"></pictures-showcase></div></div></div></section><section id=\"learn\" full-height=\"full-height\"><div class=\"page-width\"><div class=\"row\"><div class=\"col-6 copy\"><h3> <strong>Learn </strong>Adventures</h3><p class=\"large\">With Great Powers Comes Great Responsibility!" +
"<br><br>" +
"In order to unleash the full potential of <a href='/editor'>The Editor</a>, " +
"learn to master it through our guided challenges - Our Builder Judoka " +
"will teach you all you need to know, step by step&hellip;" +
"" +
"</p><a href=\"/challenges\" class=\"button\">Learning Challenges</a></div><div class=\"col-5 off-1\"><pictures-showcase base-url=\"/assets/screenshots/challenges\" length=\"6\" extension=\"jpg\"></pictures-showcase></div></div></div></section><section id=\"make\" full-height=\"full-height\"><div class=\"page-width\"><div class=\"row\"><div class=\"col-6 copy\"><h3><strong>Make </strong>Adventures</h3><p class=\"large\">Ok, so you <a v-on='click: play'>played the Kano Adventure</a> and you " +
"<a href='/challenges'>learned to use The Editor</a> &mdash; Are you ready " +
"to start creating and sharing your own game?" +
"<br><br>" +
"Draw maps, add behaviours, create and place items, use JavaScript to " +
"create a story and get your friends playing with your creations." +
"<br><br>" +
"Wether you enjoy just drawing small maps, creating entire worlds or " +
"even coding your own custom behaviours using JavaScript, wether you're " +
"an artist or a hacker, a writer or a player, this is a tool for you " +
"to express that and have fun." +
"</p><a href=\"/editor/kano-adventure\" class=\"button\">Edit the Kano Adventure</a><a href=\"/editor\" class=\"button\">Make a New Adventure</a></div><div class=\"col-5 off-1\"><pictures-showcase base-url=\"/assets/screenshots/editor\" length=\"6\" extension=\"jpg\"></pictures-showcase></div></div></div></section><section id=\"get-started\" full-height=\"full-height\"><div class=\"page-width\"><div class=\"judoka-face\"></div><h2 class=\"center\"><strong>Get Started Now!</strong></h2><p class=\"center large\">So, what are you waiting for? Start playing, learning and making with " +
"Make Adventures from <a href='http://www.kano.me'>Kano</a> - It's completely free!" +
"</p><br/><div class=\"center buttons\"><a href=\"/challenges\" class=\"button secondary large highlight\"><i class=\"icon-book\"> </i>Learn</a><a v-on=\"click: play\" class=\"button success large highlight\"><i class=\"icon-play\"> </i>Play</a><a href=\"/editor\" class=\"button primary large highlight\"><i class=\"icon-pencil\"> </i>Make</a></div></div></section><footer><div class=\"page-width\"><ul><li><i class=\"icon-mail\"> </i><a v-on=\"click: feedbackModal\">Say Hi</a></li><li><a href=\"http://www.kano.me\" target=\"_blank\"><img src=\"/assets/ui/kano-logo.svg\" class=\"logo\"/></a></li></ul></div></footer>" ;
