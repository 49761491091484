/*
 * Default Entities
 *
 * Exports level's default entities
 */

export default [

    {
        type   : 'solid',
        tile   : [ 1, 0, 'logic' ]
    },

    {
        type   : 'spawn',
        tile   : [ 2, 0, 'logic' ],
        unique : true
    }

];