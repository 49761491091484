import saveSlots from '../util/save-slots';
import api from '../core/api';
import error from '../core/error';
import modal from '../ui/modal';

/*
 * Save Slots modal
 *
 * Modal to select a save slot
 */

var data = {
        levelId : null,
        slots   : null,
        loading : true,
        error   : null
    },
    methods = { chooseSlot, clearSlot, update, hasSaves },
    OVERRIDE_COPY = (
        'The level has been modified since you last played - ' +
        'Do you wish to start again on the new version or restore your previous game?<br><br>' +
        'Note: By choosing `override` you will play the latest version but you will lose' +
        'your progress on the chosen save slot.'
        );

/*
 * Initialise modal
 */
function run() {
    api.levels.load({ id: this.levelId })
    .then((res) => {
        this.level = res.body.level;
    }, (res) => {
        this.error = res.body;
    })
    .then(this.update)
    .then()
    .catch(error.handle);
}

/*
 * Update slots from API
 *
 * @return {Promise}
 */
function update() {
    this.loading = true;

    return saveSlots.list(this.levelId)
    .then((slots) => {
        this.slots = slots;

        if (!this.hasSaves()) {
            return this.done(this.slots[0]);
        }

        this.loading = false;
    }, (res) => {
        this.error = res.body;
    })
    .catch(error.handle);
}

/*
 * Return true if all slots are empty
 *
 * @return {Boolean}
 */
function hasSaves() {
    var i;

    for (i = 0; i < this.slots.length; i++) {
        if (!this.slots[i].empty) {
            return true;
        }
    }

    return false;
}

/*
 * Complete choice with given slot
 *
 * @param {Object} slot
 * @param {Boolean=} confirmed
 */
function chooseSlot(slot, confirmed = false) {
    if (!confirmed && !slot.empty && this.level.version !== slot.save.meta.version) {
        this.hide = true;

        return modal.open('confirm', {
            title       : 'New Version',
            text        : OVERRIDE_COPY,
            cancelText  : 'Override',
            confirmText : 'Continue',
            colors      : false
        }, (confirmed) => {
            // Override slot
            if (!confirmed) {
                this.clearSlot(slot, true, () => {
                    this.done(slot);    
                });
            }
        });
    }

    this.done(slot);
}

/*
 * Clear given save slot
 *
 * @param {Object} slot
 * @param {Boolean=} confirmed
 * @param {Function=} callback
 */
function clearSlot(slot, confirmed = false, callback = null) {
    callback = callback || function () {};

    if (confirmed) {
        this.loading = true;

        return saveSlots.clear(this.level.id, slot.id)
        .then(() => {
            this.update();
            callback();
        }, (err) => {
            this.error = err;
        })
        .catch(error.handle);
    }

    this.hide = true;

    modal.open('confirm', {
        title : 'Are you sure?',
        text  : 'If you clear this slot, you\'ll lose your game progress'
    }, (confirmed) => {
        if (confirmed) { this.clearSlot(slot, true).then(callback); }

        this.hide = false;
    });
}

export default { data, methods, run };