import Map from './Map';
import mapsGridUtil from '../util/maps-grid';

/*
 * Level class
 *
 * Wraps level data passed in options with common level-related methods used
 * by both editor and game
 */

export default class Level {

    /*
     * Level constructor
     *
     * @param {Object=} options
     */
    constructor(options = {}) {
        var maps = options.maps || [];

        this.id = options.id || null;
        this.slug = options.slug || null;
        this.name = options.name || null;
        this.behaviours = options.behaviours || {};
        this.items = options.items || {};
        this.maps = [];
        this.lastMapOpened = options.lastMapOpened || 0;
        this.version = options.version || 1;
        this.previewIndex = options.previewIndex || 0;

        for (let i = 0; i < maps.length; i++) {
            this.maps[i] = new Map(this, maps[i]);
        }
    }

    /*
     * Create a copy of the level
     *
     * @return {Level}
     */
    copy() {
        var map, cloned, clonedMap;

        cloned = new this.constructor({
            slug          : this.slug,
            name          : this.name,
            behaviours    : this.behaviours,
            items         : this.items,
            maps          : [],
            lastMapOpened : this.lastMapOpened,
            version       : this.version,
            previewIndex  : this.previewIndex
        });

        for (map of this.maps) {
            clonedMap = map.copy();
            clonedMap.level = cloned;
            cloned.maps.push(clonedMap);
        }

        return cloned;
    }

    /*
     * Get map and location of spawn point
     *
     * @return {Object}
     */
    getSpawnPoint() {
        for (let map of this.maps) {
            let spawn = map.getEntity({ type: 'spawn' });

            if (spawn) {
                return {
                    map : map,
                    x   : spawn.x,
                    y   : spawn.y
                };
            }
        }

        return {
            map : this.maps[0],
            x   : 0,
            y   : 0
        };
    }

    /*
     * Get behaviour Object by category and id
     *
     * @param {String} category
     * @param {String} id
     * @return {Object}
     */
    getBehaviour(category, id) {
        return this.behaviours[category][id];
    }

    /*
     * Get map by name
     *
     * @param {String} name
     * @return {Map}
     */
    getMapByName(name) {
        for (let map of this.maps) {
            if (map.name === name) { return map; }
        }

        return null;
    }

    /*
     * Generate instances from behaviours in each map
     *
     * @param {GamePlay} game
     */
    generateInstances(game) {
        this.maps.forEach(function (map) {
            map.generateInstances(game);
        });
    }

    /*
     * Export level output
     *
     * @param {Boolean=} exportInstances
     * @return {Object}
     */
    export(exportInstances = false) {
        return {
            id            : this.id,
            slug          : this.slug,
            name          : this.name,
            behaviours    : this.behaviours,
            items         : this.items,
            maps          : this.maps.map((map) => { return map.export(exportInstances); }),
            lastMapOpened : this.lastMapOpened,
            version       : this.version,
            previewIndex  : this.previewIndex
        };
    }

    /*
     * Generate and render whole level preview using packing algorithm from the
     * back-end and renderer from the grid utility
     *
     * @param {Function} callback
     * @param {Number=} size
     */
    generatePreview(callback, size = 600) {
        mapsGridUtil.solveAndRender(this.maps, size, size, callback);
    }

}