/*
 * Text module
 *
 * Speech and conversation functions
 */

/*
 * Make your character say something out loud
 *
 * @param {String} message
 */
exports.say = function (message) {
    this.terminal.log(`{person}You{/person} {action}say{/action} {speech}${message}{/speech}`);
    this.player.speech(message);
};

/*
 * Make your character shout something out loud
 *
 * @param {String} message
 */
exports.shout = function (message) {
    message = message.toUpperCase();
    this.terminal.log(`{person}You{/person} {action}shout{/action} {speech}${message}{/speech}`);
    this.player.speech(message, 'shout');
};