/*
 * Image utility module
 *
 * A small module containing utilities to work with Images
 */

/*
 * Convert Image to Blob
 *
 * @param {Image} image
 * @param {Function} callback
 */
exports.toBlob = function (image, callback) {
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

    canvas.width = ctx.width = image.width;
    canvas.height = ctx.height = image.height;

    ctx.drawImage(image, 0, 0, image.width, image.height);

    canvas.toBlob(callback);
};