import EventEmitter from 'tiny-emitter';
import async from 'async';

/*
 * Terminal class
 *
 * In-game terminal interface
 */

export default class Terminal extends EventEmitter {

    /*
     * Terminal constructor
     *
     * @param {Object=} options
     */
    constructor(options = {}) {
        super();
        this.output = options.output || [];
        this.history = options.history || [];
        this.promptCallbacks = [];
        this.promptMode = false;
    }

    /*
     * Log a message
     *
     * @param {String} message
     */
    log(message) {
        this.print('message', message);
    }

    /*
     * Log an error
     *
     * @param {String} message
     */
    error(err) {
        this.print('error', err.toString());
    }

    /*
     * Log a message
     *
     * @param {String} message
     */
    print(type, text) {
        let html = this.compile(text);
        this.emit('update');
        this.output.push({ type, text, html });
    }

    /*
     * Log a description
     *
     * @param {String} text
     */
    describe(text) {
        this.print('description', text);
    }

    /*
     * Print ASCII art
     *
     * @param {String} str
     */
    ascii(str) {
        this.print('ascii', str);
    }

    /*
     * Compile message HTML
     *
     * @param {String} message
     */
    compile(message) {
        return (message || '').toString()
        .replace(/\n/g, '<br>')
        .replace(/{([a-zA-Z]*)}([^{]*){\/\1}/g, function (match, type, text) {
            return '<span class="token token-' + type + '">' + text + '</span>';
        });
    }

    /*
     * Log a warning message
     *
     * @param {String} message
     */
    warn(message) {
        this.print('warning', '[!] ' + message);
    }

    /*
     * Print a given number blank lines
     *
     * @param {Number} number
     */
    blank(number) {
        let str = '';

        for (let i = 0; i < number; i++) {
            str += '\n';
        }

        this.print('blank', str);
    }

    /*
     * Log message as an animated sequence
     *
     * @param {String} message
     * @param {Function=} callback
     * @param {Number=} interval
     */
    sequence(messages, callback = null, interval = 30, messagesInterval = 1000) {
        var i = this.output.length,
            terminal = this,
            c = 0,
            message, log, tasks;

        if (messages instanceof Array) {
            tasks = messages.map((message) => {
                return (callback) => {
                    this.sequence(message, () => {
                        setTimeout(callback, messagesInterval);
                    }, interval);
                };
            });

            return async.series(tasks, callback);
        }

        message = messages;

        this.print('sequence', '');

        log = this.output[i];

        nextLetter();

        function nextLetter() {
            log.text = log.text + message[c];
            log.html = terminal.compile(log.text);

            c++;

            if (c >= message.length) {
                if (callback) { callback(); }
                return;
            }

            setTimeout(function () {
                nextLetter();
            }, interval);
        }
    }

    /*
     * Enter prompt mode and cue prompt callback
     *
     * @param {String} question
     * @param {Function=} callback
     * @param {String=} method
     * @param {String=} type
     */
    prompt(question, callback = null, method = 'print', type = 'message') {
        var previousState = this.promptMode;

        this[method](type, question);
        this.promptMode = true;
        this.promptCallbacks.push(callback || null);

        if (previousState !== this.promptMode) {
            this.emit('promptStart');
        }
    }

    /*
     * Answer first cued prompt and fire callback if found - exit prompt mode
     * if last in the cue
     *
     * @param {String=} answer
     */
    answerPrompt(answer = null) {
        var callback;

        if (this.promptCallbacks.length) {
            callback = this.promptCallbacks[0];
            if (typeof callback === 'function') { callback(answer); }
            this.promptCallbacks.splice(0, 1);
        }

        if (!this.promptCallbacks.length) {
            if (this.promptMode) {
                this.emit('promptEnd');
            }

            this.promptMode = false;
        }
    }

    /*
     * Y/n Confirmation prompt
     *
     * @param {String} confirm
     * @param {Function=} yesFn
     * @param {Function=} noFn
     */
    confirm(message, yesFn = null, noFn = null) {
        var text = `\n{question}${message}{/question}\n\n{options}[Y/n]{/options}\n`;

        this.prompt(text, function (answer) {
            var positive = answer.trim().toLowerCase() === 'y';

            if (positive && typeof yesFn === 'function') {
                yesFn();
            } else if (!positive && typeof noFn === 'function') {
                noFn();
            }
        }, 'print', 'question');
    }

}