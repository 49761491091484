import api from '../core/api';
import error from '../core/error';

/*
 * Feedback modal
 *
 * Modal to send feedback
 */

var data = {
        success : false,
        error   : null,
        text    : '',
        name    : '',
        email   : '',
        title   : null,
        copy    : ''
    },
    methods = {
        send
    };

/*
 * Initialise modal
 */
function run() {
}

function send() {
    if (!this.text) {
        this.error = 'Text is required';
        return;
    }

    api.feedback.send({
        name  : this.name,
        text  : this.text,
        email : this.email
    }).then(() => {
        this.success = true;
    }, (res) => {
        this.error = res.body;
    })
    .catch(error.handle);
}

export default { data, methods, run };