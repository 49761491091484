var config = require('../config'),
    analytics = window.analytics;

/*
 * Analytics module
 *
 * Wrapper to Segment.io analytics
 * More documentation at: https://segment.com/docs/libraries/analytics.js
 */

var enabled = config.SEGMENTIO_ID && analytics;

/*
 * Initialise Segment.io if `SEGMENTIO_ID` is present in config, start
 * tracking page views
 *
 * @return void
 */
function init() {
    if (!enabled) { return; }

    window.analytics.load(config.SEGMENTIO_ID);
}

/*
 * Track current page view
 *
 * @param {String} pageId
 * @return void
 */
function page(pageId) {
    if (!enabled) { return; }

    window.analytics.page(pageId);
}

/*
 * Tie a user to the tracked data
 *
 * @param {String} userId
 * @param {Object} traits
 * @param {Object} options
 * @param {Function} callback
 */
function identify(userId, traits, options, callback) {
    if (!enabled) { return; }

    window.analytics.identify(userId, traits, options, callback);
}

/*
 * Record actions that a user performs
 *
 * @param {String} event
 * @param {Object} properties
 * @param {Object} options
 * @param {Function} callback
 */
function track(event, properties, options, callback) {
    if (!enabled) { return; }

    window.analytics.track(event, properties, options, callback);
}

export default { init, page, identify, track };