import Vue from 'vue';
import keycode from 'keycode';
import EventEmitter from 'tiny-emitter';
import Player from '../class/Player';
import GameState from '../class/GameState';
import GameControls from '../class/GameControls';
import Terminal from '../class/Terminal';
import Inventory from '../class/Inventory';
import GameAPI from '../class/GameAPI';
import error from '../core/error';
import router from '../core/router';
import config from '../config';
import docs from '../data/docs.json';
import modal from '../ui/modal';
import tracking from '../core/tracking';
import session from '../editor/session';
import saveSlotsUtil from '../util/save-slots';
import authUtil from '../util/auth';
import kanoWorld from '../core/kano-world';
import GameLoop from 'game-loop';

/*
 * Game Play component
 *
 * Display gameplay interface
 */

 const TERMINAL_SPACING = 210,
    BOUNCE_DELAY = 50,
    STATS_SIZE = 15,
    NAV_SPACING = 150,
    ITEM_FEEDBACK_DURATION = 1000,
    SAVE_FEEDBACK_DURATION = 1000,
    CONTROL_DELAY = 100,
    SPEED_PER_SECOND = 5,
    MOVING_DIRS = {
        up    : [ 0, -1 ],
        right : [ 1, 0 ],
        down  : [ 0, 1 ],
        left  : [ -1, 0 ]
    };

Vue.component('game-play', {
    data            : () => {
        return {
            level              : null,
            map                : null,
            mapStyle           : {},
            panelStyle         : {},
            terminalStyle      : {},
            innerPanelStyle    : {},
            events             : new EventEmitter(),
            terminal           : new Terminal(),
            player             : new Player(),
            inventory          : new Inventory(),
            command            : null,
            over               : false,
            victory            : false,
            rated              : false,
            overlay            : null,
            transitioning      : false,
            tab                : 'interact',
            showHelp           : false,
            docs               : docs,
            helpTab            : 'main',
            inputDisabled      : false,
            collectFeedback    : false,
            newItems           : 0,
            controls           : null,
            ready              : false,
            saveSlot           : null,
            saving             : false,
            toggles            : true,
            autosave           : false,
            directionsPressed  : [],
            lastMovementUpdate : null,
            movingDirection    : null,
            inputFocus         : false
        };
    },
    template        : require('../../views/component/game-play.jade'),
    isFn            : true,
    replace         : true,
    created         : init,
    ready           : ready,
    beforeRemove    : unbind,
    props           : [ 'level', 'saveSlot', 'toggles', 'autosave' ],
    methods         : {
        bind,
        loadState,
        generateInstances,
        submitPrompt,
        answerPrompt,
        endPrompt,
        startPrompt,
        promptFocus,
        promptKeyDown,
        updateTerminal,
        enterMap,
        playerMove,
        bouncePlayer,
        endGame,
        win,
        lose,
        showOverlay,
        hideOverlay,
        restart,
        tryAgain,
        initializeInstances,
        statChange,
        teleport,
        collectItem,
        selectItem,
        addControlKey,
        removeControlKey,
        showInventoryNotification,
        useItem,
        saveGameState: authUtil.required(saveGameState),
        feedbackModal,
        rate,
        startMoving,
        stopMoving,
        updateMovement,
        getInput,
        updateFocus,
        playerChangeTile
    }
});

/*
 * Initialise component
 */
function init() {
    session.setGame(this);
}

/*
 * Run component
 */
function ready() {
    // Initialise game state
    this.state = new GameState(this);

    // Initialise UI properties
    this.prompt = { cursor: 0 };

    // Instanciate game API
    this.api = new GameAPI({
        game  : this,
        state : {}
    });

    // // Instanciate game controls
    this.controls = new GameControls();

    // Load initial save state
    this.loadState();

    // Bind events and watch data
    this.bind();

    // Update focus state on ready
    this.$watch('ready', () => {
        if (!this.ready) { return; }

        // Next tick..
        setTimeout(() => {
            this.updateFocus();
        });
    });
}

/*
 * Update inputFocus Boolean
 */
function updateFocus() {
    this.inputFocus = !!this.getInput('focus');
}

/*
 * Load game state from save slot
 */
function loadState() {
    var game = this;

    if (this.saveSlot === null || typeof this.saveSlot === 'undefined') {
        return notAvailable();
    }

    saveSlotsUtil.load(this.level.id, this.saveSlot)
    .then((save) => {
        if (!save) { return notAvailable(); }
        this.state.load(save);
        this.ready = true;
    }, error.handle)
    .catch(error.handle);

    /*
     * A game save was not found - start from scratch
     */
    function notAvailable() {
        var spawnPoint = game.level.getSpawnPoint();

        game.ready = true;
        game.generateInstances();
        game.enterMap(spawnPoint.map, spawnPoint, false);
    }
}

/*
 * Generate map instances from loaded /initial state
 */
function generateInstances() {
    this.level.generateInstances(this);
}

/*
 * Show game overlay with given options
 *
 * @param {Object} options
 */
function showOverlay(options) {
    this.overlay = {
        title   : options.title || null,
        text    : options.text || null,
        class   : options.class || null,
        buttons : options.buttons || []
    };
}

/*
 * hide game overlay
 */
function hideOverlay() {
    this.overlay = null;
}

/*
 * End game - Win or lose depending on victory value, write message and
 * run player animation
 *
 * @param {Boolean} victory
 * @param {String} playerAnimation
 * @param {String} message
 */
function endGame(victory, message = null, playerAnimation = null) {
    var buttons = [],
        inEditor = router.path.indexOf('editor/') !== -1;

    this.player.stop();

    if (inEditor) {
        buttons.push({
            label : 'Back to Editor',
            fn    : this.$parent.togglePreview.bind(this.$parent)
        });
    } else {
        buttons.push({
            label : (victory ? 'Play' : 'Try') + ' again',
            fn    : victory ? this.restart : this.tryAgain
        });

        if (victory) {
            buttons.push({
                classname : 'primary highlight',
                label     : 'Make',
                fn        : () => {
                    router.goTo(`/editor/${this.level.id}`);
                }
            });
        }
    }

    this.player.animate(playerAnimation, () => {
        this.player.visible = victory;
        this.over = true;
        this.victory = victory;
        this.showOverlay({
            title   : victory ? 'Well Done' : 'Game Over..',
            text    : message,
            class   : victory ? 'success' : 'failure',
            buttons : buttons
        });
    });

    tracking.track(victory ? 'game-won' : 'game-lost');
}

/*
 * Open feedback modal
 */
function feedbackModal() {
    modal.open('feedback');
}

/*
 * Restart view
 */
function restart() {
    router.refresh();
}

/*
 * Try again from current map
 */
function tryAgain() {
    this.enterMap(this.map, this._entryPoint);
    this.over = false;
    this.victory = false;
    this.rated = false;
    this.player.visible = true;
    this.player.resetStats();
    this.hideOverlay();
}

/*
 * Control reactions of player's movement
 *
 * @param {Object} pos
 * @param {Object} off
 * @param {Boolean=} subTileMovement
 */
function playerMove(pos, off, subTileMovement = false) {
    let positions = getTouchedPositions(pos.x, pos.y),
        outOfBoundaries = this.player.isOutOfBoundaries(),
        transitionTo = outOfBoundaries ? getPlayerTransition.call(this) : null,
        collides = false,
        instance, i, entity;

    for (i = 0; i < positions.length; i++) {
        entity = this.map.getEntity(positions[i]);
        instance = this.map.getInstance(positions[i]);

        if (
            entity && entity.type === 'solid' ||
            instance && instance.type === 'item' && !instance.pickable
            ) {
            collides = true;
            break;
        }
    }

    instance = this.map.getInstance({ x: this.player.tile.x, y: this.player.tile.y });

    if (transitionTo) { // Change map
        this.enterMap(transitionTo.map, transitionTo.pos);
    } else if (
        collides || // If collided with a solid entity ..
        outOfBoundaries || // .. or is out of boundaries ..
        (instance && instance.type === 'item' && !instance.pickable) // .. or is a non-pickable item ..
        ) {
        // Bounce player back
        return this.bouncePlayer({ x: -off.x, y: -off.y }, subTileMovement === true);
    }
}

/*
 * Handle player tile change, perform `walk` hook or collect item
 */
function playerChangeTile(x, y) {
    var instance = this.map.getInstance({ x, y });

    if (!instance) { return; }

    if (instance.type === 'item' && instance.pickable) { // If is a pickable item..
        return this.collectItem(instance);
    }

    // .. perform its `walk` hook
    instance.perform('walk', this.api);
}

/*
 * Get touched positions by x and y coordinates (If on fraction coordinates
 * involves more than a result)
 *
 * @param {Number} x
 * @param {Number} y
 * @return {[Object]}
 */
function getTouchedPositions(x, y) {
    let floored = { x: Math.floor(x), y: Math.floor(y) };

    if (floored.x === x && floored.y === y) {
        return [ floored ];
    } else if (floored.x === x) {
        return [ floored, { x: floored.x, y: floored.y + 1 } ];
    } else if (floored.y === y) {
        return [ floored, { x: floored.x + 1, y: floored.y } ];
    } else {
        return [
            floored,
            { x: floored.x + 1, y: floored.y },
            { x: floored.x, y: floored.y + 1 },
            { x: floored.x + 1, y: floored.y + 1 }
        ];
    }
}

/*
 * Collect item by given instance
 *
 * @param {ItemInstance} itemInstance
 */
function collectItem(itemInstance) {
    itemInstance.perform('collect', this.api); // .. trigger item's collect hook ..
    this.inventory.add(itemInstance); // .. add to inventory ..
    this.map.removeInstance(itemInstance); // .. and remove from map ..
}

/**
 * Show inventory collection notification
 *
 * @param {ItemInstance} item
 */
function showInventoryNotification(item) {
    // Get item props
    var props = this.level.items[item.id];

    // Show terminal log
    this.terminal.log('{action}Item {/action}{item}' + props.name + '{/item}{action} collected{/action}');

    // Add new item notification
    this.newItems++;

    if (this._animatingFeedback) { return; }

    // Show visual feedback
    this.collectFeedback = true;
    this._animatingFeedback = true;

    // Hide visual feedback
    setTimeout(() => {
        this._animatingFeedback = false;
        this.collectFeedback = false;
    }, ITEM_FEEDBACK_DURATION);
}

/*
 * Find new map and location for player if off boundaries on a connected map
 *
 * @return {Object}
 */
function getPlayerTransition() {
    var pos = { x: this.player.x, y: this.player.y },
        newPos, newMap, entity;

    if (pos.x < 0 && this.map.hasConnection('left')) {
        // Left
        newPos = { x: config.MAP_SIZE[0] - 1, y: pos.y };
        newMap = this.map.connections.left;
    } else if (pos.x + 0.9 >= config.MAP_SIZE[0] && this.map.hasConnection('right')) {
        // Right
        newPos = { x: 0, y: pos.y };
        newMap = this.map.connections.right;
    } else if (pos.y < 0 && this.map.hasConnection('top')) {
        // Top
        newPos = { x: pos.x, y: config.MAP_SIZE[1] - 1 };
        newMap = this.map.connections.top;
    } else if (pos.y + 0.9 >= config.MAP_SIZE[1] && this.map.hasConnection('bottom')) {
        // Bottom
        newPos = { x: pos.x, y: 0 };
        newMap = this.map.connections.bottom;
    }

    if (!newPos) { return null; }

    newMap = this.level.maps[newMap];
    entity = newMap.getEntity({ x: newPos.x, y: newPos.y });

    if (entity && entity.type === 'solid') { return null; }

    return { map: newMap, pos: newPos };
}

/*
 * Perform given ItemEntity's `use` hook
 *
 * @param {ItemEntity} item
 */
function useItem(item) {
    item.perform('use', this.api);

    if (item.singleUse) {
        this.inventory.removeItem(item.id, 1);
    }
}

/*
 * Handle player stat change
 *
 * @param {String} key
 * @param {Number} oldVal
 * @param {Number} newVal
 */
function statChange(key, oldVal, newVal) {
    let action;

    if (key === 'health') {
        action = newVal > oldVal ? 'gain' : 'lose';
    } else {
        action = newVal > oldVal ? 'charge' : 'use';
    }

    let diff = Math.abs(oldVal - newVal),
        msg = (
            '{person}You{/person} {action}' + action + '{/action} {stat}' +
            diff + ' ' + key + ' point' + (diff > 1 ? 's' : '') + '{/stat}'
            );

    this.terminal.print(key + '-' + action, msg);
}

/*
 * Bounce player back by given amount
 *
 * @param {Object} amt
 * @param {Boolean} soft
 */
function bouncePlayer(amt, soft = false) {
    if (soft) {
        this.player.x += amt.x;
        this.player.y += amt.y;
        return;
    }

    setTimeout(() => {
        this.player.x += amt.x;
        this.player.y += amt.y;
        this.terminal.warn('{person}You{/person} can\'t walk there!');
        this.player.stop();
    }, BOUNCE_DELAY);
}

/*
 * Prompt creation of new control key
 */
function addControlKey() {
    modal.open('select-key', {}, (key) => {
        if (!key) {
            return modal.open('alert', { text: 'No key selected' });
        }

        this.controls.keys.$set(key, '');

        setTimeout(() => {
            var controlInputs = this.$el.querySelectorAll('.controls-map input'),
                lastInput = controlInputs[controlInputs.length - 1];

            lastInput.focus();
        });
    });
}

/*
 * Confirm deletion of a control key
 *
 * @param {String} key
 */
function removeControlKey(key) {
    modal.open('confirm', { text: 'Remove ' + key + ' key control?' }, (confirmed) => {
        if (!confirmed) { return; }

        this.controls.keys.$delete(key);
    });
}

/*
 * Enter given map at given position
 *
 * @param {Map} map
 * @param {Object} pos
 * @param {Boolean=} save
 */
function enterMap(map, pos, save = true) {
    this.transitioning = true;
    this._entryPoint = { x: pos.x, y: pos.y };

    // Next tick..
    setTimeout(() => {
        this.map = map;

        this.player.x = this.player.tile.x = pos.x;
        this.player.y = this.player.tile.y = pos.y;

        figlet(this.map.name, this.map.config.font, (err, text) => {
            if (err) { return error.handle(err); }

            this.terminal.ascii(text);
            this.terminal.blank(1);

            this.terminal.log(
                '{person}You{/person} {action}entered{/action} {location}' +
                this.map.name +
                '{/location} - Type {cmd}help{/cmd} if you\'re struggling\n\n'
                );

            if (this.map.scripts.enter) {
                // Next tick..
                setTimeout(() => {
                    this.api.execute(this.map.scripts.enter);
                });
            }

            this.initializeInstances();

            this.api.execute('observe(true)');

            // Next tick..
            setTimeout(() => {
                this.transitioning = false;
            });

            if (save && this.autosave && kanoWorld.loggedIn()) {
                this.saveGameState();
            }
        });
    });
}

/*
 * Teleport to given map and location by mapId, x and y
 *
 * @param {Number=} x
 * @param {Number=} y
 * @param {String=} mapId
 */
function teleport(x = 0, y = 0, mapName = null) {
    let map = this.level.getMapByName(mapName) || this.map;

    this.enterMap(map, { x, y });
}

/*
 * Initialize instances in current map
 */
function initializeInstances() {
    this.map.instances.forEach((instance) => {
        if (!instance.initialized) {
            instance.perform('initialize', this.api);
            instance.initialized = true;
        }
    });
}

/*
 * Bind DOM events
 */
function bind() {
    // Clear new items notification counter every time inventory tab is opened
    this.$watch('tab', function (tab) {
        if (tab === 'inventory') {
            this.newItems = 0;
        }
    });

    // Always stay on interact tab on command changes
    this.$watch('command', function () {
        if (this.tab !== 'interact') {
            this.tab = 'interact';
        }
    });

    // Bind player events
    this.player.on('move', this.playerMove.bind(this));
    this.player.on('change-tile', this.playerChangeTile.bind(this));
    this.player.on('stat-change', this.statChange.bind(this));
    this.player.on('game-end', this.endGame.bind(this));

    // Bind terminal events
    this.terminal.on('update', this.updateTerminal.bind(this));
    this.terminal.on('promptStart', this.startPrompt.bind(this));
    this.terminal.on('promptEnd', this.endPrompt.bind(this));

    // Bind inventory events
    this.inventory.on('add', this.showInventoryNotification);

    // Bind controls events
    this.controls.on('command', (command) => {
        if (this.inputDisabled) { return; }
        this.command = command;
        setTimeout(() => {
            this.submitPrompt();
        }, CONTROL_DELAY);
    });

    // Bind game controls
    this.controls.bind();

    // Prepare DOM events callbacks
    this.resize = resize.bind(this);
    this.keydown = keydown.bind(this);
    this.keyup = keyup.bind(this);

    // Bind DOM events
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('keyup', this.keyup);
    window.addEventListener('resize', this.resize);

    // Trigger first resize
    this.resize();

    // Create game loop
    this.loop = new GameLoop();

    // Update player movement
    this.loop.use(this.updateMovement.bind(this));

    // Start game loop
    this.loop.play();
}

/*
 * Unbind DOM events
 */
function unbind() {
    // Unbind game controls
    this.controls.unbind();

    // Unbind DOM events
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('keyup', this.keyup);

    // Stop game loop
    this.loop.stop();
}

/*
 * Triggered on key down, close help overlay when ESC is pressed
 */
function keydown(e) {
    if (!this.$el) { return; }

    var key = keycode(e.keyCode),
        input = this.getInput();

    if (key === 'esc') {
        this.showHelp = false;
        if (input) { input.blur(); }
    } else if (key === 'tab') {
        if (input) { input.blur(); }
    } else if (e.target.tagName !== 'INPUT' && Object.keys(MOVING_DIRS).indexOf(key) !== -1) {
        this.startMoving(key);
    }
}

/*
 * Triggered on key down, close help overlay when ESC is pressed
 */
function keyup(e) {
    var key = keycode(e.keyCode);

     if (Object.keys(MOVING_DIRS).indexOf(key) !== -1) {
        this.stopMoving(key);
    }
}

/*
 * Triggered on window resize, update layout
 */
function resize() {
    let mapSize = Math.floor(window.innerHeight / config.MAP_SIZE[0]) * config.MAP_SIZE[0] - STATS_SIZE,
        headerHeight = document.querySelector('header').offsetHeight;

    this.mapStyle = {
        width  : mapSize + 'px',
        height : mapSize - headerHeight + 'px'
    };

    this.panelStyle = {
        width  : window.innerWidth - mapSize + 'px' ,
        height : window.innerHeight - headerHeight + 'px'
    };

    this.innerPanelStyle = {
        width  : window.innerWidth - mapSize + 'px',
        height : window.innerHeight - NAV_SPACING - headerHeight + 'px'
    };

    this.terminalStyle = {
        height : window.innerHeight - TERMINAL_SPACING - headerHeight + 'px'
    };

    this.updateTerminal();
}

/*
 * Submit command in prompt box
 *
 * @param {SubmitEvent} e
 */
function submitPrompt(e) {
    if (e) { e.preventDefault(); }

    this.promptFocus();

    if (this.commandRestriction && !this.commandRestriction.test(this.command)) {
        this.events.emit('wrong', this.command);
        this.command = '';
        return;
    }

    if (this.command === 'help') {
        this.showHelp = true;
        this.command = '';
        return;
    }

    if (this.terminal.promptMode) {
        return this.answerPrompt();
    }

    this.terminal.print('command', this.command);
    this.events.emit('command', this.command);
    this.api.execute(this.command, false);
    this.terminal.history.push(this.command);

    this.command = null;
    this.prompt.cursor = 0;
}

/*
 * Enter prompt mode - clear current command and focus on prompt
 */
function startPrompt() {
    this.command = null;
    this.promptFocus();
}

/*
 * Focus on terminal prompt
 */
function promptFocus() {
    // Next tick..
    setTimeout(() => {
        if (!this.$el) { return; }
        this.$el.querySelector('[ref="terminal-prompt"]').focus();
    });
}

/*
 * Answer current prompt
 */
function answerPrompt() {
    this.terminal.print('answer', this.command);
    this.terminal.answerPrompt(this.command);
}

/*
 * Exit prompt mode
 */
function endPrompt() {
    this.command = null;
}

/*
 * Browse history on up / down keys over prompt
 *
 * @param {KeyDownEvent} e
 */
function promptKeyDown(e) {
    var key = keycode(e.keyCode),
        history = this.terminal.history,
        offset, command, cursor;

    if (key === 'up') {
        offset = 1;
    } else if (key === 'down') {
        offset = -1;
    }

    cursor = this.prompt.cursor + offset;
    command = history[history.length - cursor];

    if (command || cursor === 0) {
        this.prompt.cursor = cursor;
        this.command = command || '';
    }
}

/*
 * Win game with given conditions
 *
 * @param {String=} animation
 * @param {String=} message
 */
function win(message = 'You won the game! Now what about Making it?', animation = 'jumps') {
    this.endGame(true, message, animation);
}

/*
 * Lose game with given conditions
 *
 * @param {String=} animation
 * @param {String=} message
 */
function lose(message = 'You lost!', animation = 'pop-vanish') {
    this.endGame(false, message, animation);
}

/*
 * Prefill terminal input with item use command
 *
 * @param {String} itemId
 */
function selectItem(itemId) {
    this.command = 'useItem("' + itemId + '")';
    this.tab = 'interact';
    this.promptFocus();
}

/*
 * Scroll terminal to last line - Called after terminal updates or resizes
 */
function updateTerminal() {
    // Next tick..
    setTimeout(() => {
        if (!this.$el) { return; }

        var logEl = this.$el.querySelector('[ref="terminal-log"]');

        if (!logEl) { return; }

        logEl.scrollTop = logEl.scrollHeight;
    });
}

/*
 * Save current game's state to localStorage
 */
function saveGameState() {
    if (this.saveSlot !== null && typeof this.saveSlot === 'undefined') { return; }

    this.saving = true;

    setTimeout(() => {
        this.saving = false;
    }, SAVE_FEEDBACK_DURATION);

    saveSlotsUtil.save(this.saveSlot, this.state);
}

/*
 * Rate game either yay or nay
 *
 * @param {Boolean=} yay
 */
function rate(yay = false) {
    tracking.track('vote', { yay: yay });
    this.rated = true;
}

/*
 * Start sub-tile player movement in given direction
 *
 * @param {String} dir
 */
function startMoving(dir) {
    if (this.directionsPressed.indexOf(dir) === -1) {
        this.directionsPressed.push(dir);
    }
}

/*
 * Stop sub-tile player movement in given direction
 *
 * @param {String} dir
 */
function stopMoving(dir) {
    if (this.directionsPressed.indexOf(dir) !== -1) {
        this.directionsPressed.splice(this.directionsPressed.indexOf(dir), 1);

        if (this.transitioning && !this.directionsPressed.length) {
            this.transitioning = false;
            this.movingDirection = null;

            // Snap position to grid
            this.player.snapPosition();
        }
    }
}

/*
 * Update player sub-tile movement
 */
function updateMovement() {
    var now = new Date().getTime(),
        secondsDelay, dir, multiplier, offset;

    if (!this.lastMovementUpdate) {
        this.lastMovementUpdate = now;
    }

    if (!this.inputDisabled && this.directionsPressed.length) {
        if (!this.transitioning) {
            this.transitioning = true;
        }

        dir = this.directionsPressed[this.directionsPressed.length - 1];
        secondsDelay = (now - this.lastMovementUpdate) / 1000;
        multiplier = MOVING_DIRS[dir];
        offset = {
            x: multiplier[0] * secondsDelay * SPEED_PER_SECOND,
            y: multiplier[1] * secondsDelay * SPEED_PER_SECOND
        };

        this.player.setPosition(this.player.x += offset.x, this.player.y + offset.y);
        this.player.turn(dir);

        this.player.emit('move', this.player, offset, true);
        this.movingDirection = dir;
    }

    this.lastMovementUpdate = now;
}

/*
 * Get input element if available
 *
 * @param {String=} state
 * @return {HTMLInput|null}
 */
function getInput(state = null) {
    let pseudo = state ? `:${state}` : '';

    if (!this.$el) { return null;   }

    return this.$el.querySelector(`input${pseudo}`) || null;
}