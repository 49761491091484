import Vue from 'vue';
import Level from '../class/Level';
import generate from '../util/generate';
import challenges from '../challenges/index';
import focus from '../ui/focus';
import bubble from '../ui/bubble';
import modal from '../ui/modal';
import requireClick from '../ui/require-click';
import unlockedChallenges from '../core/unlocked-challenges';
import error from '../core/error';

/*
 * Editor challenge component
 *
 * Display component to play a challenge for learning to use the editor
 */

Vue.component('editor-challenge', {
    data            : () => {
        return {
            challenge : null,
            level     : null,
            step      : 0,
            hide      : {}
        };
    },
    template        : require('../../views/component/editor-challenge.jade'),
    isFn            : true,
    replace         : true,
    props           : [
        'challenge'
    ],
    methods         : {
        nextStep,
        done,
        clear
    },
    beforeDestroy,
    created
});

/*
 * Initialise component
 */
function created() {
    this.level = this.challenge.level ? new Level(this.challenge.level) : generate.empty();
    this.hide = this.challenge.hide;
    setTimeout(this.nextStep.bind(this));
}

/*
 * Clear up any UI forced bindings before destroying, if any
 */
function beforeDestroy() {
    this.clear();
}

/*
 * Run next step of challenge
 */
function nextStep() {
    var next = this.challenge.steps[this.step++];

    if (!next) {
        return this.done();
    }

    next.call(this, nextStep.bind(this));
}

/*
 * Show congratulations modal once challenge is completed
 */
function done() {
    var nextChallenge = challenges.getNext(this.challenge),
        index = challenges.indexOf(this.challenge);

    clear();

    modal.open('challenge-finished', { next: nextChallenge });

    // Get current unlocked challenges value
    unlockedChallenges.get()
    .then((unlocked) => {
        // Set new unlocked challenges value if higher
        if (index + 1 > unlocked) {
            return unlockedChallenges.set(index + 1);
        }
    })
    .then(() => {
        // Do nothing..
    }, error.handle)
    .catch(error.handle);
}

/*
 * Close everything that may have been left open by the sequence
 */
function clear() {
    focus.off();
    bubble.close();
    requireClick.off();
}