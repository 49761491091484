import EventEmitter from 'tiny-emitter';
import ItemInstance from './ItemInstance';

/*
 * Inventory class
 *
 * In-game inventory interface
 */

export default class Inventory extends EventEmitter {

    /*
     * Inventory constructor
     */
    constructor() {
        super();
        this.items = [];
    }

    /*
     * Add item to inventory
     *
     * @param {ItemInstance} item
     */
    add(item) {
        this.emit('add', item);
        this.items.push(item);
    }

    /*
     * Get count of available items of given id
     *
     * @param {String} itemId
     * @return {Number}
     */
    getCount(itemId) {
        var count = 0,
            item;

        for (item of this.items) {
            if (item.id === itemId) {
                count++;
            }
        }

        return count;
    }

    /*
     * Get single item entity by id
     *
     * @param {String} itemId
     * @return {ItemEntity|void}
     */
    getItem(itemId) {
        var item;

        for (item of this.items) {
            if (item.id === itemId) {
                return item;
            }
        }

        return null;
    }

    /*
     * Return list of item types in the inventory containing IDs, counts and
     * state tiles for preview taken from level item presets
     *
     * @param {Level} level
     * @return {[Object]}
     */
    getList(level) {
        var list = {},
            item;

        for (item of this.items) {
            if (!list[item.id]) { list[item.id] = 0; }
            list[item.id]++;
        }

        return Object.keys(list).map(function (id) {
            var props = level.items[id],
                tile = props.states.default.tile,
                count = list[id];

            return { id, count, tile, props };
        });
    }

    /*
     * Returns true if item is present in inventory in given amount
     *
     * @param {String} itemId
     * @param {Number=} amount
     * @return {Boolean}
     */
    hasItem(itemId, amount = 1) {
        return this.getCount(itemId) >= amount;
    }

    /*
     * Remove items by id in given quantity
     *
     * @param {String} itemId
     * @param {Number=} amount
     */
    removeItem(itemId, amount = 1) {
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].id === itemId) {

                if (this.items.$remove) {
                    this.items.$remove(i);
                } else {
                    this.items.splice(i, 0);
                }

                i--;
                amount--;
                if (!amount) { return; }
            }
        }
    }

    /*
     * Restore item instances from options
     *
     * @param {[Object]} items
     */
    restore(game, options) {
        console.log(options);
        options.items.forEach((item) => {
            item.game = game;
            this.items.push(new ItemInstance(item));
        });
    }

    /*
     * Export restorable properties
     *
     * @return {Object}
     */
    export() {
        var items = this.items.map((item) => {
            return item.export();
        });

        return { items };
    }

}