/*
 * Number utility module
 *
 * Small utility module exporting methods to work with objects and namespaces
 */

/*
 * Zeropad Number to String with given amount of digits
 *
 * @param {Number} value
 * @param {Number=} length
 * @return {String}
 */
exports.zeroPad = function (value, length = 2) {
    var str = String(value);

    while (str.length < length) {
        str = '0' + str;
    }

    return str;
};