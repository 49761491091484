import api from '../core/api';
import error from '../core/error';
import modal from '../ui/modal';
import MapRenderer from '../class/MapRenderer';

/*
 * Feedback modal
 *
 * Modal to send feedback
 */

var PREVIEW_SIZE = 600,
    data = {
        slug           : null,
        name           : null,
        level          : null,
        previews       : [],
        previewLoading : true,
        previewsCount  : 0
    },
    methods = {
        updatePreview,
        generatePreview,
        getPreviewBlob,
        save
    };

/*
 * Initialise modal
 */
function run() {
    this.previewsCount = this.level.maps.length === 1 ? 1 : this.level.maps.length + 1;

    if (!this.level.previewIndex) {
        this.level.previewIndex = 0;
    }

    this.$watch('level.previewIndex', updatePreview);
    this.updatePreview();
}

/*
 * Update current preview
 */
function updatePreview() {
    if (this.previews[this.level.previewIndex]) {
        this.previewLoading = false;
    } else {
        this.generatePreview(this.level.previewIndex);
    }
}

/*
 * Generate preview of given index
 *
 * @param {Number=} index
 */
function generatePreview(index = 0) {
    var modal = this,
        renderer;

    this.previewLoading = true;

    if (index === 0) {
        this.level.generatePreview(generated, PREVIEW_SIZE);
    } else {
        renderer = new MapRenderer(this.level.maps[index - 1], { size: PREVIEW_SIZE });
        renderer.render(generated);
    }

    function generated(err, preview) {
        if (err) { return error.handle(err); }

        preview.url = preview.canvas.toDataURL();
        modal.previews[index] = preview;
        modal.previewLoading = false;
    }
}

/*
 * Generate current preview image blob
 *
 * @param {Function} callback
 */
function getPreviewBlob(callback) {
    this.previews[this.level.previewIndex].canvas.toBlob(callback);
}

/*
 * Save level
 *
 * @param {SubmitEvent=} e
 */
function save(e) {
    if (e) { e.preventDefault(); }

    if (!this.level.name) {
        return modal.open('alert', { text: 'Invalid name' });
    }

    this.getPreviewBlob((preview) => {
        this.level.version++;

        var levelData = this.level.export(),
            file = new Blob([ JSON.stringify(levelData) ], { type : 'application/json' });

        api.levels.save({
            id    : this.level.id,
            name  : this.level.name,
            files : { file, preview }
        }).then((res) => {
            this.level.id = res.body.level.id;
            this.done(true);
        }, (res) => {
            modal.open('alert', { text: 'Error: ' + res.body });
        })
        .catch(error.handle);
    });
}

export default { data, methods, run };