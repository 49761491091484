import Vue from 'vue';
import config from '../config';

/*
 * Assisted Drawing Highlighter component
 *
 * Renders a maps layer on given area for assisted drawing
 */

const STROKE_SIZE = 2,
    DASH_SIZE = 3;

Vue.component('assisted-drawing-highlighter', {
    data     : () => {
        return {
            assisted : null,
            show     : false
        };
    },
    template : require('../../views/component/assisted-drawing-highlighter.jade'),
    isFn     : true,
    replace  : true,
    props    : [ 'assisted' ],
    methods  : {
        render,
        renderCell,
        renderPositiveCell,
        renderNegativeCell,
        renderBoundary
    },
    ready    : ready
});

function ready() {
    this.mask = { canvas: this.$el.querySelector('.mask') };
    this.lines = { canvas: this.$el.querySelector('.lines') };
    this.mask.ctx = this.mask.canvas.getContext('2d');
    this.lines.ctx = this.lines.canvas.getContext('2d');

    this.render();

    this.$watch('assisted', this.render);

    // Next tick..
    setTimeout(() => {
        this.show = true;
    });
}

function render() {
    var area = this.assisted.area,
        x, y;

    this.ratio = config.PIXEL_RATIO;
    this.width = this.$el.offsetWidth * this.ratio;
    this.height = this.$el.offsetHeight * this.ratio;

    this.mask.ctx.width = this.mask.canvas.width = this.lines.ctx.width = this.lines.canvas.width = this.width;
    this.height = this.mask.ctx.height = this.mask.canvas.height = this.lines.ctx.height = this.lines.canvas.height = this.height;
    this.cellSize = this.width / config.MAP_SIZE[0];
    this.strokeSize = STROKE_SIZE * this.ratio;

    this.lines.ctx.strokeStyle = 'white';
    this.lines.ctx.lineWidth = this.strokeSize;
    this.lines.ctx.setLineDash([ DASH_SIZE * this.ratio, DASH_SIZE * this.ratio ]);

    this.mask.ctx.fillStyle = 'black';

    for (y = 0; y < area.length; y++) {
        for (x = 0; x < area[y].length; x++) {
            this.renderCell(x, y);
        }
    }
}

function renderCell(x, y) {
    var area = this.assisted.area,
        cell = area[y][x];

    if (cell) {
        this.renderPositiveCell(x, y);
    } else {
        this.renderNegativeCell(x, y);
    }
}

function renderPositiveCell(x, y) {
    var adjacents = this.assisted.getAdjecents(x, y);

    if (!adjacents.left) {
        this.renderBoundary(x, y, 0, 1);
    }

    if (!adjacents.top) {
        this.renderBoundary(x, y, 1, 0);
    }

    if (!adjacents.bottom) {
        this.renderBoundary(x, y + 1, 1, 0);
    }

    if (!adjacents.right) {
        this.renderBoundary(x + 1, y, 0, 1);
    }
}

function renderBoundary(x, y, offsetX, offsetY) {
    this.lines.ctx.beginPath();

    this.lines.ctx.moveTo(
        this.cellSize * x,
        this.cellSize * y
        );

    this.lines.ctx.lineTo(
        this.cellSize * x + this.cellSize * offsetX,
        this.cellSize * y + this.cellSize * offsetY
        );

    this.lines.ctx.stroke();
}

function renderNegativeCell(x, y) {
    this.mask.ctx.fillRect(this.cellSize * x, this.cellSize * y, this.cellSize, this.cellSize);
}