import compression from '../util/compression';
import saveSlots from '../util/save-slots';
import Level from './Level';
import Player from './Player';
import Inventory from './Inventory';

/*
 * GameState class
 *
 * Base class for GameState storage / exporting
 */

export default class GameState {

    /*
     * GameState constructor
     *
     * @param {GameApi} game
     */
    constructor(game) {
        this.game = game;
    }

    /*
     * Export restorable game state
     *
     * @return {Object}
     */
    export() {
        var meta = {
                date    : new Date(),
                version : this.game.level.version,
                levelId : this.game.level.id
            },
            data = compression.compress({
                mapIndex  : this.game.level.maps.indexOf(this.game.map),
                level     : this.game.level.export(true),
                player    : this.game.player.export(),
                inventory : this.game.inventory.export(),
                controls  : this.game.controls.export(),
                api       : { state: this.game.api.state },
                version   : this.game.level.version
            }),
            file = new Blob([ data ]);

        return { meta, file };
    }

    /*
     * Export restorable game maps properties
     *
     * @return {[Object]}
     */
    exportMaps() {
        return this.level.maps.map((map) => {
            return map.export(true);
        });
    }

    /*
     * Decompress and restore state string
     *
     * @param {String}
     */
    load(save) {
        save.state = saveSlots.parse(save.data);
        this.restore(save.state);
    }

    /*
     * Restore game state from Object
     *
     * @param {Object} state
     */
    restore(state) {
        var map;

        this.game.player = new Player(state.player);
        this.game.level = new Level(state.level);
        this.game.inventory = new Inventory(state.inventory);
        this.game.api.state = state.api.state;
        this.game.controls.keys = state.controls.keys;

        this.game.level.maps.forEach((map) => {
            map.restoreInstances(this.game);
        });

        this.game.inventory.restore(this.game, state.inventory);

        map = this.game.level.maps[state.mapIndex];

        this.game.enterMap(map, state.player, false);
    }

}