module.exports=[
    {
        "label"    : "Movement",
        "commands" : [
            {
                "call"        : "move",
                "description" : "Move by given X and Y distances",
                "example"     : "move(3, -2)",
                "args"        : [
                    [ "distance_x", "number", "Distance to the right (Negative will move left)", true ],
                    [ "distance_y", "number", "Distance downwards (Negative will move upwards)", false ]
                ],
                "defaults": [ 1 ]
            },
            {
                "call"        : "moveUp",
                "description" : "Move up a given distance",
                "example"     : "moveUp(5)",
                "args"        : [
                    [ "distance", "number", "Distance in tiles", false ]
                ],
                "defaults": [ 1 ]
            },
            {
                "call"        : "moveRight",
                "description" : "Move right a given distance",
                "example"     : "moveRight(5)",
                "args"        : [
                    [ "distance", "number", "Distance in tiles", false ]
                ],
                "defaults": [ 1 ]
            },
            {
                "call"        : "moveDown",
                "description" : "Move down a given distance",
                "example"     : "moveDown(5)",
                "args"        : [
                    [ "distance", "number", "Distance in tiles", false ]
                ],
                "defaults": [ 1 ]
            },
            {
                "call"        : "moveLeft",
                "description" : "Move left a given distance",
                "example"     : "moveLeft(5)",
                "args"        : [
                    [ "distance", "number", "Distance in tiles", false ]
                ],
                "defaults": [ 1 ]
            }
        ]
    },
    {
        "label"    : "Facing",
        "commands" : [
            {
                "call"        : "turn",
                "description" : "Turn facing in to a direction",
                "example"     : "turn('right')",
                "args"        : [
                    [ "direction", "string", "Direction to turn ('top', 'right', 'bottom' or 'left')", true ]
                ],
                "defaults": [ null ]
            },
            {
                "call"        : "turnUp",
                "description" : "Turn facing upwards",
                "example"     : "turnUp()",
                "args"        : [
                ],
                "defaults": []
            },
            {
                "call"        : "turnRight",
                "description" : "Turn facing right",
                "example"     : "turnRight()",
                "args"        : [
                ],
                "defaults": []
            },
            {
                "call"        : "turnDown",
                "description" : "Turn facing downwards",
                "example"     : "turnDown()",
                "args"        : [
                ],
                "defaults": []
            },
            {
                "call"        : "turnLeft",
                "description" : "Turn facing left",
                "example"     : "turnLeft()",
                "args"        : [
                ],
                "defaults": []
            }
        ]
    },
    {
        "label"    : "Interaction",
        "commands" : [
            {
                "call"        : "observe",
                "description" : "Get a description of the current map",
                "example"     : "observe()",
                "args"        : [
                ],
                "defaults": []
            },
            {
                "call"        : "look",
                "description" : "Look at an item directly in front of you",
                "example"     : "look()",
                "args"        : [
                ],
                "defaults": []
            },
            {
                "call"        : "describe",
                "description" : "Get a description of an item directly in front of you",
                "example"     : "describe()",
                "args"        : [
                ],
                "defaults": []
            },
            {
                "call"        : "talk",
                "description" : "Start a conversation with someone / something directly in front of you",
                "example"     : "talk()",
                "args"        : [
                ],
                "defaults": []
            },
            {
                "call"        : "use",
                "description" : "Use an item directly in front of you",
                "example"     : "use()",
                "args"        : [
                ],
                "defaults": []
            }
        ]
    },
    {
        "label"    : "Communication",
        "commands" : [
            {
                "call"        : "say",
                "description" : "Say something",
                "example"     : "say('Hello!')",
                "args"        : [
                    [ "message", "string", "Message to say", true ]
                ],
                "defaults": [ null ]
            },
            {
                "call"        : "shout",
                "description" : "Shout something out loud",
                "example"     : "shout('this will be shouted!!!')",
                "args"        : [
                    [ "message", "string", "Message to shout", true ]
                ],
                "defaults": [ null ]
            }
        ]
    }
]