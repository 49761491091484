import Vue from 'vue';
import clone from 'deepcopy';
import request from 'request';
import extend from 'deep-extend';
import domUtil from '../util/dom';
import objectUtil from '../util/object';
import error from '../core/error';
import modals from '../modal/index';
import Q from 'q';

/*
 * Modal UI module
 *
 * Handles opening, initialising, rendering and closing of modals
 */

var TRANSITION_MS = 200,
    openModals = [],
    initialised = false;

/*
 * Load a template for given modal type
 *
 * @param {String} type
 * @param {Function} callback
 */
function loadTemplate(type, callback) {
    let url = location.origin + '/modal/' + type + '.html';

    request.get(url, function (err, res, body) {
        if (err) {
            return callback(err);
        } else if (res.statusCode !== 200) {
            return callback(new Error('Error ' + res.statusCode + ' loading template'));
        }

        callback(null, body);
    });
}

/*
 * Bind DOM events if not initialised yet
 */
function init() {
    if (initialised) { return; }

    initialised = true;

    // window.addEventListener('keydown', (e) => {
    //     let key = keycode(e.keyCode);

    //     if (key === 'esc' && openModals.length) {
    //         close();
    //     }
    // });
}

/*
 * Open modal of given type
 *
 * @param {String=} type
 * @param {Object=} data
 * @param {Function=} callback
 */
function open(type = 'alert', data = {}, callback = null) {
    let options = modals[type],
        deferred = Q.defer(),
        modal = { type, callback, deferred },
        methods, ready, beforeDestroy, scope;

    init();

    if (!options) {
        return error.handle(new Error('Modal not found: ' + type));
    }

    // Add modal to open modals list
    openModals.push(modal);

    // If no controller is find, replace it with an empty Object
    options.controller = options.controller || {};

    // Prepare VM options
    methods = clone(options.controller.methods || {});
    scope = extend({ hide: false }, options.controller.data);
    scope = objectUtil.extend(scope, data);
    ready = options.controller.run || null;
    beforeDestroy = options.controller.destroy || null;

    // Add `done` method to close modal with a response
    methods.done = (response) => {
        this.closeModal(modal, response);
    };

    // Load modal template
    loadTemplate(options.template || type, (err, template) => {
        if (err) {
            deferred.reject(err);
            return error.handle(err);
        }

        let offscreen, el;

        // Add `modal-open` class to body
        domUtil.addClass(document.body, 'modal-open');

        // Render modal element offscreen from given template
        offscreen = document.createElement('div');
        offscreen.innerHTML = template;
        modal.el = el = offscreen.children[0];

        // Add element on screen
        document.body.appendChild(el);

        // Initialise VM on modal element
        modal.vm = new Vue({ el, data: scope, methods, ready, beforeDestroy });

        // Hide / Show modal on hide value change
        modal.vm.$watch('hide', (hide) => {
            domUtil.toggleClass(modal.el, 'hide', hide);
        });
    });

    return deferred.promise;
}

/*
 * Close given modal with response (If given)
 *
 * @param {Object} modal
 * @param {*=} response
 */
function closeModal(modal, response = null) {
    let index = openModals.indexOf(modal);

    if (index === -1) { return error.handle(new Error('Modal not found')); }

    domUtil.addClass(modal.el, 'closing');

    setTimeout(() => {
        openModals.splice(index, 1);
        document.body.removeChild(modal.el);
        modal.vm.$destroy();

        modal.deferred.resolve(response);

        if (modal.callback) {
            modal.callback(response);
        }

        if (!openModals.length) {
            domUtil.removeClass(document.body, 'modal-open');
        }
    }, TRANSITION_MS);
}

/*
 * Close all open modals
 */
function close() {
    for (let modal of openModals) {
        closeModal(modal);
    }
}

export default { init, open, close, closeModal };