var Vue = require('vue'),
    marked = require('marked');

/*
 * Markdown filters
 *
 * Markdown rendering filters
 */

/*
 * Render markdown with Marked
 *
 * @param {String} str
 * @return {String}
 */
Vue.filter('markdown', function (str) {
    return marked(str);
});