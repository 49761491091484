import Q from 'q';

/*
 * Assisted Drawing class
 *
 * Wrapping class for assisted drawing execution - Provides method that
 * facilitate the behaviour of other components when performing assisted
 * drawing
 */

export default class AssistedDrawing {

    /*
     * Instance constructor
     *
     * @param {LevelEditor} editor
     * @param {[String]} area
     * @param {Object=} tilesObj
     */
    constructor(editor, area, tilesObj = {}, layer = null) {
        this.editor = editor;
        this.buildAreaMap(area);
        this.generateAllowedTiles(tilesObj);
        this.deferred = Q.defer();
        this.layer = layer;

        this._onchange = this.onchange.bind(this);
        this.editor.map.on('change', this._onchange);

        this.editor.layersLocked = true;

        this.then = this.deferred.promise.then.bind(this.deferred.promise);
        this.catch = this.deferred.promise.catch.bind(this.deferred.promise);
    }

    /*
     * Update changed tiles on map change, check for challenge completion and
     * resolve if over
     *
     * @param {Number} l
     * @param {Number} x
     * @param {Number} y
     */
    onchange(l, x, y) {
        this.changed[`${y},${x}`] = true;

        if (this.isComplete()) {
            this.editor.layersLocked = false;
            this.editor.map.off('change', this._onchange);
            this.deferred.resolve();
        }
    }

    /*
     * Returns true if all tiles that had to be changed have been changed
     */
    isComplete() {
        var key;

        for (key in this.changed) {
            if (this.changed.hasOwnProperty(key)) {
                if (!this.changed[key]) { return false; }
            }
        }

        return true;
    }

    /*
     * Build grid of cell Booleans from Array of Strings representing map area
     *
     * @param {[String]}
     */
    buildAreaMap(area) {
        this.changed = {};

        this.area = area.map((row, y) => {
            return row.split('').map((cell, x) => {
                if (cell === 'x') {
                    this.changed[`${y},${x}`] = false;
                    return true;
                }

                return false;
            });
        });
    }

    /*
     * Get Object containing a Boolean set to true for each side having a cell
     *
     * @param {Number} x
     * @param {Number} y
     * @return {Object}
     */
    getAdjecents(x, y) {
        return {
            top    : (this.area[y - 1] ? this.area[y - 1][x] : false) || false,
            right  : this.area[y][x + 1] || false,
            bottom : (this.area[y + 1] ? this.area[y + 1][x] : false) || false,
            left   : this.area[y][x - 1] || false
        };
    }

    /*
     * Generate Array of allowed tiles from input format (In which each key of
     * the Object represents the tile set and the Array of Strings inside the
     * key of allowed tiles for that set)
     *
     * @param {Object=} tilesObj
     * @return {[String]}
     */
    generateAllowedTiles(tilesObj = {}) {
        this.tiles = [];

        if (!tilesObj) { return; }

        Object.keys(tilesObj).forEach((set) => {
            tilesObj[set].forEach((key) => {
                this.tiles.push(`${key}:${set}`);
            });
        });
    }

    /*
     * Return true if tile of given key is in allowed list
     *
     * @param {String} key
     * @return {Boolean}
     */
    tileIsAllowed(key) {
        return this.tiles.indexOf(key) !== -1;
    }

    /*
     * Given cell position is in assisted drawing allowed area
     *
     * @param {Number} x
     * @param {Number} y
     * @return {Boolean}
     */
    isInArea(x, y) {
        return this.area[y] && this.area[y][x];
    }

}