import clone from 'deepcopy';
import config from '../config';
import Level from '../class/Level';
import behaviours from '../editor/behaviours/index';
import items from '../editor/items';

/*
 * Generate utility module
 *
 * A small module containing utilities to generate dummy map data
 */

/*
 * Create empty new level Object
 *
 * @return {Object}
 */
function empty() {
    return new Level({
        maps       : [ map() ],
        behaviours : clone(behaviours),
        items      : clone(items)
    });
}

/*
 * Generate empty map data
 *
 * @return {Object}
 */
function map() {
    return {
        layers   : [
            fillLayer([], [ 1, 0, config.DEFAULT_TILESET ]),
            fillLayer([], null),
            fillLayer([], null),
            fillLayer([], null)
        ],
        entities   : [],
        behaviours : []
    };
}

/*
 * Fill layer with min. number of tiles of given type
 *
 * @param {[Array]} tiles
 * @param {[Array]=} type
 */
function fillLayer(tiles, preset = null) {
    preset = preset;

    var x, y;

    for (y = 0; y < config.MAP_SIZE[1]; y++) {
        if (!tiles[y]) { tiles[y] = []; }

        for (x = 0; x < config.MAP_SIZE[0]; x++) {
            if (!tiles[y][x]) {
                tiles[y][x] = preset ? [ preset[0], preset[1], preset[2] ] : null;
            }
        }
    }

    return tiles;
}

export default { empty, map };