/*
 * Tutorial module
 *
 * Exports methods to help explain the UI
 */

const FADE_DURATION = 500;

/*
 * Temporary highglights element of given selector
 *
 * @param {String} selector
 * @param {Number} duration
 */
exports.highlightElement = function (selector, duration = 6000) {
    var el = document.querySelector(selector),
        pointer;

    if (!el) { return; }

    pointer = document.createElement('div');
    pointer.className = 'highlight-pointer';

    el.appendChild(pointer);

    setTimeout(() => {
        pointer.className = 'highlight-pointer show';

        setTimeout(() => {
            pointer.className = 'highlight-pointer';

            setTimeout(() => {
                el.removeChild(pointer);
            }, FADE_DURATION);
        }, duration);
    });
};

/*
 * Enable terminal input
 *
 * @param {Boolean=} focus
 */
exports.enableInput = function (focus = true) {
    this.game.inputDisabled = false;

    if (focus) {
        this.game.promptFocus();
    }
};

/*
 * Disable terminal input
 */
exports.disableInput = function () {
    this.game.transitioning = false;
    this.game.inputDisabled = true;
};