/*
 * Default Tilesets
 *
 * Exports level's default tilesets
 */

var sets = {
    wood       : { label: 'Woods', adaptive: 11 },
    overworld  : { label: 'Overworld', adaptive: 17 },
    desert     : { label: 'Desert', adaptive: 7 },
    limbo      : { label: 'Limbo', adaptive: 3 },
    items      : { label: 'Items', adaptive: 1 },
    camp       : { label: 'Camp' },
    characters : { label: 'Characters' },
    npcs       : { label: 'NPCs' },
    portals    : { label: 'Portals' },
    logic      : { label: 'Logic', hidden: true }
};

export default {

    get(id) {
        return sets[id] || null;
    },

    getOptions() {
        var out = [],
            i = 0,
            key;

        for (key in sets) {
            if (sets.hasOwnProperty(key) && !sets[key].hidden) {
                out.push({
                    value   : key,
                    text    : sets[key].label,
                    default : i === 0
                });
            }

            i++;
        }

        return out;
    }

};