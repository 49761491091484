import template from '../../views/component/bubble.jade';
import domUtil from '../util/dom';
import Vue from 'vue';
import Q from 'q';

/*
 * Bubble UI module
 *
 * Create and display a single bubble element at the time which holding
 * wrapper can be placed over an element by given selector
 */

var TRANSITION_TIME = 200,
    holder, vm, deferred, targetSelector;

/*
 * Open bubble with given options (Target, type, direction, text, button, ..)
 *
 * @param {Object} options
 * @return {Promise}
 */
function open(options = {}) {
    var methods = { done: () => { done(); } };

    targetSelector = options.target || null;

    if (holder) { close(); }

    deferred = Q.defer();

    holder = document.createElement('div');
    holder.className = 'bubble-holder';
    document.body.appendChild(holder);

    holder.innerHTML = template;

    vm = new Vue({ el: holder, data: options, methods });

    document.body.appendChild(holder);

    window.addEventListener('resize', update);
    window.addEventListener('scroll', update);
    update();

    setTimeout( () => {
        if (!holder) { return; }
        domUtil.addClass(holder, 'show');
    });

    return deferred.promise;
}

/*
 * Get currently open bubble's VM
 *
 * @return {Vue|null}
 */
function getVm() {
    return vm || null;
}

/*
 * Update modal position depending on targets (If found)
 */
function update() {
    var target = document.querySelector(targetSelector),
        rect;

    if (!targetSelector) {
        holder.style.left = null;
        holder.style.top = null;
        return;
    }

    rect = target.getBoundingClientRect();

    holder.style.left = rect.left + 'px';
    holder.style.top = rect.top + 'px';
    holder.style.width = rect.width + 'px';
    holder.style.height = rect.height + 'px';
}

/*
 * Resolve current bubble's promise
 */
function done() {
    if (!deferred) { return; }
    deferred.resolve();
    deferred = null;
    close();
}

/*
 * Close currently open bubble
 */
function close() {
    var oldHolder = holder,
        oldVm = vm;

    holder = null;
    vm = null;

    if (!oldHolder) { return; }

    domUtil.removeClass(oldHolder, 'show');

    setTimeout(() => {
        if (!oldHolder) { return; }
        document.body.removeChild(oldHolder);
        oldVm.$destroy();
    }, TRANSITION_TIME);

    window.removeEventListener('resize', update);
    window.removeEventListener('scroll', update);

    if (!deferred) { return; }
    deferred.reject();
    deferred = null;
}

export default { open, close, getVm };