import async from 'async';
import MapRenderer from '../class/MapRenderer';
import api from '../core/api';
import error from '../core/error';
import clustersUtil from './map-clusters';

/*
 * Maps Grid utility module
 *
 * Containsmethods for serverside packing solution and rendering of maps grids
 */

/*
 * Solve maps packing on the API and render
 *
 * @param {[Map]} maps
 * @param {Number} width
 * @param {Number} height
 * @param {Function} callback
 */
function solveAndRender(maps, width, height, callback) {
    solve(maps, (err, grid) => {
        if (err) { return callback(err); }

        render(grid, width, height, callback);
    });
}

/*
 * Render packed solution
 *
 * @param {[Map]}
 * @param {Function} callback
 */
function solve(maps, callback) {
    var grids, refs;

    grids = clustersUtil.formClusters(maps)
    .sort(function (a, b) {
        return a.length > b.length ? -1 : 1;
    })
    .map((cluster) => {
        return clustersUtil.formGrid(cluster);
    });

    refs = replaceWithRefs(grids);

    api.packer.solve({ grids: grids })
    .then((res) => {
        callback(null, rebuildFromRefs(res.body.solution, refs));
    }, (res) => {
        callback(new Error(res.body));
    })
    .catch(error.handle);
}

function rebuildFromRefs(solution, refs) {
    var y, x, row, ref;

    for (y = 0; y < solution.length; y++) {
        row = solution[y];

        for (x = 0; x < row.length; x++) {
            ref = row[x];

            if (typeof ref === 'number') {
                row[x] = refs[ref];
            }
        }
    }

    return solution;
}

function replaceWithRefs(grids) {
    var references = [],
        i, x, y, grid, row, map;

    for (i = 0; i < grids.length; i++) {
        grid = grids[i];

        for (y = 0; y < grid.length; y++) {
            row = grid[y];

            for (x = 0; x < row.length; x++) {
                map = row[x];

                if (map) {
                    references.push(map);
                    row[x] = references.length - 1;
                }
            }
        }

    }

    return references;
}

/*
 * Render packed solution
 *
 * @param {[Map]}
 */
function render(grid, width, height, callback) {
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),
        mapSize = Math.min(width / grid[0].length, height / grid.length),
        tasks = [],
        simplify = grid.length * grid[0].length > 3,
        absSize = {
            width  : mapSize * grid[0].length,
            height : mapSize * grid.length
        },
        padding = {
            x : (width - absSize.width) / 2,
            y : (height - absSize.height) / 2
        },
        x, y, map, renderer;

    // Disable smoothing
    ctx.imageSmoothingEnabled       = false;
    ctx.mozImageSmoothingEnabled    = false;
    ctx.msImageSmoothingEnabled     = false;
    ctx.oImageSmoothingEnabled      = false;

    canvas.width = ctx.width = width;
    canvas.height = ctx.height = height;

    for (y = 0; y < grid.length; y++) {
        for (x = 0; x < grid[y].length; x++) {
            map = grid[y][x];

            if (!map) { continue; }

            renderer = new MapRenderer(map, { size: mapSize, simplify });

            tasks.push({ renderer, x, y });
        }
    }

    async.map(tasks, (task, callback) => {
        task.renderer.render((err, render) => {
            if (err) { return callback(err); }

            ctx.drawImage(
                render.canvas,
                task.x * mapSize + padding.x,
                task.y * mapSize + padding.y,
                mapSize,
                mapSize
                );

            callback();
        });
    }, function (err) {
        if (err) { return callback(err); }

        callback(null, { canvas, ctx });
    });
}

export default { solve, render, solveAndRender };