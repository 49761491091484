import Movable from './Movable';
import config from '../config';

/*
 * Player class
 *
 * In-game player interface
 */

const SPEECH_BUBBLE_DURATION = 3000,
    ANIMATIONS = {
        'pop-vanish' : 1000,
        'jumps'      : 1000,
        'shake'      : 1000,
        'damage'     : 1000
    },
    MAX_HEALTH = 10,
    MAX_MANA = 10;

export default class Player extends Movable {

    /*
     * Player constructor
     *
     * @param {Object=} options
     */
    constructor(options = {}) {
        super();
        this.x = options.x || 0;
        this.y = options.y || 0;
        this.tile = { x: this.x, y: this.y };
        this._speech = { text: null, show: false, cname: null };
        this.turn(options.direction || 'down');
        this.animation = null;
        this.visible = true;
        this.stats = {
            health : { value: MAX_HEALTH, max: MAX_HEALTH },
            mana   : { value: MAX_MANA, max: MAX_MANA }
        };
    }

    resetStats() {
        this.stats.health.value = MAX_HEALTH;
        this.stats.health.mana = MAX_MANA;
    }

    /*
     * Snape precisely to tile position
     */
    snapPosition() {
        this.x = this.tile.x;
        this.y = this.tile.y;
    }

    /*
     * Set position to given X and Y
     *
     * @param {Number} x
     * @param {Number} y
     */
    setPosition(x, y) {
        this.x = x;
        this.y = y;

        this.updateTile();
    }

    /*
     * Extend `step` method to trigger tile update
     */
    step() {
        super.step();
        this.updateTile();
    }

    /*
     * Update current tile, emit event if different
     */
    updateTile() {
        var tile = {
                x : Math.floor(this.x + 0.5),
                y : Math.floor(this.y + 0.5)
            };

        if (this.tile.x !== tile.x || this.tile.y !== tile.y) {
            this.tile = tile;
            this.emit('change-tile', this.tile.x, this.tile.y);
        }
    }

    /*
     * Turn in given direction
     *
     * @param {String} dir
     */
    turn(dir) {
        super.turn(dir);
        this.pose = this.direction;
    }

    /*
     * Display player speech bubble for a short time span
     *
     * @param {String} text
     * @param {String=} cname
     */
    speech(text, cname) {
        if (this._speechTimer) {
            clearTimeout(this._speechTimer);
        }

        this._speech.text = text;
        this._speech.show = true;
        this._speech.cname = cname;

        this._speechTimer = setTimeout(() => {
            this._speech.show = false;
        }, SPEECH_BUBBLE_DURATION);
    }

    /*
     * Returns true if player position is out of map's boundaries
     *
     * @return {Boolean}
     */
    isOutOfBoundaries() {
        return (
            this.x < 0 ||
            this.y < 0 ||
            this.x + 0.9 >= config.MAP_SIZE[0] ||
            this.y + 0.9 >= config.MAP_SIZE[1]
            );
    }

    /*
     * Execute animation on character and fire callback when done
     *
     * @param {String=} animation
     * @param {function=} callback 
     */
    animate(animation = 'pop-vanish', callback = null) {
        var duration = ANIMATIONS[animation];

        if (!duration) { return callback ? callback() : null; }

        this.animation = animation;

        setTimeout(() => {
            this.animation = null;
            if (callback) { callback(); }
        }, duration);
    }

    /*
     * Kill character
     *
     * @param {String=} message
     * @param {String=} animation
     */
    die(message = 'You\'re dead..', animation = 'pop-vanish') {
        this.emit('game-end', false, message, animation);
    }

    /*
     * Called on stats value change
     *
     * @param {String} key
     * @param {Number} previousValue
     * @param {Number} value
     */
    statsChange(key, previousValue, value) {
        this.emit('stat-change', key, previousValue, value);

        if (this.stats.health.value === 0) {
            this.emit('death');
            this.die();
        }
    }

    /*
     * Get / set stat by given key
     *
     * @param {String} key
     * @param {Number=} value
     * @return {Number}
     */
    getSetStat(key = 'health', value = null) {
        if (!this.stats[key]) { return; }

        if (typeof value === 'number') {
            let previousValue = this.stats[key].value;

            if (value < 0) {
                value = 0;
            } else if (value > this.stats[key].max) {
                value = this.stats[key].max;
            }

            if (value === this.stats[key].value) {
                return;
            }

            this.stats[key].value = value;
            this.statsChange(key, previousValue, value);
        }

        return this.stats[key].value;
    }

    /*
     * Get / set health
     *
     * @param {String} key
     * @param {Number=} value
     * @return {Number}
     */
    health(value = null) {
        return this.getSetStat('health', value);
    }

    /*
     * Decrease health by given amount
     *
     * @param {Number} amt
     */
    damage(amt) {
        this.health(this.health() - (amt || 1));
    }

    /*
     * Increase health by given amount
     *
     * @param {Number} amt
     */
    heal(amt) {
        this.health(this.health() + (amt || 1));
    }

    /*
     * Get / set mana
     *
     * @param {String} key
     * @param {Number=} value
     * @return {Number}
     */
    mana(value = null) {
        return this.getSetStat('mana', value);
    }

    /*
     * Decrease mana by given amount
     *
     * @param {Number} amt
     */
    useMana(amt) {
        this.mana(this.mana() - (amt || 1));
    }

    /*
     * Increase mana by given amount
     *
     * @param {Number} amt
     */
    gainMana(amt) {
        this.mana(this.mana() + (amt || 1));
    }

    /*
     * Export player's state properties
     *
     * @return {Object}
     */
    export() {
        return {
            x         : this.x,
            y         : this.y,
            stats     : this.stats,
            direction : this.direction
        };
    }

}