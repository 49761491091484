import { Router } from 'routy';
import Vue from 'vue';
import app from '../app';
import domUtil from '../util/dom';
import clone from 'deepcopy';
import analytics from './analytics';
import extend from 'deep-extend';
import modal from '../ui/modal';
import focus from '../ui/focus';
import bubble from '../ui/bubble';
import uiRules from '../ui/rules';
import requireClick from '../ui/require-click';

/*
 * Router module
 *
 * Handles changes of route - instanciates controllers and the view DOM
 * element on route changes
 */

var SCOPE_DEFAULTS = {
        error   : null,
        loading : false
    },
    REQUIRE_CONFIRM_PATTERN = /(\/)*editor|play|challenges\/.*/,
    router = new Router(),
    wrap = document.getElementById('view'),
    initialised = false,
    changeConfirmed = false,
    view;

/*
 * Configure and initialise router
 */
router.init = function () {
    router
    .on('beforeChange', beforeChangeView)
    .on('change', changeView)
    .html5()
    .run();
};

/*
 * Intercept view change, ask to confirm if confirm pattern matches to current
 * path and moving to a different same segment
 *
 * @param {Object} req
 */
function beforeChangeView(req) {
    if (!initialised) { return; }

    if (
        !REQUIRE_CONFIRM_PATTERN.test(router.path) ||
        REQUIRE_CONFIRM_PATTERN.test(req.path)
        ) { return; }

    if (changeConfirmed) {
        changeConfirmed = false;
        return;
    }

    router.cancel = true;

    modal.open('confirm', { text: 'Are you sure you wanna leave the page?' }, (confirmed) => {
        if (!confirmed) { return; }

        changeConfirmed = true;

        router.goTo(req.path);
    });
}

/*
 * View change callback - Instanciate controller when the route has changed
 *
 * @param {Object} change
 */
var changeView = (route) => {
    var options = route.route.options,
        controller = options.controller || {},
        scope = clone(SCOPE_DEFAULTS);

    // Set initialised to true
    initialised = true;

    // Reset UI elements that may be left open
    focus.off();
    bubble.close();
    requireClick.off();
    modal.close();

    // Track pageview
    analytics.page(options.id);

    // Attach segments Array to route Object
    route.segments = route.path.split('/').splice(1);

    /*
     * Return true if string matches a segment in the route - Checks for
     * specific index if specified
     *
     * @param {String} str
     * @param {Number=} index
     */
    route.hasSegment = (str, index = null) => {
        if (typeof index === 'number') {
            return route.segments[index] === str;
        }

        return route.segments.indexOf(str !== -1);
    };

    // Scroll to top
    window.scrollTo(null, 0);

    // Set scope defaults
    scope.error = null;
    scope.loading = false;
    scope.user = app.vm.user || null;

    // Extend scope
    scope = extend(scope, controller.scope || {});

    // Close Remove previous view class name
    if (app.viewId) {
        domUtil.removeClass(wrap, 'view-' + app.viewId);
    }

    // Add new view class name
    if (options.id) {
        domUtil.addClass(wrap, 'view-' + options.id);
    }

    // Clean up previous view's VM
    if (view) {
        view.$destroy();
    }

    // Apply page title and view ID to app's VM, render template
    app.title = options.title || null;
    app.viewId = options.id || null;
    wrap.innerHTML = options.template || '';

    // Initialise new view's VM
    view = new Vue({
        el            : wrap,
        data          : scope,
        methods       : controller.methods || {},
        beforeDestroy : controller.destroy || null
    });

    // Run controller function
    if (typeof controller.run === 'function') {
        controller.run.apply(view, [ route, scope ]);
    }

    // Trigger UI update
    uiRules.update();
};

export default router;