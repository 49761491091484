/*
 * Prompt modal
 *
 * Modal to submit an answer to a given question
 */

var data = {
        answer   : null,
        question : null
    },
    methods = {
        respond
    };

/*
 * Initialise modal
 */
function run() {
    let input = this.$el.querySelector('input');

    if (input && input.focus) { input.focus(); }
}

/*
 * Respond to prompt with current answer and close modal
 *
 * @param {Event} e
 */
function respond(e) {
    if (e) { e.preventDefault(); }

    this.done(this.answer);
}

export default { data, methods, run };