import domUtil from '../util/dom';
import config from '../config';

/*
 * Focus UI module
 *
 * Export methods to set / remove focus on given selector matches by applying
 * a black overlay mask around them
 */
var TRANSITION_TIME = 1000,
    BACKGROUND_COLOR = '#000',
    HIGHLIGHTER_BORDER_THICKNESS = 3,
    wrap = document.body,
    highlighters = [],
    canvas, ctx, elements;

/*
 * Focus over elements given by query selector
 *
 * @param {String} selector
 * @param {String} highlightSelector
 */
function on(selector, highlightSelector = false) {
    var i, highlightElements, type;

    highlightSelector = highlightSelector === false ? selector : highlightSelector;

    elements = document.querySelectorAll(selector);
    highlightElements = highlightSelector ? document.querySelectorAll(highlightSelector) : [];

    if (elements.length) {
        type = elements[0].tagName.toLowerCase();

        if (type === 'input' || type === 'textarea' || type === 'button' || type === 'a') {
            elements[0].focus();
        }
    }

    removeHighlighters();
    if (!canvas) { createCanvas(); }

    for (i = 0; i < highlightElements.length; i++) {
        highlighters.push(createHighlighter(highlightElements[i]));
    }

    redraw();
}

/*
 * Create highlighter entry on given target
 *
 * @param {HTMLElement} target
 */
function createHighlighter(target) {
    var el = document.createElement('div'),
        highlighter = { el, target };

    el.className = 'focus-highlighter';
    wrap.appendChild(el);
    highlighters.push(highlighter);

    setTimeout(() => {
        domUtil.addClass(el, 'show');
    });

    return highlighter;
}

/*
 * Update highlighter elements styles
 */
function updateHighlighters() {
    highlighters.forEach((highlighter) => {
        var rect;

        if (!highlighter.target) {
            wrap.removeChild(highlighter.el);
            highlighters.splice(highlighters.indexOf(highlighter), 1);
            return;
        }

        rect = highlighter.target.getBoundingClientRect();

        highlighter.el.style.left = (rect.left - HIGHLIGHTER_BORDER_THICKNESS) + 'px';
        highlighter.el.style.top = (rect.top - HIGHLIGHTER_BORDER_THICKNESS) + 'px';
        highlighter.el.style.width = (rect.width + HIGHLIGHTER_BORDER_THICKNESS * 2) + 'px';
        highlighter.el.style.height = (rect.height + HIGHLIGHTER_BORDER_THICKNESS * 2) + 'px';
    });
}

/*
 * Redraw focus over elements
 */
function redraw() {
    var i;

    drawBackground();
    centerTransform();

    if (!elements) { return; }

    for (i = 0; i < elements.length; i++) {
        clearArea(elements[i]);
    }

    updateHighlighters();
}

/*
 * Clear area surrounding an element
 *
 * @param {HTMLElement} element
 */
function clearArea(element) {
    var rect = element.getBoundingClientRect();

    ctx.clearRect(
        rect.left * config.PIXEL_RATIO,
        rect.top * config.PIXEL_RATIO,
        rect.width * config.PIXEL_RATIO,
        rect.height * config.PIXEL_RATIO
        );
}

/*
 * If focus is on a single element, set focus mask transorm origin to
 * element's center to improve transform animation
 */
function centerTransform() {
    if (!elements || elements.length !== 1) {
        return domUtil.removeVendorProperty(canvas, 'transform-origin');
    }

    var rect = elements[0].getBoundingClientRect(),
        origin = (rect.left + rect.width / 2) + 'px ' + (rect.top + rect.height / 2) + 'px';

    domUtil.addVendorProperty(canvas, 'transform-origin', origin);
}

/*
 * Draw dark background over full size of canvas
 */
function drawBackground() {
    ctx.fillStyle = BACKGROUND_COLOR;
    ctx.fillRect(0, 0, ctx.width, ctx.height);
}

/*
 * Remove focus
 */
function off() {
    removeCanvas();
    removeHighlighters();
}

/*
 * Create and fade in focus canvas element, bind DOM events
 */
function createCanvas() {
    var hasMultipleMasks = elements.length > 1 ? true : false;

    canvas = document.createElement('canvas');
    canvas.className = 'focus-canvas' + (hasMultipleMasks ? ' show' : '');
    wrap.appendChild(canvas);

    ctx = canvas.getContext('2d');

    if (!hasMultipleMasks) {
        setTimeout(() => {
            domUtil.addClass(canvas, 'show');
        });
    }

    window.addEventListener('resize', resize);
    resize();
}

/*
 * Remove and fade out focus canvas element, unbind DOM events
 */
function removeCanvas() {
    if (!canvas) { return; }

    var el = canvas;

    canvas = null;

    domUtil.removeClass(el, 'show');

    setTimeout(() => {
        wrap.removeChild(el);
    }, TRANSITION_TIME);

    window.removeEventListener('resize', resize);
}

/*
 * Handle resize, update canvas inline styles
 */
function resize() {
    if (!canvas) { return; }

    canvas.style.width = canvas.style.height = 0;

    canvas.width = ctx.width = window.innerWidth * config.PIXEL_RATIO;
    canvas.height = ctx.height = window.innerHeight * config.PIXEL_RATIO;

    canvas.style.width = window.innerWidth + 'px';
    canvas.style.height = window.innerHeight + 'px';

    redraw();
}

/*
 * Clear all highlighter elements and list
 */
function removeHighlighters() {
    highlighters.forEach((highlighter) => {
        if (!highlighter.el) { return; }

        domUtil.removeClass(highlighter.el, 'show');

        setTimeout(() => {
            if (!highlighter.el || highlighter.el.parentElement !== wrap) { return; }
            wrap.removeChild(highlighter.el);
        }, TRANSITION_TIME);
    });

    highlighters = [];
}

export default { on, off };