import api from '../core/api';
import error from '../core/error';
import router from '../core/router';
import modal from '../ui/modal';

/*
 * Levels controller
 *
 * Levels list view controller
 */

export var scope = {
    levels  : null,
    loading : true
};

export var methods = { play, deleteLevel, update, share };

/*
 * Initialise controller
 */
export function run() {
    this.update();
}

/*
 * Update levels data
 */
function update() {
    this.loading = true;

    api.levels.list()
    .then((res) => {
        this.loading = false;
        this.levels = res.body.levels;
    })
    .catch(error.handle);
}

/*
 * Pick save slot and play level by id
 *
 * @param {String} id
 */
function play(id) {
    modal.open('save-slots', { levelId: id }, (slot) => {
        if (!slot) { return; }
        router.goTo(`/play/${id}/${slot.id}`);
    });
}

/*
 * Delete level by id
 *
 * @param {String} id
 */
function deleteLevel(id) {
    modal.open('confirm', { text: 'Are you sure?' }, (confirmed) => {
        if (!confirmed) { return; }

        api.levels.delete({ id })
        .then(() => {
            this.update();
        }, (res) => {
            modal.open('alert', { text: 'Error: ' + res.body });
        })
        .catch(error.handle);
    });
}

/*
 * Delete level by id
 *
 * @param {String} id
 */
function share(id) {
    modal.open('share-level', { levelId: id });
}