import BaseInstance from './BaseInstance';

/*
 * ItemInstance class
 *
 * Wrapping class for item instances
 */

export default class ItemInstance extends BaseInstance {

    /*
     * Instance constructor
     *
     * @param {Object=} options
     */
    constructor(options = {}) {
        super(options);
        this.state = options.state || 'default';
        this.states = options.states || {};
        this.attributes = options.attributes || {};
        this.pickable = options.pickable;
        this.singleUse = options.singleUse;
        this.moving = false;
    }

    /*
     * Update state on turn
     */
    turn(dir) {
        super.turn(dir);

        var state = dir === 'down' ? 'default' : dir;

        if (this.states[state]) {
            this.state = state;
        }
    }

}