var Vue = require('vue'),
    moment = require('moment');

/*
 * Date filters
 *
 * Date formatting filters
 */

/*
 * Humanised date-from-now with Moment.js
 *
 * @param {Date} date
 * @return {String}
 */
Vue.filter('from-now', function (date) {
    if (!date) { return ''; }

    return moment(date).fromNow();
});