/*
 * Movement module
 *
 * Movement functions
 */

/*
 * Move hero by given x and y amount
 *
 * @param {Number} x
 * @param {Number} y
 * @param {Function} callback
 */
exports.move = function (x, y, callback) {
    return this.player.move(x, y, callback);
};

/*
 * Move hero up by given amount
 *
 * @param {Number} amt
 * @param {Function} callback
 */
exports.moveUp = function (amt, callback) {
    return exports.move.call(this, 0, -(amt || 1), callback);
};

/*
 * Move hero down by given amount
 *
 * @param {Number} amt
 * @param {Function} callback
 */
exports.moveDown = function (amt, callback) {
    return exports.move.call(this, 0, amt || 1, callback);
};

/*
 * Move hero left by given amount
 *
 * @param {Number} amt
 * @param {Function} callback
 */
exports.moveLeft = function (amt, callback) {
    return exports.move.call(this, -(amt || 1), 0, callback);
};

/*
 * Move hero right by given amount
 *
 * @param {Number} amt
 * @param {Function} callback
 */
exports.moveRight = function (amt, callback) {
    return exports.move.call(this, amt || 1, 0, callback);
};

/*
 * Turn hero to face given direction
 *
 * @param {String} direction
 */
exports.turn = function (direction = null) {
    this.player.turn(direction);
};

/*
 * Turn hero to face up
 */
exports.turnUp = function () {
    exports.turn.call(this, 'up');
};

/*
 * Turn hero to face right
 */
exports.turnRight = function () {
    exports.turn.call(this, 'right');
};

/*
 * Turn hero to face down
 */
exports.turnDown = function () {
    exports.turn.call(this, 'down');
};

/*
 * Turn hero to face left
 */
exports.turnLeft = function () {
    exports.turn.call(this, 'left');
};