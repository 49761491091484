/*
 * Configuration module
 *
 * Exports core app's configuration
 */

var KW_CONFIG_PROD = {
        API_URL   : 'https://api.kano.me',
        WORLD_URL : 'http://world.kano.me'
    },
    KW_CONFIG_DEV = {
        API_URL   : 'http://localhost:1234',
        WORLD_URL : 'http://localhost:5000'
    },
    KW_CONFIG = window.MA_CONFIG.IS_PRODUCTION ? KW_CONFIG_PROD : KW_CONFIG_DEV;

export default {
    DEBUG                       : true,
    DEFAULT_TILESET             : 'wood',
    TILESET_SIZE                : [ 6, 10 ],
    TILESET_ADAPTIVE_REAL_WIDTH : 49,
    MAP_SIZE                    : [ 15, 15 ],
    TILE_SIZE                   : 35,
    TILE_SIZE_ORIGINAL          : [ 21, 21 ],
    ADAPTIVE_TILE_HEIGHT        : 2,
    PIXEL_RATIO                 : window.devicePixelRatio || 1,
    KW_CONFIG                   : KW_CONFIG,
    SEGMENTIO_ID                : window.MA_CONFIG.SEGMENTIO_ID || null,
    PRODUCTION                  : window.MA_CONFIG.PRODUCTION || false
};