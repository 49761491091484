import BaseChallenge from '../class/BaseChallenge';
import modalsSequence from './util/modals-sequence';
import requireClick from '../ui/require-click';
import focus from '../ui/focus';
import bubble from '../ui/bubble';
import session from '../editor/session';
import modal from '../ui/modal';

/*
 * Draw Floor challenge
 *
 * First challenge in learn mode, teaches to select and draw tiles
 */

var challenge = {
        name  : 'Drawing',
        steps : [],
        hide  : {
            layers     : true,
            logic      : true,
            items      : true,
            behaviours : true,
            map        : true,
            world      : true,
            save       : true
        }
    };

// Editor introduction sequence
challenge.steps.push(modalsSequence({ title : 'The editor' }, [
    { text: 'Hi there fella, ready to make some games?', assistant: 'standing' },
    { text: 'This is the Kano Adventures Editor - It appears when you click <strong>Make</strong> - Let\'s learn how to use it!', image: 'drawing/editor' }
]));

// Draw grass patches
challenge.steps.push(function (done) {
    var editor = session.getEditor(),
        changes = 0,
        requiredChanges = 30,
        sel;

    // Select the grass tile
    bubble.open({ text: 'Select the dark grass tile', target: '.tiles-palette', type: 'assistant', direction: 'bottom' });
    focus.on('.tiles-palette', '.tiles-palette [tile="2:0:wood"]');
    requireClick.on('[tile="2:0:wood"]')
    .then(() => {

        // Draw first tile on the map
        bubble.open({ text: 'Click aywhere on the map', target: '.map', type: 'assistant', direction: 'right' });
        focus.on('.map');
        return requireClick.on('.map');

    })
    .then(() => {
        focus.off();
        bubble.close();

        // Cache and clear selection, congratulate, click OK to continue
        sel = editor.selection.get();
        editor.selection.clear();
        return modal.open('explain', { title: 'Drawing', text: 'Great! You can also swipe while drawing to be quicker!', image: 'drawing/swipe' });
    })
    .then(() => { 
        focus.on();
        focus.off();

        // Prompt to draw more
        editor.selection.set(sel);
        focus.on('.map');
        bubble.open({ foo: 'bar', text: getText(), target: '.map', type: 'assistant', direction: 'right' });

        var bubbleVm = bubble.getVm();

        /*
         * Generate updated bubble text
         *
         * @return {String}
         */
        function getText() {
            return `Let\'s draw a few more dark patches! <strong>${changes}/${requiredChanges}</strong>`;
        }

        // Wait for 30 tiles to be drawn
        editor.map.on('change', function () {
            changes++;

            bubbleVm.text = getText();

            // Continue when done drawing 30 tiles
            if (changes === requiredChanges) {
                focus.off();

                // Clear selection
                editor.selection.clear();

                // Proceed
                done();
            }
        });

    });
});

// Draw dirt patches
challenge.steps.push(function (done) {
    var editor = session.getEditor(),
        changes = 0,
        requiredChanges = 30;

    // Congratulate, continue when OK is pressed
    bubble.open({ text: 'Well done! Now let\'s add some dirt..', type: 'assistant', button: true });
    focus.on();
    requireClick.on('.bubble button')
    .then(() => {

        // Select the grass tile
        bubble.open({ text: 'Select the light dirt tile', target: '.tiles-palette', type: 'assistant', direction: 'bottom' });
        focus.on('.tiles-palette', '[tile="8:0:wood"]');
        return requireClick.on('[tile="8:0:wood"]');

    })
    .then(() => {
        focus.off();

        focus.on('.map');
        bubble.open({ text: getText(), target: '.map', type: 'assistant', direction: 'right' });

        var bubbleVm = bubble.getVm();

        /*
         * Generate updated bubble text
         *
         * @return {String}
         */
        function getText() {
            return `Now draw a few dirt patches! <strong>${changes}/${requiredChanges}</strong>`;
        }

        // Wait for 30 tiles to be drawn
        editor.map.on('change', function () {
            changes++;

            bubbleVm.text = getText();

            // Continue when done drawing 30 tiles
            if (changes === requiredChanges) {
                focus.off();

                // Clear selection
                editor.selection.clear();

               return done();

            }
        });

    });
});

// Switch to gameplay
challenge.steps.push(function (done) {
    focus.off();

    // Congratulate, continue after confirming
    focus.on();
    bubble.open({ text: 'Great! Now let\'s play with this map!', type: 'assistant', button: true })
    .then(() => {

        // Congratulate, continue when OK is pressed
        bubble.open({ text: 'Click on the play button', type: 'assistant', target: '.play-preview', direction: 'right' });
        focus.on('.play-preview');
        return requireClick.on('.play-preview')
        .then(done);

    });
});

// Play the game
challenge.steps.push(function (done) {
    var game = session.getGame(),
        completed = false;

    focus.off();

    // Explain command to input
    bubble.open({ text: 'Now type `moveDown(5)` and press Enter..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });
    focus.on('input.prompt');

    // Listen for commands input
    game.events.on('command', function (command) {
        if (completed) { return; }

        if (/moveDown\([0-9]*\)/.test(command)) {
            completed = true;
            correct();
        } else {
            wrong();
        }
    });

    /*
     * Command was incorrectly submitted
     */
    function wrong() {
        bubble.open({ text: 'That wasn\'t quite right - try again..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });

        setTimeout(() => {
            bubble.open({ text: 'Now type `moveDown(5)` and press Enter..', type: 'assistant', target: 'input.prompt', direction: 'bottom' });
        }, 1000);
    }

    /*
     * Command was correctly submitted
     */
    function correct() {
        focus.on('.map');

        bubble.close();

        setTimeout(() => {
            bubble.open({ text: 'You can use commands like this to move around when playing', type: 'assistant', target: '.map', direction: 'right', button: true })
            .then(done);
        }, 3000);
    }
});

// Back to the editor
challenge.steps.push(function (done) {
    focus.off();

    focus.on('.play-preview');

    // Require switching back to editor
    bubble.open({ text: 'Now, let\'s get back to the editor - Click on the pause button', type: 'assistant', target: '.play-preview', direction: 'right' });
    focus.on('.play-preview');
    return requireClick.on('.play-preview')
    .then(() => {
        bubble.close();
        focus.off();
        done();
    });
});

// Saving explanation sequence
challenge.steps.push(modalsSequence({ title : 'Saving' }, [
    { text: 'Great job! You\'ve made a map and you played with it!', assistant: 'standing' },
    { text: 'Now we need to save your progress - So next time you\'ll be able to load this map and edit or play it', assistant: 'standing' }
]));

// Explain the save button
challenge.steps.push(function (done) {
    // Show save button and wait a tick
    this.hide.save = false;

    // Next tick..
    setTimeout(() => {

        // Focus on save button
        focus.on('.tool-save', '[void]');
        bubble.open({ text: 'A new button appeared! This means you can save your map now.', type: 'assistant', target: '.tool-save', direction: 'left', button: true });
        requireClick.on('.bubble button')
        .then(() => {
            // Click on save button
            focus.on('.tool-save');
            bubble.open({ text: 'Now, click on it', type: 'assistant', target: '.tool-save', direction: 'left' });
            requireClick.on('.tool-save')
            .then(() => {
                bubble.close();
                focus.off();
                setTimeout(done, 500);
            });
        });

    });
});

// Explain the save modal
challenge.steps.push(function (done) {
    var input = document.querySelector('.modal input'),
        editor = session.getEditor(),
        cancelButton = document.querySelector('.modal button[data-role="cancel"]');

    cancelButton.style.display = 'none';
    input.blur();

    input.focus();
    focus.on('.modal', '.modal input, .modal button.save');
    bubble.open({ text: 'Choose a name for your map, then hit save', type: 'assistant', target: '.modal', direction: 'right' });

    editor.events.once('save', () => {
        focus.off();
        bubble.close();
        done();
    });
});

export default new BaseChallenge(challenge);