/*
 * Challenge Finished modal
 *
 * Modal displayed once a challenge is completed
 */

var data = {
    next : null
};

/*
 * Initialise modal
 */
function run() {
    // Do nothing..
}

export default { data, run };