import Vue from 'vue';
import S from 'string';

/*
 * String filters
 *
 * Basic String mainpulation rendering filters
 */

/*
 * Uppercase first letter
 *
 * @param {String} str
 * @return {String}
 */
Vue.filter('upper-first', function (str) {
    return str.substr(0, 1).toUpperCase() + str.substr(1);
});

/*
 * Uppercase first letters, remove dashed, etc..
 *
 * @param {String} str
 * @return {String}
 */
Vue.filter('humanize', function (str) {
    return S(str).humanize().s;
});