import parseUtil from '../../util/parse';

/*
 * Behaviours index module
 *
 * Exports default behaviours as parsed Object
 */

var tiles = {
        'water'  : require('./tiles/water.behaviour'),
        'lose'   : require('./tiles/lose.behaviour'),
        'win'    : require('./tiles/win.behaviour'),
        'damage' : require('./tiles/damage.behaviour')
    },
    items = {
        'stat-potion' : require('./items/stat-potion.behaviour')
    };

export default {
    tiles : parseUtil.parseBehaviours(tiles),
    items : parseUtil.parseBehaviours(items)
};