/*
 * Error module
 *
 * Centralised error handler
 */

export default {

    /*
     * Log handled error
     *
     * @param {Error} err
     */
    handle(err) {
        console.error('[ HANDLED ]', err.stack);
    }

};