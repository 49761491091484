/*
 * Preload module
 *
 * Handle serialised preload of some assets to guarantee a smoother experience
 */

const ASSETS_URL = '/assets',
    ASSETS = [
    'sprites/hero-up.png',
    'sprites/hero-right.png',
    'sprites/hero-down.png',
    'sprites/hero-left.png',
    'sprites/damage.png'
    ];

var i = 0,
    callback;

/*
 * Start preloading images
 *
 * @param {Function} cb
 */
function init(cb = null) {
    callback = cb;
    preloadNext();
}

/*
 * Preload next asset, once finished run again or fire callback
 */
function preloadNext() {
    var cur = ASSETS[i],
        image = new Image();

    image.onload = () => {
        if (i >= ASSETS.length - 1) {
            if (typeof callback === 'function') { callback(); }
            return;
        }

        i++;
        preloadNext();
    };

    image.src = `${ASSETS_URL}/${cur}`;
}

export default { init };