import clone from 'deepcopy';
import EventEmitter from 'tiny-emitter';
import keycode from 'keycode';

/*
 * GameControls class
 *
 * In-game key mapping interface
 */

const LOCKED_KEYS = [ 'up', 'right', 'down', 'left' ],
    DEFAULT_KEYS = {
        // up    : 'moveUp()',
        // right : 'moveRight()',
        // down  : 'moveDown()',
        // left  : 'moveLeft()' ,
        t     : 'talk()',
        u     : 'use()',
        space : 'use()',
        d     : 'describe()',
        l     : 'look()'
    };

export default class GameControls extends EventEmitter {

    /*
     * GameControls constructor
     *
     * @param {Object=} options
     */
    constructor(options = {}) {
        super();
        this.keys = options.keys || clone(DEFAULT_KEYS);
    }

    /*
     * Start listening to keyboard events
     */
    bind() {
        this.onKeyDown = this.keyDown.bind(this);
        window.addEventListener('keydown', this.onKeyDown);
    }

    /*
     * Stop listening to keyboard events
     */
    unbind() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    /*
     * Trigger event on key down if a command is mapped to pressed key
     *
     * @param {KeyDownEvent} e
     */
    keyDown(e) {
        var key = keycode(e.keyCode);

        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
            return;
        }

        if (LOCKED_KEYS.indexOf(key) === -1 && this.keys[key]) {
            this.emit('command', this.keys[key]);
            e.preventDefault();
            e.stopPropagation();
        }
    }

    /*
     * Export control's configuration
     *
     * @return {Object}
     */
     export() {
        return { keys: this.keys };
     }

}