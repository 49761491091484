import BaseInstance from './BaseInstance';

/*
 * BehaviourInstance class
 *
 * Wrapping class for behaviour instances
 */

export default class BehaviourInstance extends BaseInstance {

    /*
     * Instance constructor
     *
     * @param {Object=} options
     */
    constructor(options = {}) {
        super(options);
        this.context = {};
    }

    /*
     * Perform hook in provided GameApi
     *
     * @param {GameApi} api
     */
    perform(hook, api) {
        if (this.hooks[hook]) {
            api.execute(this.hooks[hook], true, this.context);
        }
    }

}