import keycode from 'keycode';

/*
 * Pictures Gallery modal
 *
 * Modal to show a sequence of pictures
 */

var data = {
        pictures : [],
        current  : null
    },
    methods = {
        next,
        previous,
        getIndex,
        finish,
        keydown
    };

/*
 * Initialise modal
 */
function run() {
    this.current = this.current || this.pictures[0];
    window.addEventListener('keydown', this.keydown);
}

/*
 * Unbind keydown before destroying modal
 */
function destroy() {
    window.removeEventListener('keydown', this.keydown);
}

/*
 * Handle keydown event
 *
 * @param {KeyDownEvent} e
 */
function keydown(e) {
    let key = keycode(e.keyCode);

    if (key === 'esc') {
        this.finish();
    } else if (key === 'left') {
        this.previous(true);
    } else if (key === 'right') {
        this.next(true);
    }
}

/*
 * Close modal and return value of current picture's index
 */
function finish() {
    this.done(this.getIndex());
}

/*
 * Get current picture's index
 */
function getIndex() {
    return this.pictures.indexOf(this.current);
}

/*
 * Select next picture if available, or selct first if currently on last and
 * cycle is set to true
 *
 * @param {Boolean=} cycle
 */
function next(cycle = false) {
    var index = this.getIndex();

    if (!this.pictures[index + 1]) {
        if (cycle) {
            this.current = this.pictures[0];
        }

        return;
    }

    this.current = this.pictures[index + 1];
}

/*
 * Select previous picture if available, or selct last if currently on first and
 * cycle is set to true
 *
 * @param {Boolean=} cycle
 */
function previous(cycle = false) {
    var index = this.getIndex();

    if (!this.pictures[index - 1]) {
        if (cycle) {
            this.current = this.pictures[this.pictures.length - 1];
        }

        return;
    }

    this.current = this.pictures[index - 1];
}

export default { data, methods, run, destroy };