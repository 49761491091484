
import router from './core/router';

/*
 * Routes module
 *
 * Routes for the application are defined here - keeping them in a centralised
 * place allows for a faster understanding of the app's overall structure
 */

router

// Menu
.add('/', {
    id         : 'splash',
    template   : require('../views/view/splash.jade'),
    controller : require('./controller/splash')
})

// Levels
.add('/levels', {
    id         : 'levels',
    template   : require('../views/view/levels.jade'),
    controller : require('./controller/levels')
})

// Editor (New)
.add('/editor', {
    id         : 'editor',
    template   : require('../views/view/editor.jade'),
    controller : require('./controller/editor')
})

// Editor (Load)
.add('/editor/:levelId', {
    extends    : 'editor'
})

// Play level
.add('/play/:levelId', {
    id         : 'play',
    template   : require('../views/view/play.jade'),
    controller : require('./controller/play')
})

// Play level
.add('/play/:levelId/:slot', {
    extends    : 'play'
})

// Challenges list
.add('/challenges', {
    template   : require('../views/view/challenges.jade'),
    controller : require('./controller/challenges')
})

// Challenges list
.add('/challenges/:slug', {
    template   : require('../views/view/challenge.jade'),
    controller : require('./controller/challenge')
})

// Challenges list
.add('/shared/:shareId', {
    template   : require('../views/view/share.jade'),
    controller : require('./controller/share')
})

// Challenges list
.add('/404-not-found', {
    template   : require('../views/view/not-found.jade')
})

// Challenges list
.add('/test', {
    template   : require('../views/view/test.jade')
})

.otherwise('/404-not-found');