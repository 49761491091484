import error from '../core/error';

/*
 * Parse utility module
 *
 * Small utility module exporting methods to parse domain-specific formats
 */

/*
 * Parse given plain string behaviours in object
 *
 * @return {Object}
 */
exports.parseBehaviours = function (plain) {
    var behaviours = {};

    for (let key in plain) {
        behaviours[key] = parseBehaviour(plain[key]);
    }

    return behaviours;
};

/*
 * Parse given behaviour from String to Object
 *
 * @param {String} str
 * @return {Object}
 */
function parseBehaviour(str) {
    var parts = str.split(/------\s*([a-zA-Z0-9_-]*)\s*------/gi),
        behaviour = {};

    parts.splice(0, 1);

    for (let i = 0; i < parts.length; i += 2) {
        let key = parts[i].trim(),
            value = parts[i + 1].trim();

        behaviour[key] = value;

        if (key === 'attributes') {
            try {
                behaviour[key] = parseAttributes(value);
            } catch(e) {
                error.handle(new Error('Failed parsing attributes from string: ' + value));
            }
        }
    }

    return behaviour;
}

/*
 * Parse behaviour atributes string to JSON
 *
 * @param {String} str
 */
function parseAttributes(str) {
    var lines = str.split('\n');

    return JSON.parse('{' + lines.join(',') + '}');
}