import modules from '../game-api';

/*
 * Game API class
 *
 * Wraps game API in instances containing all necessary properties, provide
 * command execution in virtual context
 */

export default class GameAPI {

    /*
     * Game API constructor
     *
     * @param {Object} options
     */
    constructor(options) {
        this.game = options.game;
        this.state = options.state || {};
    }

    /*
     * Execute given String as command
     *
     * @param {String} command
     */
    execute(command, unlimitedAccess = true, context = {}) {
        this.game = this.game;
        this.terminal = this.game.terminal;
        this.player = this.game.player;
        this.level = this.game.level;
        this.inventory = this.game.inventory;
        this.map = this.game.map;

        window.api = this;

        try {
            /* jshint unused: false, evil: true */

            var vars = [],
                _modules = {},
                state = this.state,
                c, m, value,
                compiled;

            if (unlimitedAccess) {
                var game = this.game,
                    player = this.player,
                    terminal = this.terminal,
                    inventory = this.inventory,
                    map = this.map;
            }

            // Loop through language modules and declare every property in this block
            for (m in modules) {
                if (modules.hasOwnProperty(m)) {

                    for (c in modules[m]) {
                        if (modules[m].hasOwnProperty(c)) {
                            // Declare property using eval
                            _modules[c] = modules[m][c];

                            if (typeof _modules[c] === 'function') {
                                _modules[c] = _modules[c].bind(this);
                            }

                            vars.push(c + ' = _modules.' + c);
                        }
                    }
                }
            }

            compiled = '(function () {\nvar ' + vars.join(',\n    ') + ';\n\n' + command + ' }).call(context)';

            eval(compiled);

        } catch (e) {
            this.terminal.error(e);
        }
    }

}